import { useState, useEffect, Fragment, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import { GrDrag } from 'react-icons/gr';
import Table from 'react-bootstrap/Table';
import { ReactSortable } from 'react-sortablejs';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import { AiFillLike, AiFillStar, AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import Alert from 'react-bootstrap/Alert';
import FormGroup from '@mui/material/FormGroup';
import { Box, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from 'react-bootstrap/Modal';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Form from 'react-bootstrap/Form';
import IconButton from '@mui/material/IconButton';
import '../../../Style/QuestionCreatemodal.css';
import DropDown from '../../component-part/dropDown';
import { closeQuestiondata, updateQuestionList } from '../../../Redux/actions';
import { BASE_URL, API_ENDPOINTS } from '../../../api_folder/config';
// import {handlCreateQuestion} from '../../Popup-folder/Question_section/CreateQuestionmodal';

function QuestionEdit(props) {
  const optionvalue = [
    {
      opt1: 'Multiple Choice',
      opt2: 'Long Answers',
      opt4: 'Short Answers',
      opt5: 'DropDown',
      opt6: 'Linear-scale',
      opt7: 'Matrix-MCQ',
      opt8: 'Matrix-Checkbox',
      opt9: 'Range Slider',
      opt10: 'Date & Time ',
      opt11: 'File Upload',
      opt12: 'Image-choice',
      opt13: 'Rating',
    },
  ];
  const dispatch = useDispatch();
  const getupdatedlist = useSelector((state) => state.updatequestionlist);

  // question data inmport here from question list
  const openQuestiondata = useSelector((state) => state.openQuestiondata);
  const getdata = useSelector((state) => state.updatequestionid);
  const getquestionID = getdata !== null ? getdata.questionid : 'loading...';
  console.log(openQuestiondata);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const handleClose = () => {
    dispatch(closeQuestiondata());
  
  };


  useEffect(() => {
    if (getdata && getdata.questionid !== selectedQuestion?.questionid) {
      setSelectedQuestion(getdata);
    }
  }, [getdata, selectedQuestion]);

  const selectValue = (event) => {
    // default code when change question type
    const originalAns = event.target.value;
    setSelectedOption(originalAns);
    if (originalAns === answerType) {
      setRangerSlider();
      setDatetime();
      setLinaerscale();
      setFileuploader();
      setImageans();
      setRatingAns();
      setMultipleoption();
      setMatrixcheckbox();
      setMatrixrow();
      setShowmatrixcheckbox(true);
    } else {
      // rating
      setCreaterating();
      setInputCount();
      setShape();
      setShowlabe();
      setInputValues();

      // matrix  checkbox
      setShowmatrixcheckbox(false);
      const matrixrowbox = [
        { id: 1, checkboxrow: '' },
        { id: 2, checkboxrow: '' },
      ];

      const matrixcolbox = [
        { id: 1, checkboxcolumn: '' },
        { id: 2, checkboxcolumn: '' },
      ];

      setMatrixrowCheckbox(matrixrowbox);
      setMatrixcolumnbox(matrixcolbox);

      // matrix mcq
      const matrixvalue = [
        { id: 1, rowvalue: '' },
        { id: 2, rowvalue: '' },
      ];

      const matrixcolvslue = [
        { id: 1, columnvalue: '' },
        { id: 2, columnvalue: '' },
      ];

      setMartixrow(matrixvalue);
      setMartixcolumn(matrixcolvslue);

      // image input
      const defaultimage = Array(1).fill({ label: '', showInput: false, additionalInput: '', fileInput: null });
      setImageAnswers(defaultimage);

      // file uploader
      setWarningmsg();
      setFilecheck();

      // linaer scale
      setMinValue(1);
      setMaxValue(10);

      // range slider
      setLeftside();
      setMiddleside();
      setRightside();

      // multiple choice
      const newAnswerData = Array(2).fill({ id: 0, value: '' });
      const getmcqvalue = newAnswerData.map((filtervalue) => filtervalue.value);
      setDisplayAnswerData(getmcqvalue);
    }
  };

  // display data for edit question dropdown
  const [editquestion, setEditquestion] = useState([]);
  const setQue = getdata !== null ? getdata.question_text : 'loading...';
  const getID = getdata !== null ? getdata.questionid : 'loading...';
  const [answerdata, setSetanswer] = useState([]);
  const getAnswers = getdata !== null ? getdata.answer : 'loading...';
  const isRequired = getdata !== null ? getdata.compulsory : 'loading...';
  const isMultiSelect = getdata !== null ? getdata.multiple_answer :'loading...';
  const [ansarraydata, setAnsArray] = useState([]);
  const [displayAnswerData, setDisplayAnswerData] = useState([]);
  console.log('Fetched data:', getdata);

  //  add input for anwer
  const handleAddropdownFields = () => {
    if (displayAnswerData.length >= 8) {
      setLimitalert(true);
      setMinimum(false);
      setTimeout(() => {
        setLimitalert(false);
      }, 2000);
    } else {
      setOpenalert(false);
      const newAnswerData = [...displayAnswerData];
      newAnswerData.push('');
      setDisplayAnswerData(newAnswerData);
      validateInputValues(newAnswerData); // Validate after adding an input field
    }
  };
  
  
  const handleRemovedropdownFields = (index) => {
    const spreadItem = [...displayAnswerData];
    if (spreadItem.length <= 2) {
      setLimitalert(true);
      setMinimum(true);
      setTimeout(() => {
        setLimitalert(false);
      }, 2000);
    } else {
      spreadItem.splice(index, 1);
      setDisplayAnswerData(spreadItem);
      validateInputValues(spreadItem); // Validate after removing an input field
    }
  };
  
  
  const checkIfAllFieldsFilled = (fields) => {
    const allFilled = fields.every((item) => item !== '');
    setAnserInput(!allFilled);
  };
  

  useEffect(() => {
    setMultipleoption();
  }, [ansarraydata, getupdatedlist]);

  const setMultipleoption = () => {
    setDisplayAnswerData(ansarraydata);
  };
  useEffect(() => {
    try {
      const parseAnswerData = JSON.parse(answerdata);
      // Convert null values to strings
      const getAnsArray = parseAnswerData.map((text) => {
        if (text === null) {
          return 'null';
        }
        return text;
      });
      setAnsArray(getAnsArray);
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  }, [answerdata, getupdatedlist]);

  const answerType = getdata !== null ? getdata.question_type : 'loading..';
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    setSelectedOption(answerType);
    setEditquestion(setQue);
    setSetanswer(getAnswers);
    
  
    if (isRequired === '1') {
      setRequired(true);
    } else {
      setRequired(false);
    }
  
    if (isMultiSelect === '1') {
      setMultipleSelect(true);
    } else {
      setMultipleSelect(false);
    }
  
  }, [answerType, setQue, getAnswers, getupdatedlist, isMultiSelect]);
  

  // set fileuploader answer in state varialble
  const [filecheck, setFilecheck] = useState(''); // Default value
  const [warningmsg, setWarningmsg] = useState(''); // Default value
  const fileuploader = [filecheck, warningmsg];

  const handelQuestionupdate = (event) => {
    setEditquestion(event.target.value);
  };

  // edit question data
  const [checked, setChecked] = useState(false);
  const [openalert, setOpenalert] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  // ------------------------------------- on check more answer for question  -------------------------------------
  const [isCheckedmoreans, setIsCheckedmoreans] = useState(false);
  const handleCheckmoreans = (event) => {
    setIsCheckedmoreans(event.target.checked);
    console.log(isChecked);
  };
  const [multipleSelect, setMultipleSelect] = useState(false);
  const selectMultipleAnswer = (event) => {
    setMultipleSelect(event.target.checked);
  };
  const showDatepicker = () => {
    setChecked(!checked);
  };

  // MCQ  DnD code start
  const [minimum, setMinimum] = useState(false);
  const [limitalert, setLimitalert] = useState(false);
  const [inputFields, setInputFields] = useState([
    { id: 0, value: '' },
    { id: 1, value: '' },
  ]);

  // const nextId = inputFields.length;
  // const handleInputChange = (index, event) => {
  //   const values = [...inputFields];
  //   values[index].value = event.target.value;
  //   setInputFields(values);
  // };

  // const handleAddFields = () => {
  //   if (inputFields.length + 1 > 8) {
  //     setLimitalert(true)
  //     setMinimum(false)

  //     setTimeout(() => {
  //       setLimitalert(false)
  //     }, 2000);
  //   } else {
  //     setLimitalert(false)
  //     const values = [...inputFields];
  //     values.push({ id: nextId, value: '' });
  //     setInputFields(values);
  //     console.log(inputFields.length + 1)
  //   }
  // };

  // const handleRemoveFields = (id) => {
  //   if (inputFields.length <= 2) {
  //     setLimitalert(true)
  //     setMinimum(true)
  //     setTimeout(() => {
  //       setLimitalert(false)
  //     }, 2000);
  //   } else {
  //     const values = inputFields.filter((feild) => feild.id !== id);
  //     setInputFields(values);
  //   }

  // };

  // MCQ  DnD code end
  const [anserInput, setAnserInput] = useState(false);
  const validateAnswers = () => {
    return displayAnswerData.some(answer => answer.trim() === '');
  };
  
  const ChangeAnsInput = (index, event) => {
    const newInputValues = [...displayAnswerData];
    newInputValues[index] = event.target.value;
    setDisplayAnswerData(newInputValues);
    validateInputValues(newInputValues);
  };

  const validateInputValues = (inputValues) => {
    if (inputValues.some((item) => item === '')) {
      setAnserInput(true);
    } else {
      setAnserInput(false);
    }
  };

  const setRangerSlider = async () => {
    try {
      const rangeSlider = JSON.parse(getdata.answer);
      setLeftside(rangeSlider[0]);
      setMiddleside(rangeSlider[1]);
      setRightside(rangeSlider[2]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setRangerSlider();
  }, [setQue, getupdatedlist]);

  const [leftside, setLeftside] = useState();
  const [middleside, setMiddleside] = useState();
  const [rightside, setRightside] = useState();
  const [anserInputrange, setAnserInputrange] = useState(false);
  const rangeValue = [leftside, middleside, rightside];

  const handelSetrightrange = (e) => {
    setRightside(e.target.value);
    setAnserInputrange(false);
  };

  const handelSetMiddlerange = (e) => {
    setMiddleside(e.target.value);
    setAnserInputrange(false);
  };

  const handelSetleftrange = (e) => {
    setLeftside(e.target.value);
    setAnserInputrange(false);
  };

  const validateValues = () => {
    if (Number(middleside) <= Number(leftside)) {
      return 'Second value must be greater than the first value.';
    }
    if (Number(rightside) <= Number(middleside)) {
      return 'Third value must be greater than the second value.';
    }
    if (Number(leftside) >= Number(rightside)) {
      return 'Third value must be greater than the first value.';
    }
    return '';
  };
  const validationError = validateValues();
  // range slider

  // dropdown DnD code start

  // linear scale
  const [minValue, setMinValue] = useState(1);
  const [maxValue, setMaxValue] = useState(10);
  const linaerscale = [minValue, maxValue];

  const setLinaerscale = async () => {
    try {
      const parselinaerscale = JSON.parse(getdata.answer);
      setMinValue(parselinaerscale[0]);
      setMaxValue(parselinaerscale[1]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLinaerscale();
  }, [setQue, getupdatedlist]);

  const handleMinValueChange = (event) => {
    const newMinValue = parseInt(event.target.value, 10);
    setMinValue(newMinValue);
    if (maxValue < newMinValue) {
      setMaxValue(newMinValue);
    }
  };

  const handleMaxValueChange = (event) => {
    const newMaxValue = parseInt(event.target.value, 10);
    setMaxValue(newMaxValue);
  };

  const maxOptions = Array.from({ length: 11 - minValue }, (_, index) => (
    <MenuItem key={index + minValue} value={index + minValue}>
      {index + minValue}
    </MenuItem>
  ));
  const divElements = [];
  // eslint-disable-next-line no-plusplus
  for (let i = minValue; i <= maxValue; i++) {
    divElements.push(
      <Chip style={{ width: '80px' }} className="m-1 linear-chip" label={i} variant="outlined" key={i}>
        {i}
      </Chip>
    );
  }
  // linaer scale

  //  date and time

  useEffect(() => {
    setDatetime();
  }, [setQue, getupdatedlist, openQuestiondata]);

  const [checkdate, setCheckdate] = useState();
  const [checktime, setChecktime] = useState();
  const [datetype, setDatetype] = useState('');
  const datetimeArray = [String(checkdate), String(checktime), datetype];

  const setDatetime = async () => {
    try {
      const parsedatetime = JSON.parse(getdata.answer);
      setCheckdate(JSON.parse(parsedatetime[0]));
      console.log(parsedatetime[0], parsedatetime[1], parsedatetime[2]);
      setChecktime(JSON.parse(parsedatetime[1]));
      setDatetype(parsedatetime[2]);
    } catch (error) {
      console.log(error);
    }
  };
  const checkDate = (event) => {
    setCheckdate(event.target.checked);
  };

  const checkTime = (event) => {
    setChecktime(event.target.checked);
  };

  const handelDatetype = (event) => {
    setDatetype(event.target.value);
  };
  // date and time

  // file uploader
  const setFileuploader = async () => {
    try {
      const parseImguploader = JSON.parse(getdata.answer);
      setFilecheck(parseImguploader[0]);
      setWarningmsg(parseImguploader[1]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setFileuploader();
  }, [setQue, getupdatedlist]);

  const checkFileuoloader = (event) => {
    setFilecheck(event.target.value);
  };
  const [imgcheckimg, setCheckimg] = useState('');

  const createImage = () => {
    setCheckimg(imgcheckimg);
  };

  const updateMsg = (event) => {
    setWarningmsg(event.target.value);
  };
  // file uploader

  const [imgurl, setimageurl] = useState();
  const [getinputdata, setImageAnswers] = useState([
    { label: '', showInput: false, additionalInput: '', fileInput: null, getImages: imgurl, fileValue: '' },
  ]);
  const [imagePreviews, setImagePreviews] = useState([]);

  // const handleFileInputChange = async (index, event) => {
  //   const selectedFile = event.target.files[0];
  //   const updatedPreviews = [...imagePreviews];
  //   updatedPreviews[index] = URL.createObjectURL(selectedFile);
  //   setImagePreviews(updatedPreviews);

  //   const updatedFields = [...getinputdata];
  //   updatedFields[index].fileInput = event.target.files;
  //   setImageAnswers(updatedFields);

  //   const uid = localStorage.getItem('uid');
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const getidfromurl = urlParams.get('id');
  //   const formData = new FormData();
  //   formData.append('theFile', getinputdata[index].fileInput[0]);
  //   formData.append('que_id', setquestionid);
  //   formData.append('surveyid', getidfromurl);
  //   formData.append('userid', uid);

  //   try {
  //     const endpoint = API_ENDPOINTS.answerImagePOST;
  //     const res = await fetch(`${BASE_URL}${endpoint}`, {
  //       method: 'POST',
  //       body: formData,
  //     });
  //     if (res.status === 200) {
  //       const responseJson = await res.json();
  //       const newImageUrl = responseJson.logo;
  //       setimageurl(newImageUrl);

  //       setImageAnswers((prevInputData) => {
  //         const updatedData = [...prevInputData];
  //         updatedData[index] = {
  //           ...updatedData[index],
  //           getImages: newImageUrl,
  //         };
  //         return updatedData;
  //       });

  //       console.log('answer Image Uploaded');
  //     } else {
  //       console.error('Error posting Image data:', res.status);
  //     }
  //   } catch (error) {
  //     console.error('Error posting Image data:', error);
  //   }
  // };
  const handleFileInputChange = async (index, event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const updatedPreviews = [...imagePreviews];
      updatedPreviews[index] = URL.createObjectURL(selectedFile);
      setImagePreviews(updatedPreviews);

      const updatedFields = [...getinputdata];
      updatedFields[index].fileInput = event.target.files;
      setImageAnswers(updatedFields);

      const uid = localStorage.getItem('uid');
      const urlParams = new URLSearchParams(window.location.search);
      const getidfromurl = urlParams.get('id');
      const formData = new FormData();
      formData.append('theFile', selectedFile);
      formData.append('que_id', setquestionid);
      formData.append('surveyid', getidfromurl);
      formData.append('userid', uid);

      try {
        const endpoint = API_ENDPOINTS.answerImagePOST;
        const res = await fetch(`${BASE_URL}${endpoint}`, {
          method: 'POST',
          body: formData,
        });
        if (res.status === 200) {
          const responseJson = await res.json();
          const newImageUrl = responseJson.logo;
          setimageurl(newImageUrl);

          setImageAnswers((prevInputData) => {
            const updatedData = [...prevInputData];
            updatedData[index].getImages = newImageUrl;
            return updatedData;
          });

          console.log('Answer Image Uploaded');
        } else {
          console.error('Error posting Image data:', res.status);
        }
      } catch (error) {
        console.error('Error posting Image data:', error);
      }
    }
  };

  const handleAddFielddata = () => {
    if (getinputdata.length >= 8) {
      setLimitalert(true);
      setMinimum(false);
      setTimeout(() => {
        setLimitalert(false);
      }, 2000);
    } else {
      setLimitalert(false);
      setImageAnswers([
        ...getinputdata,
        { label: '', showInput: false, additionalInput: '', fileInput: null, getImages: imgurl },
      ]);
    }
  };

  const handleInputdataChange = (index, event) => {
    const updatedFields = [...getinputdata];
    updatedFields[index].label = event.target.value;
    setImageAnswers(updatedFields);
  };

  const handleAdditionalInputdataChange = (index, event) => {
    const updatedFields = [...getinputdata];
    updatedFields[index].additionalInput = event.target.value;
    setImageAnswers(updatedFields);
  };

  const handleCheckboxdataChange = (index) => {
    const updatedFields = [...getinputdata];
    updatedFields[index].showInput = !updatedFields[index].showInput;
    setImageAnswers(updatedFields);
  };

  const handleDeleteField = (index) => {
    const updatedFields = [...getinputdata];
    updatedFields.splice(index, 1);
    setImageAnswers(updatedFields);

    const updatedImagePreviews = [...imagePreviews];
    updatedImagePreviews.splice(index, 1);
    setImagePreviews(updatedImagePreviews);
  };
  const [imgageslectalert, setImgageslectalert] = useState(false);
  // const handleDeleteField = (index) => {
  //   if (getinputdata.length <= 1) {
  //     setImgageslectalert(true);
  //     setTimeout(() => {
  //       setImgageslectalert(false);
  //     }, 1000);
  //   } else {
  //     const updatedFields = [...getinputdata];
  //     updatedFields.splice(index, 1);
  //     setImageAnswers(updatedFields);
  //   }
  // };
  
  const setImageans = async () => {
    try {
      const parseImgAns = JSON.parse(getdata.answer);
      setImageAnswers(parseImgAns);
      setImagePreviews(parseImgAns.map((item) => item.getImages));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setImageans();
  }, [setQue, getupdatedlist]);
  // create input and upload image

  // rating
  const [isshowlabel, setShowlabe] = useState(true);
  // const handleShowLabele = () => {
  //   setShowlabe(!isshowlabel);
  // };

  const [inputCount, setInputCount] = useState();
  const [createrating, setCreaterating] = useState();

  // const handelRating = (event) => {
  //   const ratingselectvalue = event.target.value;
  //   setCreaterating(ratingselectvalue);
  //   const newInputValues = Array(ratingselectvalue).fill('');

  //   // eslint-disable-next-line no-plusplus
  //   for (let i = 0; i < Math.min(inputValues.length, ratingselectvalue); i++) {
  //     newInputValues[i] = inputValues[i];
  //   }

  //   setInputValues(newInputValues);
  // };
  
  const [ratingalert, setRattingalert] = useState('');
  const [inputValues, setInputValues] = useState([]);  // Initialize as an empty array
  
  const handelRating = (event) => {
    const selectednumber = event.target.value;
    setCreaterating(selectednumber);
    setRattingalert('');
    // Reset input values when rating changes
    setInputValues(Array(selectednumber).fill(''));
    const newInputValues = Array.from({ length: selectednumber }, (_, i) => (i + 1).toString());
    setInputValues(newInputValues);
    console.log('Rating changed:', selectednumber, newInputValues);
  };
  
  const [shape, setShape] = useState('2');
  const [liketype, setShowLike] = useState('');
  // const [isshowlabel, setShowlabe] = useState(false);  // Added this state
  
  const handleLiketype = (event) => {
    setShape(event.target.value);
    setShowLike(event.target.value);
    if (event.target.value === '1') {
      setShowLike(true);
    } else if (event.target.value === '2') {
      setShowLike(false);
    }
  };
  
  useEffect(() => {
    const newRatingArray = [String(createrating), shape, isshowlabel, inputValues];
    setRatingArray(newRatingArray);
  }, [inputValues, shape, liketype, isshowlabel]);  // Added isshowlabel to dependencies
  
  const handleInputChangerating = (index, event) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = event.target.value;
    setInputValues(newInputValues);
    setShowLike(liketype);
  };
  
  const setRatingAns = async () => {
    try {
      const parserating = JSON.parse(getdata.answer);
      setCreaterating(parserating[0]);
      setInputCount(parseInt(parserating[0], 10));
      setShape(parserating[1]);
      setShowlabe(parserating[2]);
      setInputValues(parserating[3]);
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    setRatingAns();
  }, [setQue, getupdatedlist]);
  
  const handleShowLabele = (event) => {
    setShowlabe(event.target.checked);
  };

  // rating

  // matrix checkbox code start

  // fetch data & set data code

  const setMatrixcheckbox = async () => {
    try {
      const parserating = JSON.parse(getdata.answer);
      setMatrixrowCheckbox(parserating[0]);
      setMatrixcolumnbox(parserating[1]);
      setICheckedNotColcheck(parserating[2]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setMatrixcheckbox();
  }, [openQuestiondata,setQue, getupdatedlist]);
  // fetch data & set data code

  const [opencheckbox, setOpencheckbox] = useState(false);
  const [selectvaluecheckbox, setInputcheckbox] = useState('');
  const [selectvaluecheckbox1, setInputcheckbox1] = useState('');
  const [showmatrixcheckbox, setShowmatrixcheckbox] = useState(true);

  const selectClosecheckbox = () => {
    setOpencheckbox(false);
  };

  const selectOpencheckbox = () => {
    setOpencheckbox(true);
  };

  const matrixMultiplecheckbox = (event) => {
    selectClosecheckbox();
    const sentence = event.target.value;
    const removedash = sentence.split('-');
    const splitone = removedash[0].trim();
    const splittwo = removedash[1].trim();
    setInputcheckbox(splitone);
    setInputcheckbox1(splittwo);
    setShowmatrixcheckbox(true);

    const updatedRowInput = [...matrixcolumnbox];
    updatedRowInput[0].checkboxcolumn = splitone;
    updatedRowInput[1].checkboxcolumn = splittwo;
    setMatrixcolumnbox(updatedRowInput);
  };
  const MatrixMcqDropdowncheckbox = [
    {
      dropdownclass: 'dropdownclass',
      width: '40%',
      title: 'Select matrix ',
      label: 'Select matrix',
      val1: 'Agree - Disagree',
      val2: 'Satisfied - Dissatisfied',
      val3: 'Yes - No',
      val4: 'Likely - Unlikely',
      val5: 'Familiar - Not familiar',
      val6: 'A great deal - None at all',
      val7: 'Interested - Not interested',
      val8: 'Easy - Difficult',
      val9: 'Always - Never',
      val10: 'Better - Worse',
      val11: 'Approve - Disapprove',
      val12: 'Above average - Below average',
      val13: 'High quality - Low quality',
      val14: 'True - False',
      val15: 'Definitely would - Definitely would not',
      val16: 'Useful - Not useful',
      val17: 'Valuable - Not valuable',
      val18: 'Clear - Not clear',
      val19: 'Helpful - Nothelpful',
      value1: 'Agree - Disagree',
      value2: 'Satisfied - Dissatisfied',
      value3: 'Yes - No',
      value4: 'Likely - Unlikely',
      value5: 'Familiar - Not familiar',
      value6: 'A great deal - None at all',
      value7: 'Interested - Not interested',
      value8: 'Easy - Difficult',
      value9: 'Always - Never',
      value10: 'Better - Worse',
      value11: 'Approve - Disapprove',
      value12: 'Above average - Below average',
      value13: 'High quality - Low quality',
      value14: 'True - False',
      value15: 'Definitely would - Definitely would not',
      value16: 'Useful - Not useful',
      value17: 'Valuable - Notvaluable',
      value18: 'Clear - Notclear',
      value19: 'Helpful - Nothelpful',
      handleClose: selectClosecheckbox,
      handleOpen: selectOpencheckbox,
      handleChange: matrixMultiplecheckbox,
    },
  ];

  const [matrixtypevaluecheck, setMatrixtypevaluecheck] = useState();
  const [minvaluesetcheck, setMinvaluesetcheck] = useState(false);
  const [maxvaluesetsecondcheck, setMaxvaluesetcheck] = useState(false);

  const [matrixrowCheckbox, setMatrixrowCheckbox] = useState([
    { id: 1, checkboxrow: '' },
    { id: 2, checkboxrow: '' },
  ]);

  const [matrixcolumnbox, setMatrixcolumnbox] = useState([
    { id: 1, checkboxcolumn: '' },
    { id: 2, checkboxcolumn: '' },
  ]);

  const [isCheckedNotColcheck, setICheckedNotColcheck] = useState();
  const matrixmcqchekbox = [matrixrowCheckbox, matrixcolumnbox, isCheckedNotColcheck];
  const handelchangerowvaluecheck = (id, event) => {
    const updatedFields = [...matrixrowCheckbox];
    updatedFields[id].checkboxrow = event.target.value;
    setMatrixrowCheckbox(updatedFields);
  };

  const handelchangecolvaluecheck = (id, event) => {
    const updatedFields = [...matrixcolumnbox];
    updatedFields[id].checkboxcolumn = event.target.value;
    setMatrixcolumnbox(updatedFields);
  };

  // const addRowcheckbox = () => {
  //   const newId = matrixrowCheckbox.length + 1;
  //   setMatrixrowCheckbox([...matrixrowCheckbox, { id: newId, checkboxrow: '' }]);
  // };
  const addRowcheckbox = () => {
    if (matrixrowCheckbox.length >= 8) {
      setLimitalert(true);
      setMinimum(false);
      setTimeout(() => {
        setLimitalert(false);
      }, 2000);
    } else {
      setLimitalert(false);
  const newId = matrixrowCheckbox.length ? matrixrowCheckbox[matrixrowCheckbox.length - 1].id + 1 : 1;
  setMatrixrowCheckbox([...matrixrowCheckbox, { id: newId, checkboxrow: '' }]);
    }
};

  // const addColumncheckbox = () => {
  //   const colid = matrixcolumnbox.length + 1;
  //   setMatrixcolumnbox([...matrixcolumnbox, { id: colid, checkboxcolumn: '' }]);
  // };

  const addColumncheckbox = () => {
    if (matrixcolumnbox.length >= 8) {
      setLimitalert(true);
      setMinimum(false);
      setTimeout(() => {
        setLimitalert(false);
      }, 2000);
    } else {
      setLimitalert(false);
    const colid = matrixcolumnbox.length ? matrixcolumnbox[matrixcolumnbox.length - 1].id + 1 : 1;
    setMatrixcolumnbox([...matrixcolumnbox, { id: colid, checkboxcolumn: '' }]);
    }
  };

  const removeRowcheckbox = (id) => {
    if (matrixrowCheckbox.length <= 2) {
      setMinvaluesetcheck(true);
      setTimeout(() => {
        setMinvaluesetcheck(false);
      }, 2000);
    } else {
      const rowinputnew = matrixrowCheckbox.filter((input) => input.id !== id);
      setMatrixrowCheckbox(rowinputnew);
    }
  };

  const removeColcheck = (id) => {
    if (matrixcolumnbox.length <= 2) {
      setMaxvaluesetcheck(true);
      setTimeout(() => {
        setMaxvaluesetcheck(false);
      }, 2000);
    } else {
      const colinputnew = matrixcolumnbox.filter((getcolumn) => getcolumn.id !== id);
      setMatrixcolumnbox(colinputnew);
    }
  };

 
  const handleCheckedNotColcheck = (event) => {
    setICheckedNotColcheck(event.target.checked);
    setMatrixtypevaluecheck(isCheckedNotColcheck);
  };
  // matrix checkbox code end

  // mcq matrix radio  btn
  const [showtwobox, setShowtwobox] = useState();
  const [showmatrix, setShowmatrix] = useState(true);
  const [selectvalue, setInputBoxmsq] = useState('');
  const [selectvalue1, setInputBoxmsq1] = useState('');
  const [open, setOpen] = useState(false);

  const handelCloseMatrictype = () => {
    setOpen(false);
  };

  const selectOpen = () => {
    setOpen(true);
  };

  const [matrixtypevalue, setMatrixtypevalue] = useState();
  const handelChangeMatrixtype = (event) => {
    handelCloseMatrictype();
    const sentence = event.target.value;
    const removedash = sentence.split('-');
    const splitone = removedash[0].trim();
    const splittwo = removedash[1].trim();
    setInputBoxmsq(splitone);
    setInputBoxmsq1(splittwo);
    setShowmatrix(true);

    const updatedRowInput = [...matrixcolumn];
    updatedRowInput[0].columnvalue = splitone;
    updatedRowInput[1].columnvalue = splittwo;
    setMartixcolumn(updatedRowInput);
  };
  const MatrixMcqDropdown = [
    {
      dropdownclass: 'dropdownclass mt-3',
      width: '40%',
      title: 'Select matrix ',
      label: 'Select matrix',
      val1: 'Agree - Disagree',
      val2: 'Satisfied - Dissatisfied',
      val3: 'Yes - No',
      val4: 'Likely - Unlikely',
      val5: 'Familiar Not - familiar',
      val6: 'A great deal - None at all',
      val7: 'Interested - Not interested',
      val8: 'Easy - Difficult',
      val9: 'Always - Never',
      val10: 'Better - Worse',
      val11: 'Approve - Disapprove',
      val12: 'Above average - Below average',
      val13: 'High quality - Low quality',
      val14: 'True False',
      val15: 'Definitely would - Definitely would not',
      val16: 'Useful - Not useful',
      val17: 'Valuable - Not valuable',
      val18: 'Clear - Not clear',
      val19: 'Helpful - Nothelpful',
      value1: 'Agree - Disagree',
      value2: 'Satisfied - Dissatisfied',
      value3: 'Yes - No',
      value4: 'Likely - Unlikely',
      value5: 'Familiar - Not familiar',
      value6: 'A great deal - None at all ',
      value7: 'Interested - Not interested',
      value8: 'Easy - Difficult',
      value9: 'Always - Never',
      value10: 'Better - Worse',
      value11: 'Approve - Disapprove',
      value12: 'Above average - Below average',
      value13: 'High quality - Low quality',
      value14: 'True - False',
      value15: 'Definitely would -  Definitely would not',
      value16: 'Useful - Not useful',
      value17: 'Valuable - Notvaluable',
      value18: 'Clear - Notclear',
      value19: 'Helpful - Nothelpful',
      handleClose: handelCloseMatrictype,
      handleOpen: selectOpen,
      handleChange: handelChangeMatrixtype,
    },
  ];

  // mcq matrix radio btn
  const [minvalueset, setMinvalueset] = useState(false);
  const [maxvaluesetsecond, setMaxvalueset] = useState(false);
  const [martixrow, setMartixrow] = useState([{ id: 1, rowvalue: '' }]);

  const [matrixcolumn, setMartixcolumn] = useState([{ id: 1, columnvalue: '' }]);

  const setMatrixrow = async () => {
    try {
      const parserating = JSON.parse(getdata.answer);
      setMartixrow(parserating[0]);
      setMartixcolumn(parserating[1]);
      setCheckedEditna(parserating[2]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setMatrixrow();
  }, [openQuestiondata,setQue, getupdatedlist]);

  const handelChangerowvalue = (id, event) => {
    const updatedFields = [...martixrow];
    updatedFields[id].rowvalue = event.target.value;
    setMartixrow(updatedFields);
  };

  const handelAddcol = (id, event) => {
    const updatedFields = [...matrixcolumn];
    updatedFields[id].columnvalue = event.target.value;
    setMartixcolumn(updatedFields);
  };

  // const addRow = () => {
  //   const newId = martixrow.length + 1;
  //   setMartixrow([...martixrow, { id: newId, rowvalue: '' }]);
  // };
  
  useEffect(() => {
    handelEditquestion();
  }, [getupdatedlist]);


  const [isrequired, setRequired] = useState(false);

  const addRow = () => {
    if (martixrow.length >= 8) {
      setLimitalert(true);
      setMinimum(false);
      setTimeout(() => {
        setLimitalert(false);
      }, 2000);
    } else {
    const newId = martixrow.length ? martixrow[martixrow.length - 1].id + 1 : 1;
    setMartixrow([...martixrow, { id: newId, rowvalue: '' }]);
    }
  };

  // const addColumn = () => {
  //   const colid = matrixcolumn.length + 1;
  //   setMartixcolumn([...matrixcolumn, { id: colid, columnvalue: '' }]);
  // };
  const addColumn = () => {
    if (matrixcolumn.length >= 8) {
      setLimitalert(true);
      setMinimum(false);
      setTimeout(() => {
        setLimitalert(false);
      }, 2000);
    } else {
    const colid = matrixcolumn.length ? matrixcolumn[matrixcolumn.length - 1].id + 1 : 1;
    setMartixcolumn([...matrixcolumn, { id: colid, columnvalue: '' }]);
    }
  };

  const removeRow = (id) => {
    if (martixrow.length <= 2) {
      setMinvalueset(true);
      setTimeout(() => {
        setMinvalueset(false);
      }, 2000);
    } else {
      const rowinputnew = martixrow.filter((input) => input.id !== id);
      setMartixrow(rowinputnew);
    }
  };

  const removeCol = (id) => {
    if (matrixcolumn.length <= 2) {
      setMaxvalueset(true);
      setTimeout(() => {
        setMaxvalueset(false);
      }, 2000);
    } else {
      const colinputnew = matrixcolumn.filter((getcolumn) => getcolumn.id !== id);
      setMartixcolumn(colinputnew);
    }
  };

  const [isCheckedNA, setCheckedEditna] = useState();
  const matrixmcq = [martixrow, matrixcolumn, isCheckedNA];
  const hyandeleditNA = (event) => {
    setCheckedEditna(event.target.checked);
    setMatrixtypevalue(isCheckedNA);
  };
  // mcq matrix radio btn

  // putapi
  const [ratingArray, setRatingArray] = useState([]);
  let changeanswervalue;
  switch (selectedOption) {
    case '1':
    case '2':
      changeanswervalue = null; // Set null for cases '1' and '2'
      break;
    case '11':
      changeanswervalue = JSON.stringify(matrixmcqchekbox);
      break;
    case '6':
      changeanswervalue = JSON.stringify(rangeValue);
      break;
    case '10':
      changeanswervalue = JSON.stringify(matrixmcq);
      break;
    case '9':
      changeanswervalue = JSON.stringify(getinputdata);
      break;
    // // case '3':
    // //   changeanswervalue = JSON.stringify(ratingArray);
    //   break;
    case '3': {
      changeanswervalue = JSON.stringify(ratingArray);
      if (ratingArray[0] === '') {
        setRattingalert('Please add rating values.');
        return false;
      }
      if (ratingArray[1] === '') {
        setRattingalert('Please add label shape.');
        return false;
      }
      if (ratingArray.some((item) => item === '')) {
        setRattingalert('Please add Input value.');
        return false;
      }
      break;
    }
    case '5':
      changeanswervalue = JSON.stringify(linaerscale);
      break;
    case '7':
      changeanswervalue = JSON.stringify(datetimeArray);
      break;
    case '8':
      changeanswervalue = JSON.stringify(fileuploader);
      break;
    default:
      if (displayAnswerData === "") {
        changeanswervalue = null; // Set null for any other default case with empty string
      } else {
        changeanswervalue = JSON.stringify(displayAnswerData);
      }
      break;
  }
  

  // putapi
  const handeCheckReq = (event) => {
    setRequired(event.target.checked);
    // console.log('Checkbox checked:', event.target.checked);
  };
  const handelEditquestion = async (event) => {
    event.preventDefault();
  
    switch (selectedOption) {
      case '6':
        if (
          Number(leftside) >= Number(middleside) ||
          Number(middleside) >= Number(rightside) ||
          Number(rightside) <= Number(leftside)
        ) {
          return;
        }
        break;
      case '12': {
        if (displayAnswerData.some((item) => item.trim() === '')) {
          setAnserInput(true); // Set error message if any input field is empty
          return;
        }
        setAnserInput(false); // Clear the error message if all fields are filled
        break;
      }
   
      default:
        break;
    }
  
    try {
      const payload = {
        question_text: editquestion,
        question_type: selectedOption,
        answer: changeanswervalue,
        multiple_answer: multipleSelect,
        compulsory: isrequired,
      };
  
      console.log('Request payload:', payload);
  
      const response = await fetch(
        `https://app.surveyslack.com/api/surveytask/api/v1/surveyquestionary.php?questionid=${getquestionID}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (response.status === 200) {
     
        dispatch(closeQuestiondata());

        dispatch(updateQuestionList());
        // getupdatedlist();
        console.log(`Survey with ID ${getquestionID} was edited successfully.`);
      } else {
        console.error(`Failed to edit survey with ID ${getquestionID}. Status code: ${response.status}`);
      }
    } catch (error) {
      console.error(`Failed to edit survey with ID ${getquestionID}.`, error);
      // Display an error message to the user
      alert(`Failed to edit survey with ID ${getquestionID}. Please try again.`);
    }
  };


  // const handelEditquestion = async (event) => {
  //   event.preventDefault(); // Prevent default form submission behavior
    
  //   switch (selectedOption) {
  //     case '6':
  //       if (
  //         Number(leftside) >= Number(middleside) ||
  //         Number(middleside) >= Number(rightside) ||
  //         Number(rightside) <= Number(leftside)
  //       ) {
  //         return;
  //       }
  //       break;
  //     case '12': {
  //       if (displayAnswerData.some((item) => item.trim() === '')) {
  //         setAnserInput(true); // Set error message if any input field is empty
  //         return;
  //       }
  //       setAnserInput(false); // Clear the error message if all fields are filled
  //       break;
  //     }
  //     default:
  //       break;
  //   }
  
  //   try {
  //     const response = await fetch(
  //       `https://app.surveyslack.com/api/surveytask/api/v1/surveyquestionary.php?questionid=${getquestionID}`,
  //       {
  //         method: 'PUT',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           question_text: editquestion,
  //           question_type: selectedOption,
  //           answer: changeanswervalue,
  //           multiple_answer: multipleSelect,
  //           compulsory: isrequired,
  //         }),
  //       }
  //     );
  
  //     if (response.status === 200) {
  //       dispatch(updateQuestionList());
  //       dispatch(closeQuestiondata());
  //       getupdatedlist();
  //       // dispatch(updateQuestionList());
  //       console.log(`Survey with ID ${getquestionID} was edited successfully.`);
  //     }
  //   } catch (error) {
  //     console.error(`Failed to edit survey with ID ${getquestionID}.`);
  //   }
  // };
  
  
  // create question id for question
  function generateQuestionid() {
    const id = Math.random().toString().slice(2, 7);
    return id;
  }
  const setquestionid = generateQuestionid();

  return (
    <>
      <Modal id="editquestionpopup" show={openQuestiondata}>
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', top: '5px', right: '15px' }}
          aria-label="delete"
        >
          <AiOutlineClose />
        </IconButton>
        <Form className="py-5 px-3 p-lg-5">
          <div>
            <h5 className="p-3 ps-5 surveytitle">Edit Question</h5>
          </div>
          <Row className="mb-3 p-3 p-0 row">
            <Form.Group className="mt-2 p-0" as={Col} md="12" controlId="validationCustom01">
              <Form.Label>Question</Form.Label>
              <Form.Control
                value={editquestion}
                onChange={handelQuestionupdate}
                type="text"
                placeholder="Edit question"
                defaultValue=""
              />
              {/* <Form.Control value={editquestion} onChange={handelQuestionupdate} type="text" placeholder="Edit question" defaultValue='' /> */}
            </Form.Group>
            <Form.Group className="mt-2 p-0 " as={Col} md="12" controlId="validationCustom02">
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <Form.Label>Answer type</Form.Label>
                  {isrequired}
                  <FormControlLabel
                    control={<Checkbox onChange={handeCheckReq} checked={isrequired} />}
                    label="Required"
                  />
                  {/* <Form.Check style={{fontSize:'large'}}   label='&nbsp; Required' onChange={handeCheckReq} checked={isrequired}
            type='checkbox'
          /> */}
                </div>
                <Form.Select
                  value={selectedOption}
                  onChange={selectValue}
                  className="mb-4 rounded-0 form-select"
                  aria-label="Default select exampl"
                >
                  {optionvalue.map((getoptionvalue) => (
                    <>
                      <>
                        <option value="" disabled hidden>
                          Not Selected
                        </option>
                        <option value="2">{getoptionvalue.opt4}</option>
                        {/* Short Answers  */}
                        <option value="1">{getoptionvalue.opt2}</option>
                        {/* long Answers  */}
                        <option value="12">{getoptionvalue.opt1}</option> {/* multiple choice question */}
                        <option value="3">{getoptionvalue.opt13}</option> {/* rating */}
                        {/* <option value="4">{getoptionvalue.opt3}</option> */}
                        <option value="4">{getoptionvalue.opt5}</option> {/* DropDown */}
                        <option value="5">{getoptionvalue.opt6}</option> {/* Linear-scale */}
                        <option value="6">{getoptionvalue.opt9}</option> {/* Range Slider */}
                        <option value="7">{getoptionvalue.opt10}</option> {/* Date & Time */}
                        <option value="8">{getoptionvalue.opt11}</option>
                        {/* File Upload */}
                        <option value="9">{getoptionvalue.opt12}</option> {/* Image-choice */}
                        <option value="10">{getoptionvalue.opt7}</option>
                        {/* Matrix-MCQ */}
                        <option value="11">{getoptionvalue.opt8}</option>
                        {/* Matrix-Checkbox */}
                      </>
                    </>
                  ))}
                </Form.Select>
              </div>
              {/* multiple choice question */}
              <div className="mt-2 col-md-12 position-relative">
                {selectedOption === '12' && (
                  <>
                    <Form>
                      <div className="mcq-question">
                        <div className="page">
                          <div className=" p-0 p-lg-2">
                            <Form.Label className="">Choices</Form.Label>
                            <ReactSortable list={displayAnswerData} setList={setDisplayAnswerData}>
                              {displayAnswerData.map((item, index) => (
                                <div className=" my-2 position-relative d-flex align-items-center " key={index}>
                                  <GrDrag style={{ cursor: 'all-scroll', fontSize: '25px' }} />
                                  <input
                                    type="text"
                                    value={item}
                                    onChange={(event) => ChangeAnsInput(index, event)}
                                    style={{ width: '100%' }}
                                    className="form-control input-item ms-2"
                                    placeholder="Enter text here"
                                  />
                                  {displayAnswerData.length > 2 &&(
                                    <IconButton aria-label="delete" style={{color:'black'}}>
                                      <DeleteOutlineTwoToneIcon onClick={() => handleRemovedropdownFields(index)} />
                                    </IconButton>
                                  )}
                                </div>
                              ))}
                            </ReactSortable>
                            {anserInput && (
                    <span className="mt-1" style={{ color: 'red', fontSize: '14px', marginLeft: '35px' }}>
                      Please Enter Answers In input fields.
                    </span>
                  )}
                          </div>
                          {limitalert && (
                            <Alert className="p-1" size="small" severity="error">
                              {minimum ? 'Minimum 2 option are required' : 'Maximum 8 answer option are allow'}
                            </Alert>
                          )}
                        </div>
                      </div>
                    </Form>
                           
                    <Button className="mt-3" onClick={handleAddropdownFields} size="sm">
            <AiOutlinePlus />
          </Button>
                    <FormGroup className="flex-row justify-content-between mt-3">
                      <FormControlLabel
                        color="info"
                        checked={multipleSelect}
                        onChange={selectMultipleAnswer}
                        control={<Checkbox defaultChecked />}
                        label="Select Multiple Answers "
                      />
             
                    </FormGroup>
                  </>
                )}
              </div>

              {/* DropDown */}
              <div className="mt-2 col-md-12 position-relative">
                {selectedOption === '4' && (
                  <>
                    <Form>
                      <div className="mcq-question">
                        <div className="page">
                          <div className=" p-0 p-lg-2">
                            <Form.Label className="">Choices</Form.Label>
                            <ReactSortable list={displayAnswerData} setList={setDisplayAnswerData}>
                              {displayAnswerData.map((value, index) => (
                                <div className=" my-2 position-relative d-flex align-items-center " key={index}>
                                  <GrDrag style={{ cursor: 'all-scroll', fontSize: '25px' }} />
                                  <input
                                    type="text"
                                    value={value}
                                    onChange={(event) => ChangeAnsInput(index, event)}
                                    style={{ width: '100%' }}
                                    className="form-control input-item ms-2"
                                    placeholder="Enter text here"
                                  />
                                  {displayAnswerData.length >2 &&(
                                    <IconButton aria-label="delete" style={{color:'black'}}>
                                      <DeleteOutlineTwoToneIcon onClick={() => handleRemovedropdownFields(index)} />
                                    </IconButton>
                                  )}
                                </div>
                              ))}
                            </ReactSortable>
                          </div>
                          {limitalert && (
                            <Alert className="p-1" size="small" severity="error">
                              {minimum ? 'Minimum 2 option are required' : 'Maximum 8 answer option are allow'}
                            </Alert>
                          )}
                        </div>
                      </div>
                    </Form>
                    <Button className='mt-3' onClick={handleAddropdownFields} size="sm">
                          <AiOutlinePlus />
                          </Button>
                    <FormGroup className="flex-row justify-content-between mt-3 d-none">
                      <FormControlLabel
                        color="info"
                        checked={multipleSelect}
                        onChange={selectMultipleAnswer}
                        control={<Checkbox defaultChecked />}
                        label="Select Multiple Answers "
                      />
                  
                    
                    </FormGroup>

                  </>
                )}
              </div>

              {/* Linear-scale */}
              {selectedOption === '5' && (
                <div className=" mt-3 m-auto">
                  <div className="d-flex d-md-block">
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="min-value-label">Minimum Value</InputLabel>
                        <Select
                          sx={{ maxWidth: 120 }}
                          labelId="min-value-label"
                          value={minValue}
                          label="Minimum Value"
                          onChange={handleMinValueChange}
                        >
                          {Array.from({ length: 10 }, (_, index) => (
                            <MenuItem key={index} value={index + 1}>
                              {index + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 120 }} className="my-0 my-lg-2 mx-2 mx-lg-0">
                      <FormControl fullWidth>
                        <InputLabel id="max-value-label">Maximum Value</InputLabel>
                        <Select
                          sx={{ maxWidth: 120 }}
                          labelId="max-value-label"
                          value={maxValue}
                          label="Maximum Value"
                          onChange={handleMaxValueChange}
                        >
                          {maxOptions}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <div>{/* Selected Range: {minValue} to {maxValue+1} */}</div>
                  <div className="mt-3 flex-wrap">{divElements}</div>
                </div>
              )}
              {/* Matrix-Checkbox */}
              {selectedOption === '11' && (
                <>
                  <DropDown dropdown={MatrixMcqDropdowncheckbox} />
                  {showmatrixcheckbox && (
                    <>
                      <div>
                        <div className="mb-2">
                          <div className="mb-3">
                            <Form.Label className="">Rows</Form.Label>
                           
                            
                          </div>
                          {matrixrowCheckbox.map((input, index) => (
                            <>
                              <div key={input.id} className="position-relative">
                                <TextField
                                  size="small"
                                  value={input.checkboxrow}
                                  onChange={(event) => handelchangerowvaluecheck(index, event)}
                                  // placeholder={input.id}
                                  className=" w-100 position-relative mb-3"
                                  label="Enter a label"
                                  variant="outlined"
                                />
                                {matrixrowCheckbox.length>2 && (
                                 
                                  <IconButton 
                                    onClick={() => removeRowcheckbox(input.id)}
                                    style={{ position: 'absolute', top: '0px', right: '15px',color:'black' }}
                                    aria-label="delete"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                )}
                              </div>
                            </>
                          ))}
                          {minvaluesetcheck && (
                            <Alert className="p-1" size="small" severity="error">
                              {'Manimum 3  input are required'}
                            </Alert>
                          )}
                          {/* <Button  onClick={addRow} style={{ float: 'right',backgroundColor:"gray",color:"white" }} variant="contained"> + Add</Button> */}
                        </div>
                        <Button  onClick={addRowcheckbox} size="sm">
                          <AiOutlinePlus />
                          </Button>
                        <div>
                          <div className="mb-3">
                            <Form.Label className="mt-3">Columns</Form.Label>
                          
                          </div>
                          {matrixcolumnbox.map((getcolumn, index) => (
                            <>
                              <div key={getcolumn.id} className="position-relative">
                                <TextField
                                  size="small"
                                  value={getcolumn.checkboxcolumn}
                                  // placeholder={getcolumn.id}
                                  onChange={(event) => handelchangecolvaluecheck(index, event)}
                                  className=" w-100 position-relative mb-3"
                                  label="Enter a label"
                                  variant="outlined"
                                />
                                {matrixcolumnbox.length >2 && (
                                  
                                  <IconButton
                                    onClick={() => removeColcheck(getcolumn.id)}
                                    style={{ position: 'absolute', top: '0px', right: '15px',color:'black' }}
                                    aria-label="delete"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                )}
                              </div>
                            </>
                          ))}
                                     
                          {maxvaluesetsecondcheck && (
                            <Alert className="p-1" size="small" severity="error">
                              {'Manimum 3  input are required'}
                            </Alert>
                          )}
                <Button  onClick={addColumncheckbox} size="sm" className='mb-3'>
                          <AiOutlinePlus />
                          </Button><br />
                          {limitalert && (
                            <Alert className="p-1" size="small" severity="error">
                              {minimum ? 'Minimum 2 option are required' : 'Maximum 8 answer option are allow'}
                            </Alert>
                          )}  
                          <FormControlLabel className='d-none'
                            control={<Checkbox checked={isCheckedNotColcheck} onChange={handleCheckedNotColcheck} />}
                            label="N/A"
                          />
                        </div>
                      </div>
                      <br />
                      <div className="mb-4" />
                      <div className="border-0">
                        <Accordion>
                          <Accordion.Item className="border-0" eventKey="0">
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Accordion.Header style={{ width: 'auto !important' }}>Preview</Accordion.Header>
                            </div>
                            <Accordion.Body
                              className="ques-prev-div"
                              style={{ margin: 'auto', width: '60%', maxWidth: '60%' }}
                            >
                              <Table responsive>
                                <thead>
                                  <tr>
                                    <th style={{ border: 'none' }}>&nbsp;</th>
                                    {matrixcolumnbox.map((getcolumn) => (
                                      <>
                                        <th
                                          key={getcolumn.id}
                                          style={{
                                            textAlign: 'center',
                                            border: 'none',
                                            maxWidthwidth: '300px',
                                            width: '200px',
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          {getcolumn.checkboxcolumn}
                                        </th>
                                      </>
                                    ))}
                                    {isCheckedNotColcheck && (
                                      <th
                                        key="1"
                                        style={{
                                          textAlign: 'center',
                                          border: 'none',
                                          maxWidthwidth: '300px',
                                          width: '200px',
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        N/A
                                      </th>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {matrixrowCheckbox.map((input) => (
                                    <>
                                      <tr>
                                        <div key={input.id} className="position-relative">
                                          <th
                                            key={input.id}
                                            style={{
                                              textAlign: 'center',
                                              textTransform: 'capitalize',
                                              maxWidthwidth: '300px',
                                              width: '200px',
                                            }}
                                          >
                                            {input.checkboxrow}
                                          </th>
                                        </div>
                                        {matrixcolumnbox.map((getcolumn) => (
                                          <>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                border: 'none',
                                                maxWidthwidth: '300px',
                                                width: '200px',
                                              }}
                                              key={getcolumn.id}
                                            >
                                              {' '}
                                              <Form.Check type="checkbox" />
                                            </td>
                                          </>
                                        ))}
                                        {isCheckedNotColcheck && (
                                          <td
                                            style={{
                                              textAlign: 'center',
                                              border: 'none',
                                              maxWidthwidth: '300px',
                                              width: '200px',
                                            }}
                                            key="1"
                                          >
                                            {' '}
                                            <Form.Check type="checkbox" />
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  ))}
                                </tbody>
                              </Table>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </>
                  )}
                </>
              )}
              {/* Matrix-MCQ */}
              {selectedOption === '10' && (
                <>
                  <DropDown dropdown={MatrixMcqDropdown} />
                  {showmatrix && (
                    <>
                      <div>
                        <div className="mb-2">
                          <div className="mb-3">
                            <Form.Label className="">Rows</Form.Label>
                          </div>
                          {martixrow.map((input, index) => (
                            <>
                              <div key={input.id} className="position-relative">
                                <TextField
                                  size="small"
                                  value={input.rowvalue}
                                  onChange={(event) => handelChangerowvalue(index, event)}
                                  placeholder="Enter a label"
                                  className=" w-100 position-relative mb-3"
                                  variant="outlined"
                                />
                                {martixrow.length > 2 && (
                                  <IconButton
                                    onClick={() => removeRow(input.id)}
                                    style={{ position: 'absolute', top: '0px', right: '15px' }}
                                    aria-label="delete"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                )}
                              </div>
                            </>
                          ))}
                          {minvalueset && (
                            <Alert className="p-1" size="small" severity="error">
                              {'Manimum 3  input are required'}
                            </Alert>
                          )}
                        </div>
                        <Button  onClick={addRow} size="sm">
                          <AiOutlinePlus />
                          </Button>
                        <div>
                          <div className="mb-3">
                            <Form.Label className="mt-3">Columns</Form.Label>
                            
                          </div>
                          {matrixcolumn.map((getcolumn, index) => (
                            <>
                              <div key={getcolumn.id} className="position-relative">
                                <TextField
                                  size="small"
                                  value={getcolumn.columnvalue}
                                  placeholder="Enter a label"
                                  onChange={(event) => handelAddcol(index, event)}
                                  className=" w-100 position-relative mb-3"
                                  variant="outlined"
                                />
                                {matrixcolumn.length>2 &&(
                                  <IconButton
                                    onClick={() => removeCol(getcolumn.id)}
                                    style={{ position: 'absolute', top: '0px', right: '15px' }}
                                    aria-label="delete"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                )}
                              </div>
                            </>
                          ))}
                           <Button  onClick={addColumn} size="sm" className='mb-3'>
                          <AiOutlinePlus />
                          </Button><br />
                          {limitalert && (
                            <Alert className="p-1" size="small" severity="error">
                              {minimum ? 'Minimum 2 option are required' : 'Maximum 8 answer option are allow'}
                            </Alert>
                          )}
                          {maxvaluesetsecond && (
                            <Alert className="p-1" size="small" severity="error">
                              {'Manimum 3  input are required'}
                            </Alert>
                          )}
                          <FormControlLabel className='d-none'
                            control={<Checkbox checked={isCheckedNA} onChange={hyandeleditNA} />}
                            label="N/A"
                          />
                        </div>
                      </div>
                      <br />
                      <div className="mb-4" />
                      <div className="border-0">
                        <Accordion>
                          <Accordion.Item className="border-0" eventKey="0">
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Accordion.Header style={{ width: 'auto !important' }}>Preview</Accordion.Header>
                            </div>
                            <Accordion.Body
                              className="ques-prev-div"
                              style={{ margin: 'auto', width: '60%', maxWidth: '60%' }}
                            >
                              <Table responsive>
                                <thead>
                                  <tr>
                                    <th style={{ border: 'none' }}>&nbsp;</th>
                                    {matrixcolumn.map((getcolumn) => (
                                      <>
                                        <th
                                          key={getcolumn.id}
                                          style={{
                                            textAlign: 'center',
                                            border: 'none',
                                            maxWidthwidth: '300px',
                                            width: '200px',
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          {getcolumn.columnvalue}
                                        </th>
                                      </>
                                    ))}
                                    {isCheckedNA && (
                                      <th
                                        key="1"
                                        style={{
                                          textAlign: 'center',
                                          border: 'none',
                                          maxWidthwidth: '300px',
                                          width: '200px',
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        N/A
                                      </th>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {martixrow.map((input) => (
                                    <>
                                      <tr>
                                        <div key={input.id} className="position-relative">
                                          <th
                                            key={input.id}
                                            style={{
                                              textAlign: 'center',
                                              textTransform: 'capitalize',
                                              maxWidthwidth: '300px',
                                              width: '200px',
                                            }}
                                          >
                                            {input.rowvalue}
                                          </th>
                                        </div>
                                        {matrixcolumn.map((getcolumn) => (
                                          <>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                border: 'none',
                                                maxWidthwidth: '300px',
                                                width: '200px',
                                              }}
                                              key={getcolumn.id}
                                            >
                                              {' '}
                                              <Form.Check type="radio" />
                                            </td>
                                          </>
                                        ))}
                                        {isCheckedNA && (
                                          <td
                                            style={{
                                              textAlign: 'center',
                                              border: 'none',
                                              maxWidthwidth: '300px',
                                              width: '200px',
                                            }}
                                            key="1"
                                          >
                                            {' '}
                                            <Form.Check type="radio" />
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  ))}
                                </tbody>
                              </Table>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </>
                  )}
                </>
              )}
              {/* rangeSlider */}
              {selectedOption === '6' && (
                <>
                  <Form.Label>Range</Form.Label>
                  <InputGroup className="mt-3">
                    <InputGroup.Text
                      className="justify-content-center rangeslider_style rounded-0"
                      style={{ width: '10%' }}
                    >
                      Left Side
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      value={leftside}
                      onChange={handelSetleftrange}
                      aria-label="Small"
                      aria-describedby="basic-addon3"
                    />
                  </InputGroup>
                  <InputGroup className="mt-3">
                    <InputGroup.Text
                      className="justify-content-center rangeslider_style rounded-0"
                      style={{ width: '10%' }}
                    >
                      Center
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      value={middleside}
                      onChange={handelSetMiddlerange}
                      aria-label="Small"
                      aria-describedby="basic-addon3"
                    />
                  </InputGroup>
                  <InputGroup className="mt-3">
                    <InputGroup.Text
                      className="justify-content-center rangeslider_style rounded-0"
                      style={{ width: '10%' }}
                    >
                      Right Side
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      value={rightside}
                      onChange={handelSetrightrange}
                      aria-label="Small"
                      aria-describedby="basic-addon3"
                    />
                  </InputGroup>
                  {validationError && (
                    <div style={{ color: 'red', fontSize: '14px' }} className="error mt-2">
                      {validationError}
                    </div>
                  )}
                  {anserInputrange && (
                    <span className="mt-1" style={{ color: 'red', fontSize: '14px' }}>
                      {' '}
                      Please Enter Range In input feids.
                    </span>
                  )}
                </>
              )}
              {/*  Date & Time  */}
              {selectedOption === '7' && (
                <>
                  <div className="form-control ">
                    <div>
                      <Checkbox
                        size="small"
                        checked={checkdate}
                        onChange={checkDate}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      Date
                      <Checkbox
                        size="small"
                        checked={checktime}
                        onChange={checkTime}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      Time
                    </div>
                    {/* check date show date  */}
                    {checkdate && (
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={datetype}
                          onChange={handelDatetype}
                        >
                          <div className="d-flex">
                            <FormControlLabel value="MM / DD / YYYY" control={<Radio />} label="MM / DD / YYYY" />
                            <FormControlLabel value="DD / MM / YYYY" control={<Radio />} label="DD / MM / YYYY" />
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  </div>
                </>
              )}
              {/* file upload */}
              {selectedOption === '8' && (
                <>
                  <div>
                    {/* file uploader type check  */}
                    <FormControl className="border rounded-2 mt-3 p-3">
                      <RadioGroup
                        className="d-flex flex-row"
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={filecheck}
                        onChange={checkFileuoloader}
                      >
                        <FormControlLabel value="Image" control={<Radio />} label="Image" />
                        <FormControlLabel value="Document" control={<Radio />} label="Document" />
                      </RadioGroup>
                    </FormControl>
                    {/* <FormLabel className="my-3" component="legend">
                      Validation Message
                    </FormLabel>
                    <TextField
                      onChange={updateMsg}
                      value={warningmsg}
                      fullWidth
                      id="outlined-basic"
                      label="When an invalid file type is uploaded, display this error message."
                      variant="outlined"
                    /> */}
                  </div>
                </>
              )}
              {/* Image-choice */}
              {/* {selectedOption === '9' && (
                <>
                  <div>
                    
                    {getinputdata.map((field, index) => (
                      <div key={index} className="form-control my-3 position-relative ">
                        <div className="d-flex  justify-content-between align-items-center">
                          <span> {index + 1}</span>
                          {index <= 0 ? (
                            ''
                          ) : (
                            <IconButton onClick={() => handleDeleteField(index)} aria-label="delete">
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </div>
                        <div>
                          <Form.Control
                            value={field.label}
                            onChange={(event) => handleInputdataChange(index, event)}
                            aria-label="Small"
                            placeholder="label"
                            aria-describedby="inputGroup-sizing-sm"
                          />
                        </div>
                        <div className="row mt-3">
                        <input
  className="col-12 col-md-4 col-lg-4"
  type="file"
  accept="image/*"
  onChange={(event) => handleFileInputChange(index, event)}
/>

                          {imagePreviews[index] && (
                            // <img
                            //   style={{ height: '150px', width: '150px' }}
                            //   src={imagePreviews[index]}
                            //   alt={`Preview ${index}`}
                            // />
                            <div style={{ width: '250px', display: 'inline-block', height: 'auto' }}>
                              <div
                                style={{
                                  height: '168px',
                                  textAlign: 'center',
                                  backgroundColor: 'white',
                                  position: 'relative',
                                }}
                              >
                                <img
                                  style={{
                                    maxHeight: '100%',
                                    maxWidth: '100%',
                                    height: 'auto',
                                    width: 'auto',
                                    position: 'absolute',
                                    top: '0',
                                    bottom: '0',
                                    left: '0',
                                    right: '0',
                                    margin: 'auto',
                                  }}
                                  src={imagePreviews[index]}
                                  alt={`Preview ${index}`}
                                />
                              </div>
                            </div>
                          )}
                          <div className="me-2 col-12 col-md-6 col-lg-6">
                            <FormControlLabel
                              onChange={() => handleCheckboxdataChange(index)}
                              checked={field.showInput}
                              control={<Checkbox />}
                              label="Include descriptive text for image"
                            />

                            {field.showInput && (
                              <TextField
                                value={field.additionalInput}
                                onChange={(event) => handleAdditionalInputdataChange(index, event)}
                                size="small"
                                placeholder="Enter descriptive text"
                                fullWidth
                                label="Enter descriptive text"
                                variant="outlined"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                           
                           <Button className="" onClick={handleAddFielddata} size="sm">                          
                            <AiOutlinePlus />
                </Button><br />
                    <FormControlLabel
                      control={<Checkbox defaultChecked={multipleSelect} onChange={selectMultipleAnswer} />}
                      label="Allow more than one answer image to this question (use checkboxes)"
                    />
                    <br />
                    {limitalert && (
                            <Alert className="p-1" size="small" severity="error">
                              {minimum ? 'Minimum 2 option are required' : 'Maximum 8 answer option are allow'}
                            </Alert>
                          )}
                  </div>
                </>
              )} */}
              {selectedOption === '9' && (
  <>
    <div>
      {getinputdata.map((field, index) => (
        <div key={index} className="form-control my-3 position-relative">
          <div className="d-flex justify-content-between align-items-center">
            <span>{index + 1}</span>
            {index !== 0 && (
              <IconButton onClick={() => handleDeleteField(index)} aria-label="delete">
                <DeleteIcon />
              </IconButton>
            )}
          </div>
          <div>
            <Form.Control
              value={field.label || ''}
              onChange={(event) => handleInputdataChange(index, event)}
              aria-label="Small"
              placeholder="Label"
              aria-describedby="inputGroup-sizing-sm"
            />
          </div>
          <div className="row mt-3">
          <div className="col-12 col-md-4 col-lg-4">
              <Button
                variant="primary"
                onClick={() => document.getElementById(`fileInput${index}`).click()}
              >
                Add Image
                              </Button>
              <input
                id={`fileInput${index}`}
                type="file"
                accept="image/*"
                onChange={(event) => handleFileInputChange(index, event)}
                style={{ display: 'none' }}
              />
            </div>
            {imagePreviews[index] && (
              <div style={{ width: '250px', display: 'inline-block', height: 'auto' }}>
                <div
                  style={{
                    height: '168px',
                    textAlign: 'center',
                    backgroundColor: 'white',
                    position: 'relative',
                  }}
                >
                  <img
                    style={{
                      maxHeight: '100%',
                      maxWidth: '100%',
                      height: 'auto',
                      width: 'auto',
                      position: 'absolute',
                      top: '0',
                      bottom: '0',
                      left: '0',
                      right: '0',
                      margin: 'auto',
                    }}
                    src={imagePreviews[index]}
                    alt={`Preview ${index}`}
                  />
                </div>
              </div>
            )}
            <div className="me-2 col-12 col-md-6 col-lg-6">
              <FormControlLabel
                onChange={() => handleCheckboxdataChange(index)}
                checked={field.showInput || false}
                control={<Checkbox />}
                label="Include descriptive text for image"
              />
              {field.showInput && (
                <TextField
                  value={field.additionalInput || ''}
                  onChange={(event) => handleAdditionalInputdataChange(index, event)}
                  size="small"
                  placeholder="Enter descriptive text"
                  fullWidth
                  label="Enter descriptive text"
                  variant="outlined"
                />
              )}
            </div>
          </div>
        </div>
      ))}
      <Button onClick={handleAddFielddata} size="sm">
        <AiOutlinePlus />
      </Button><br />
      <FormControlLabel
        control={<Checkbox defaultChecked={isCheckedmoreans} onChange={handleCheckmoreans} />}
        label="Allow more than one answer image to this question (use checkboxes)"
      />
      <br />
      {limitalert && (
        <Alert className="p-1" size="small" severity="error">
          {minimum ? 'Minimum 2 options are required' : 'Maximum 8 and Minimum 2 answer options are allowed'}
        </Alert>
      )}
      {imgageslectalert && (
        <Alert className="p-1" size="small" severity="error">
          Minimum 1 option is required.
        </Alert>
      )}
    </div>
  </>
)}


              {/* rating  */}
              {selectedOption === '3' && (
  <>
    <div className="mt-3 m-auto">
      <Box className="d-flex flex-wrap">
        <FormControl className="rating-select" sx={{ minWidth: 120 }}>
          <InputLabel id="demo-simple-select-label">Rating</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Rating"
            value={createrating}
            onChange={handelRating}
          >
            {Array.from({ length: 10 }, (_, i) => (
              <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box className="ms-3 rating-select" sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Shape</InputLabel>
            <Select
              sx={{ maxWidth: 120 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={shape}
              label="Shape"
              onChange={handleLiketype}
            >
              <MenuItem value="1">
                <AiFillLike /> &nbsp; Like
              </MenuItem>
              <MenuItem value="2">
                <AiFillStar /> &nbsp; Star
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <FormControlLabel
          className="ms-lg-2 ms-md-2 ms-0 rating-select"
          control={<Checkbox className="ps-0" checked={isshowlabel} onChange={handleShowLabele} />}
          label="Add label"
        />
      </Box>
      {Array.from({ length: createrating }).map((_, index) => (
        <div key={index} className="d-flex align-items-center mt-3">
          {isshowlabel && (shape === '1' ? <AiFillLike /> : <AiFillStar />)}
          {isshowlabel && (
            <TextField
              placeholder="Enter a rating label"
              className="ms-2"
              size="small"
              variant="outlined"
              fullWidth
              value={inputValues[index] || ''}  // Set the value of input field
              onChange={(event) => handleInputChangerating(index, event)}
            />
          )}
        </div>
      ))}
      <span className="mt-1" style={{ color: 'red', fontSize: '14px' }}>
        {ratingalert}
      </span>
    </div>
    {/* rating */}
  </>
)}

            </Form.Group>
          </Row>
          <div className="d-flex justify-content-end ">
            {' '}
            <Button onClick={handelEditquestion} className="px-5 rounded-0" type="submit">
              Save
            </Button>
             {/* <Button onClick={props.handlCreateQuestion} className="px-5 rounded-0" type="submit">
              Save
            </Button> */}
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default memo(QuestionEdit);
