// import { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
// // import { styled } from '@mui/material/styles';
// import { getAuth, signInWithEmailAndPassword, signInWithRedirect, signInWithPopup, FacebookAuthProvider } from 'firebase/auth';
// // import Cookies from 'js-cookie';
// import validator from 'validator';
// import { auth, provider } from '../../sections/auth/login/firebase';
// import { iscloseError, showDiv } from '../../Redux/actions';
// import { LoginForm } from '../../sections/auth/login';
// import '../../Style/LoginForm.css';
// // import { BASE_URL, API_ENDPOINTS } from '../../api_folder/config';

// export default function LoginPage() {

//   const navigate = useNavigate();
//   // const authToken = localStorage.getItem('Auth Token');
//   // console.log(authToken ,"loginpage ")
//   // navigate('/dashboard/survey', { replace: true });

//   const dispatch = useDispatch();
//   const [toast, setToast] = useState(false);
//   const [email, setEmail] = useState('');
//   const [isdisable, setDisable] = useState(true);
//   const [password, setPassword] = useState('');
//   const [showerror,setShowerror] = useState('');
//   const [emailError, setEmailError] = useState(false);

//   // const [isLoggedIn, setIsLoggedIn] = useState(false);

//   useEffect(() => {
//     if (localStorage.getItem('getuserid')) {
//       navigate('dashboard/survey');
//     } else {
//       navigate('/');
//     }
//     // if (email ==='' || password==='') {
//     //   setDisable(true);
//     // }

//   }, [])

//   useEffect(() => {
//     // Set autoFocus to true initially
//     setDisable(false);
//   }, []); // Run this effect only once after the initial render

//   useEffect(() => {
//     // const intervalId = setInterval(() => {
//     //   console.log('timer run');
//     // }, 1000);

//     setTimeout(() => {
//       localStorage.clear();
//       clearInterval();
//       window.location.reload();
//     }, 28800000);
//   }, []);

//   useEffect(() => {
//     localStorage.setItem('saveEmailid', email);
//     localStorage.setItem('savePsw', password);
//   }, [email, password]);

//   const onLogin = (e) => {
//     e.preventDefault();
//     signInWithEmailAndPassword(auth, email, password)
//       .then((user) => {
//         navigate('/dashboard/survey', { replace: true });
//         // sessionStorage.setItem('Auth Token', user._tokenResponse.refreshToken);
//         // localStorage.setItem('Auth Token', user._tokenResponse.refreshToken);
//         localStorage.setItem("getuserid", user.user.uid);
//         localStorage.setItem("uid", user.user.uid);
//         localStorage.setItem('email', user.user.email);
//         localStorage.setItem('provider', user.providerId);
//         dispatch(iscloseError());
//       })
//       .catch((error) => {
//         const errorCode = error.code;
//         if (errorCode === "auth/user-not-found") {
//           alert("No account found with this email. Need to create an account.")
//         }
//         if (errorCode === "auth/missing-password") {
//           alert("Please enter password.")
//         }
//         if (errorCode === "auth/wrong-password") {
//           alert("Please enter valid password.")
//         }
//         if (errorCode === "auth/invalid-email") {
//           alert("Please enter email.")
//         }
//         const errorMessage = error.message;
//         console.log(errorCode, errorMessage);
//         setToast(true);
//         dispatch(showDiv());
//       });
//   };

//   const handelfacebooklogin = () => {
//     const provider = new FacebookAuthProvider();
//     const auth = getAuth();
//     signInWithRedirect(auth, provider)
//       .then((result) => {
//         // This gives you a Facebook Access Token. You can use it to access the Facebook API.
//         const credential = provider.credentialFromResult(result);
//         const { accessToken } = credential;
//         const user = result.user;
//         localStorage.setItem('Auth Token', user._tokenResponse.refreshToken);
//         localStorage.setItem("user id:", user.uid);
//         localStorage.setItem('email', user.email);
//         sessionStorage.setItem('Access token', accessToken);
//         navigate('/dashboard/survey', { replace: true });
//         // localStorage.setItem('provider', data.providerId);
//       })
//       .catch((error) => {
//         const errorCode = error.code;

//         console.log(errorCode);
//         const errorMessage = error.message;
//         console.log(errorMessage);
//         const { customData: { email } } = error;
//         const credential = FacebookAuthProvider.credentialFromError(error);
//         console.log(credential)
//       });

//   }

//   const onGooglelogin = () => {
//     signInWithPopup(auth, provider, email).then((data) => {
//       navigate('/dashboard/survey', { replace: true });
//       localStorage.setItem('provider', data.providerId);
//       // localStorage.setItem('Auth Token', data._tokenResponse.refreshToken);
//       // sessionStorage.setItem('Auth Token', data._tokenResponse.refreshToken);
//       // const sessionId = 'unique-session-id';
//       // Cookies.set('sessionId', sessionId, { expires: 0.5 });
//       // localStorage.setItem('sessionInfo', JSON.stringify(data._tokenResponse.refreshToken));
//       localStorage.setItem('uid', data.user.uid);
//       localStorage.setItem('getuserid', data.user.uid);
//       localStorage.setItem('email', data.user.email);
//       localStorage.setItem('username', data.user.displayName);
//       try {
//         const createusername = localStorage.getItem('username');
//         const res = fetch("https://app.surveyslack.com/api/surveytask/api/v1/user.php", {
//           method: 'POST',
//           headers: { 'Content-Type': 'application/json' },
//           body: JSON.stringify
//             ({
//               userid:  data.user.uid,
//               username: createusername,
//               created_date:data.user.metadata.creationTime,
//               modified_date:data.user.metadata.lastSignInTime ,
//               platform: "Web",
//               created_for: "Personal",
//               isFree: "free",
//               subscription_enddate: "text",
//               teams: "text",
//               last_login: data.user.metadata.lastSignInTime ,
//               email:data.user.email,
//               active: "true",
//               loginwith: data.user.providerData[0].providerId,
//             }),
//         });
//         if (res.status === 200) {
//           console.log("user Data post  successfully");
//         }
//       } catch (err) {
//         console.log(err);
//       }

//   // user profile
//   try {
//     const createusername = localStorage.getItem('username');
//     const res = fetch("https://app.surveyslack.com/api/surveytask/api/v1/userprofile.php", {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify
//         ({
//           userid: data.user.uid,
//           username: createusername,
//           first_name: data._tokenResponse.firstName,
//           last_name: data._tokenResponse.lastName,
//           profile_image:data.user.photoURL,
//           created_date:data.user.metadata.creationTime,
//           modified_date:data.user.metadata.lastSignInTime ,
//           phone_number: data.user.phoneNumber,
//           bio: "text",
//         }),
//     });
//     // const resJson = await res.json();
//     if (res.status === 200) {
//       console.log(" user profile data post successfully");
//     }
//   } catch (err) {
//     console.log(err);
//     }

//     });
//   };

//   const handleAppleLogin = () => {

//   };

//   function onChangeEmail(event) {
//     setEmail(event.target.value);
//     localStorage.setItem('saveEmailid', event.target.value);

//     // Check if either email or password is blank
//     if (event.target.value === "" || password === "") {
//        setDisable(true);
//       setShowerror('');
//     } else {
//       setDisable(false);
//       // Check if the email is valid
//       if (validator.isEmail(event.target.value)) {
//         setEmailError(false);
//       } else {
//         setEmailError(true);
//       }
//     }
//   }

//   // useEffect(()=>{
//   //   const storedEmail =  localStorage.getItem('saveEmailid')
//   //   setEmail(storedEmail);
//   //   const storedPsw=  localStorage.getItem('savePsw')
//   //   setPassword(storedPsw);
//   // },[])

//   // function onChangePassword(event) {
//   //   localStorage.setItem('savePsw',event.target.value)
//   //   setPassword(event.target.value);
//   //   setDisable(false);
//   //   // Check if the password is blank
//   //   if (event.target.value === "") {
//   //     setDisable(true);
//   //     setShowerror('');
//   //   } else
//   //     // Check if the password meets the criteria
//   //     if (validator.isStrongPassword(event.target.value, {
//   //       minLength: 8,
//   //       minLowercase: 1,
//   //       minUppercase: 1,
//   //       minNumbers: 1,
//   //       minSymbols: 1
//   //     })) {
//   //       setShowerror(""); // Clear any previous error message
//   //     } else {
//   //       setDisable(true);
//   //       setShowerror('Must contain Minimum 8 characters, one number, symbol, uppercase, and lowercase letter.');
//   //     }

//   //   }
//   // setTimeout(() => {
//   //   setToast(false);
//   // }, 1000);
//   function onChangePassword(event) {
//     const newPassword = event.target.value;
//     localStorage.setItem('savePsw', newPassword);
//     setPassword(newPassword);

//     if (newPassword === "") {
//       setDisable(true);
//       setShowerror('Enter a value in the password field.');
//     } else {
//       setDisable(false);
//       setShowerror(""); // Clear any previous error message
//     }
//   }

//   // Clear toast message after a certain time
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setToast(false);
//     }, 1000);

//     return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
//   }, []);

//   const loginformdata = [
//     {
//       commonbtntext: 'Don’t have an account?',
//       commonbtn: 'Get started',
//       titletext: 'Login in to',
//       toastAlert: 'Please Check your Email And Password !',
//       loginbtn: 'Login',
//       maintitle: "SurveySlack",
//       forgotbtn: 'Forgot password?',
//       navLinkforgot: { to: '/forgot' },
//       navLinkcommonbtn: { to: '/signup' },
//       onClick: onLogin,
//       googleLoginin: onGooglelogin,
//       handleChangeEmail: onChangeEmail,
//       faceBook: handelfacebooklogin,
//       handleChangePassword: onChangePassword,
//       passwordError: showerror,
//       emailid: email,
//       Password: password,
//       disable: isdisable,
//       ConfermEmail: 'Please enter valid Email.',
//       emailvalidator: emailError,
//     },
//   ];

//   return (
//     <>
//       <div className="auth-container">
//       <div className="auth">
//       <LoginForm
//         loginformdata={loginformdata}
//       />
//       </div>
//       </div>
//     </>
//   );
// }
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signInWithPopup,
  FacebookAuthProvider,
} from 'firebase/auth';
import validator from 'validator';
import { auth, provider } from '../../sections/auth/login/firebase';
import { iscloseError, showDiv } from '../../Redux/actions';
import { LoginForm } from '../../sections/auth/login';
import '../../Style/LoginForm.css';

export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toast, setToast] = useState(false);
  const [email, setEmail] = useState('');
  const [isdisable, setDisable] = useState(true);
  const [password, setPassword] = useState('');
  const [showerror, setShowerror] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [shouldNavigate, setShouldNavigate] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('getuserid')) {
      navigate('dashboard/survey');
      setShouldNavigate(true);
    }
  }, [navigate]);

  useEffect(() => {
    if (!shouldNavigate) {
      navigate('/');
    }
  }, [shouldNavigate, navigate]);

  useEffect(() => {
    setDisable(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      localStorage.clear();
      clearInterval();
      window.location.reload();
    }, 28800000);
  }, []);

  useEffect(() => {
    localStorage.setItem('saveEmailid', email);
    localStorage.setItem('savePsw', password);
  }, [email, password]);

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((user) => {
        navigate('/dashboard/survey', { replace: true });
        localStorage.setItem('getuserid', user.user.uid);
        localStorage.setItem('uid', user.user.uid);
        localStorage.setItem('email', user.user.email);
        localStorage.setItem('provider', user.providerId);
        dispatch(iscloseError());
      })
      .catch((error) => {
        const errorCode = error.code;
        if (errorCode === 'auth/user-not-found') {
          alert('No account found with this email. Need to create an account.');
        } else if (errorCode === 'auth/missing-password') {
          alert('Please enter password.');
        } else if (errorCode === 'auth/wrong-password') {
          alert('Please enter valid password.');
        } else if (errorCode === 'auth/invalid-email') {
          alert('Please enter email.');
        }
        setToast(true);
        dispatch(showDiv());
      });
  };

  const handelfacebooklogin = () => {
    const provider = new FacebookAuthProvider();
    const auth = getAuth();
    signInWithRedirect(auth, provider)
      .then((result) => {
        const credential = provider.credentialFromResult(result);
        const { accessToken } = credential;
        const user = result.user;
        localStorage.setItem('Auth Token', user._tokenResponse.refreshToken);
        localStorage.setItem('user id:', user.uid);
        localStorage.setItem('email', user.email);
        sessionStorage.setItem('Access token', accessToken);
        navigate('/dashboard/survey', { replace: true });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const {
          customData: { email },
        } = error;
        const credential = FacebookAuthProvider.credentialFromError(error);
      });
  };

  const onGooglelogin = () => {
    signInWithPopup(auth, provider)
      .then((data) => {
        navigate('/dashboard/survey', { replace: true });
        localStorage.setItem('provider', data.providerId);
        localStorage.setItem('uid', data.user.uid);
        localStorage.setItem('getuserid', data.user.uid);
        localStorage.setItem('email', data.user.email);
        localStorage.setItem('username', data.user.displayName);
        // Additional user data handling
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  const handleAppleLogin = () => {
    // Apple login implementation here
  };

  function onChangeEmail(event) {
    setEmail(event.target.value);
    localStorage.setItem('saveEmailid', event.target.value);
    if (event.target.value === '' || password === '') {
      setDisable(true);
      setShowerror('');
    } else {
      setDisable(false);
      if (validator.isEmail(event.target.value)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }
  }

  function onChangePassword(event) {
    const newPassword = event.target.value;
    localStorage.setItem('savePsw', newPassword);
    setPassword(newPassword);
    if (newPassword === '') {
      setDisable(true);
      setShowerror('Enter a value in the password field.');
    } else {
      setDisable(false);
      setShowerror('');
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setToast(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const loginformdata = [
    {
      commonbtntext: 'Don’t have an account?',
      commonbtn: 'Create FREE Account',
      titletext: 'Login to',
      toastAlert: 'Please Check your Email And Password !',
      loginbtn: 'Login',
      maintitle: 'SurveySlack',
      forgotbtn: 'Forgot password?',
      navLinkforgot: { to: '/forgot' },
      navLinkcommonbtn: { to: '/signup' },
      onClick: onLogin,
      googleLoginin: onGooglelogin,
      handleChangeEmail: onChangeEmail,
      faceBook: handelfacebooklogin,
      handleChangePassword: onChangePassword,
      passwordError: showerror,
      emailid: email,
      Password: password,
      disable: isdisable,
      ConfermEmail: 'Please enter valid Email.',
      emailvalidator: emailError,
    },
  ];

  return (
    <>
      <Helmet>
        <title>SurveySlack Login | Secure Access to Your Surveys</title>
        <meta
          name="description"
          content="Tired of SurveyMonkey? Switch to SurveySlack Login for secure access to your surveys. Create surveys quickly and easily with our user-friendly platform."
        />
        <meta
          name="keywords"
          content="SurveySlack Login, Access SurveySlack, Online Survey Platform Login, online surveys, survey tool, feedback platform"
        />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />

        <meta property="og:url" content="https://app.surveyslack.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="SurveySlack Login | Secure Access to Your Surveys" />
        <meta
          property="og:description"
          content="Tired of SurveyMonkey? Switch to SurveySlack Login for secure access to your surveys. Create surveys quickly and easily with our user-friendly platform."
        />
        <meta property="og:image" content="https://surveyslack.com/wp-content/uploads/2024/07/Featured-Image.jpg" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="app.surveyslack.com" />
        <meta property="twitter:url" content="https://app.surveyslack.com/" />
        <meta name="twitter:title" content="SurveySlack Login | Secure Access to Your Surveys" />
        <meta
          name="twitter:description"
          content="Tired of SurveyMonkey? Switch to SurveySlack Login for secure access to your surveys. Create surveys quickly and easily with our user-friendly platform."
        />
        <meta
          name="twitter:image"
          content="https://ogcdn.net/e4b8c678-7bd5-445d-ba03-bfaad510c686/v4/Survey%20Slack/Streamline%20your%20survey%20creation%20process%20with%20ease./https%3A%2F%2Fopengraph.b-cdn.net%2Fproduction%2Fimages%2F56188dc2-e3c3-4ce5-a8b1-1323953e37b9.jpg%3Ftoken%3DhOY-wLL-tV2Wb6eqlpzb3hUOqYMZbXQ3az2flBDqaSs%26height%3D800%26width%3D1200%26expires%3D33251249770/og.png"
        />
      </Helmet>
      <div className="auth-container">
        <div className="auth">
          <LoginForm loginformdata={loginformdata} />
        </div>
      </div>
    </>
  );
}
