import React, { useEffect, useState, memo } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import { ReactSortable } from 'react-sortablejs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import Col from 'react-bootstrap/Col';
import { AiFillLike, AiFillStar, AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import RadioGroup from '@mui/material/RadioGroup';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import { Box, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import InputGroup from 'react-bootstrap/InputGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import Alert from 'react-bootstrap/Alert';
import Chip from '@mui/material/Chip';
import { GrDrag } from 'react-icons/gr';
import { useParams } from 'react-router-dom';
import FormLabel from '@mui/material/FormLabel';
import SelectAnswerTypeModal from '../../../pages/SelectAnswerTypeModal';
import {
  closeCreateSurveypopup,
  updateQuestionList,
  createQueAlert,
  createQueAlertclose,
} from '../../../Redux/actions';
import DropDown from '../../component-part/dropDown';
import LongAnswerImg from '../../../img/question-and-answer.png';
import ShortAnswerImg from '../../../img/question.png';
import RatingImg from '../../../img/rating.png';
import DropdownImg from '../../../img/drop-down-menu.png';
import LinearscaleImg from '../../../img/customer-satisfaction.png';
import RangeSliderImg from '../../../img/compass.png';
import FileUploadImge from '../../../img/upload-file.png';
import ImageChoiceImg from '../../../img/photo.png';
import DateTimeImg from '../../../img/calendar.png';
import MultipleChoiceImg from '../../../img/multiple.png';
import MatrixMCQImg from '../../../img/dot-matrix-chart.png';
import MatrixCheckImg from '../../../img/matrix.png';
import '../../../Style/QuestionCreatemodal.css';
import { BASE_URL, API_ENDPOINTS } from '../../../api_folder/config';


function CreateSurveyPage({ createsurvey }) {
  const isEditMode = useSelector((state) => state.isEditMode);
  const questionId = useSelector((state) => state.questionId);
  const [isEditQuestionid, setisEditQuestionid] = useState(null);
  const [queslist, setQueslist] = useState([]);
  const [answerData, setAnswerData] = useState(null);
  // const getdata = useSelector((state) => state.updatequestionid);
  // const [questionId, setQuestionId] = useState(null);
  useEffect(() => {
    if (isEditMode && questionId) {
      console.log('Component: isEditMode', isEditMode);
      console.log('Component: questionId', questionId);
      setisEditQuestionid(questionId);
    }
  }, [isEditMode, questionId]);
  

  // useEffect(() => {
  //   const fetchQuestions = async () => {
  //     if (isEditMode && questionId) {
  //       try {
  //         const endpoint = API_ENDPOINTS.questionCreatePOST;
  //         const response = await fetch(`${BASE_URL}${endpoint}?questionid=${questionId}`);
  //         if (response.ok) {
  //           const data = await response.json();
  //           console.log('Component: data', data);
  //           setCreatequestion(data.questionid.question_text);
  //           setIsChecked(data.questionid.compulsory === '1');
  //           setMultipleSelect(data.questionid.multiple_answer === '1');
  //           const answerType = data.questionid.question_type;
  //           setSelectedOption(answerType);
  //           setAnswerData(data.questionid.answer); // Store the answer data
  
  //           switch (answerType) {
  //             case '6': { // Range Slider
  //               const rangeSlider = JSON.parse(data.questionid.answer);
  //               setLeftside(rangeSlider[0]);
  //               setMiddleside(rangeSlider[1]);
  //               setRightside(rangeSlider[2]);
  //               break;
  //             }
  //             case '12': { // Multiple Choice
  //               const multipleChoice = JSON.parse(data.questionid.answer);
  //               setInputFields(multipleChoice.map((value, index) => ({ id: index + 1, value })));
  //               break;
  //             }
  //             case '4': { // Drop Down
  //               const DropDown = JSON.parse(data.questionid.answer);
  //               setDropdownFields(DropDown.map((value, index) => ({ id: index + 1, value })));
  //               break;
  //             }
  //             case '3': { // Rating
  //               const parsedRating = JSON.parse(data.questionid.answer);
  //               setCreaterating(parsedRating[0]);
  //               setInputValues(parsedRating[3]);
  //               setShape(parsedRating[1]);
  //               setShowlabe(parsedRating[2]);
  //               break;
  //             }
  //             case '5': { // Linear Scale
  //               const parselinaerscale = JSON.parse(data.questionid.answer);
  //               setMinValue(parselinaerscale[0]);
  //               setMaxValue(parselinaerscale[1]);
  //               break;
  //             }
  //             case '7': { // Date and Time
  //               const parsedatetime = JSON.parse(data.questionid.answer);
  //               setCheckdate(JSON.parse(parsedatetime[0]));
  //               setChecktime(JSON.parse(parsedatetime[1]));
  //               setDatetype(parsedatetime[2]);
  //               break;
  //             }
  //             case '8': { // Image Uploader
  //               const parseImguploader = JSON.parse(data.questionid.answer);
  //               setFilecheck(parseImguploader[0]);
  //               setWarningmsg(parseImguploader[1]);
  //               break;
  //             }
  //             case '9': { // Image Answer
  //               const parseImgAns = JSON.parse(data.questionid.answer);
  //               setGetinputdata(parseImgAns);
  //               setImagePreviews(parseImgAns.map((item) => item.getImages));
  //               break;
  //             }
  //             case '10': { // Matrix MCQ
  //               const parseMatrixMCQ = JSON.parse(data.questionid.answer);
  //               setRowinput(parseMatrixMCQ[0]);
  //               setColumn(parseMatrixMCQ[1]);
  //               setShowmatrix(true);
  //               break;
  //             }
  //             case '11': { // Matrix Checkbox
  //               const parseMatrixCheckBox = JSON.parse(data.questionid.answer);
  //               setRowinputcheck(parseMatrixCheckBox[0]);
  //               setColumncheck(parseMatrixCheckBox[1]);
  //               setShowmatrixcheckbox(true);
  //               break;
  //             }
  //             default:
  //               break;
  //           }
            
  //           console.log('Component: data', data);
  //         } else {
  //           console.error(`Failed to fetch questions. Status: ${response.status}`);
  //         }
  //       } catch (error) {
  //         console.error('An error occurred while fetching questions:', error);
  //       }
  //     }
  //   };
  
  //   fetchQuestions();
  // }, [isEditMode, questionId]);
  useEffect(() => {
    const fetchQuestions = async () => {
      if (isEditMode && questionId) {
        try {
          const endpoint = API_ENDPOINTS.questionCreatePOST;
          const response = await fetch(`${BASE_URL}${endpoint}?questionid=${questionId}`);
          if (response.ok) {
            const responseData = await response.json();
            console.log('Component: data', responseData);
  
            // Ensure the data and questionid are defined and access the first element of the data array
            if (responseData && responseData.data && responseData.data.length > 0) {
              const data = responseData.data[0];
              setCreatequestion(data.question_text);
              setIsChecked(data.compulsory === '1');
              setMultipleSelect(data.multiple_answer === '1');
              const answerType = data.question_type;
              setSelectedOption(answerType);
              setAnswerData(data.answer); // Store the answer data
  
              switch (answerType) {
                case '6': { // Range Slider
                  const rangeSlider = JSON.parse(data.answer);
                  setLeftside(rangeSlider[0]);
                  setMiddleside(rangeSlider[1]);
                  setRightside(rangeSlider[2]);
                  break;
                }
                case '12': { // Multiple Choice
                  const multipleChoice = JSON.parse(data.answer);
                  setInputFields(multipleChoice.map((value, index) => ({ id: index + 1, value })));
                  break;
                }
                case '4': { // Drop Down
                  const DropDown = JSON.parse(data.answer);
                  setDropdownFields(DropDown.map((value, index) => ({ id: index + 1, value })));
                  break;
                }
                case '3': { // Rating
                  const parsedRating = JSON.parse(data.answer);
                  setCreaterating(parsedRating[0]);
                  setInputValues(parsedRating[3]);
                  setShape(parsedRating[1]);
                  setShowlabe(parsedRating[2]);
                  break;
                }
                case '5': { // Linear Scale
                  const parselinaerscale = JSON.parse(data.answer);
                  setMinValue(parselinaerscale[0]);
                  setMaxValue(parselinaerscale[1]);
                  break;
                }
                case '7': { // Date and Time
                  const parsedatetime = JSON.parse(data.answer);
                  setCheckdate(JSON.parse(parsedatetime[0]));
                  setChecktime(JSON.parse(parsedatetime[1]));
                  setDatetype(parsedatetime[2]);
                  break;
                }
                case '8': { // Image Uploader
                  const parseImguploader = JSON.parse(data.answer);
                  setFilecheck(parseImguploader[0]);
                  setWarningmsg(parseImguploader[1]);
                  break;
                }
                case '9': { // Image Answer
                  const parseImgAns = JSON.parse(data.answer);
                  setGetinputdata(parseImgAns);
                  setImagePreviews(parseImgAns.map((item) => item.getImages));
                  break;
                }
                case '10': { // Matrix MCQ
                  const parseMatrixMCQ = JSON.parse(data.answer);
                  setRowinput(parseMatrixMCQ[0]);
                  setColumn(parseMatrixMCQ[1]);
                  setShowmatrix(true);
                  break;
                }
                case '11': { // Matrix Checkbox
                  const parseMatrixCheckBox = JSON.parse(data.answer);
                  setRowinputcheck(parseMatrixCheckBox[0]);
                  setColumncheck(parseMatrixCheckBox[1]);
                  setShowmatrixcheckbox(true);
                  break;
                }
                default:
                  break;
              }
            } else {
              console.error('Invalid data structure:', responseData);
            }
          } else {
            console.error(`Failed to fetch questions. Status: ${response.status}`);
          }
        } catch (error) {
          console.error('An error occurred while fetching questions:', error);
        }
      }
    };
  
    fetchQuestions();
  }, [isEditMode, questionId]);
  

  const [postOredr, setPostorder] = useState();
  const [showSelectAnswerTypeModal, setShowSelectAnswerTypeModal] = useState(false);
  useEffect(() => {}, [postOredr]);
  console.log('getorder---', JSON.stringify(postOredr));

  const openQuestiondata = useSelector((state) => state.openQuestiondata);
  const getdata = useSelector((state) => state.updatequestionid);

  console.log(openQuestiondata);

  const initialRowInput = Array.from({ length: 2 }, (_, index) => ({
    id: index + 1,
    rowvalue: '',
  }));

  const initialColInput = Array.from({ length: 2 }, (_, index) => ({
    id: index + 1,
    columnvalue: '',
  }));
  const [questiontextalert, setQuestiontextalert] = useState(false);
  // create question id for question
  function generateQuestionid() {
    const id = Math.random().toString().slice(2, 7);
    return id;
  }
    const setquestionid = generateQuestionid();

  const getupdatedlist = useSelector((state) => state.updatequestionlist);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const [isrequired, setRequired] = useState(false);
  const [editquestion, setEditquestion] = useState([]);


  // const getquestionID = getdata.questionid || null;

  const openSelectAnswerTypeModal = () => {
    setShowSelectAnswerTypeModal(true);
  };

  const closeSelectAnswerTypeModal = () => {
    setShowSelectAnswerTypeModal(false);
  };

  // get survey id from url add post  in question object
  // const urlParams = new URLSearchParams(window.location.search);
  // const getsurveyid = urlParams.get('id');
  const { id } = useParams();
  const [ratingArray, setRatingArray] = useState([]);
  const [matrixtypealert, setMarixtypealert] = useState('');
  const [matrixboxalert, setMarixboxalert] = useState('');
  const [matrixtype, setMarixtype] = useState('');
  const [matrixtypebox, setMatrixtypebox] = useState('');
  const [matrixinputvalue, setMatrixinputvalue] = useState('');
  const [matrixinputvaluebox, setMatrixinputvaluebox] = useState('');
  const [matrixradiobtn, setMatrixradiobtn] = useState('');
  const [matrixradiobtncol, setMatrixradiobtncol] = useState('');
  const [quesorder, setQuesOrder] = useState([]);
  // post data for question create
  // const getqueOrder = useSelector((state) => state.questionorder)
  // console.log("getqueOrder",getqueOrder)

  const prevOrder = localStorage.getItem('QuestionOrder');
  console.log('prevOrder', typeof prevOrder);

  const getorder = localStorage.getItem('quesOrder');
  console.log('getorder', getorder);

  const prevOrderArray = prevOrder.split(',');

  prevOrderArray.push(setquestionid.trim());

  const newOrderObject = {
    question_order: prevOrderArray.map((id) => id.trim()),
  };

  const newOrderString = JSON.stringify(newOrderObject);

  console.log(newOrderString);
  console.log(typeof setquestionid);
  // const Questionorder = useSelector((state) => state.questionorder)
  // console.log("Questionorder",Questionorder);

  const resetForm = () => {
    setIsChecked(false);
    setMarixboxalert('');
    setMatrixradiobtn('');
    setMatrixradiobtncol('');
    setMarixtypealert('');
    setMarixtype('');
    setMatrixinputvaluebox('');
    setMatrixinputvalue('');
    setAnserInput(false);
    setAnserInputdropdown(false);
    setAnserInputrange(false);
    setRattingalert('');
    setfileuploaderalert('');
    setDatealert(false);
    setDatetyoealert('');
    setMaxValue(10);
    setMatrixtypebox('');
    setShowmatrix(false);
    setShowmatrixcheckbox(false);
    setIsCheckedmoreans(false);
    setRowinputcheck(initialRowInput);
    setColumncheck(initialColInput);
    setCreatequestion('');
    setSelectedOption('');
    setRatingArray(['']);
    setCreaterating('');
    setInputCount('');
    setInputValues(['']);
    setInputFields(initialDropdownFields); // Reset to initialInputFields
    setDropdownFields(initialDropdownFields); // Reset dropdown to initial state
    setLeftside();
    setMiddleside();
    setRightside();
    setRowinput(initialRowInput);
    setColumn(initialColInput);
    setGetinputdata([
      { label: '', showInput: false, additionalInput: '', fileInput: null, getImages: JSON.stringify(imgurl) },
    ]);
    setImagePreviews([]);
    setValidated(false);
    setFilecheck('');
    setWarningmsg('');
    setShowlabe(false);
    setMultipleSelect(false);
    setCheckdate(true);
    setChecktime(true);
  };

  // const handlCreateQuestion = async (event) => {
  //   event.preventDefault();
  //   let jsonString;

  //   const validateInputs = () => {
  //     switch (selectedOption) {
  //       case '11': {
  //         jsonString = JSON.stringify(matrixmcqchekbox);
  //         if (matrixtypebox === '') {
  //           setMarixboxalert('Please select Matrix type.');
  //           return false;
  //         }

  //         const rowvalue11 = rowinputcheck.map((item) => item.checkboxrow);
  //         const columnvalue11 = inputcolumncheck.map((item) => item.checkboxcolumn);

  //         if (rowvalue11.some((item) => item === '')) {
  //           setMatrixradiobtn('Please Enter Matrix value.');
  //           return false;
  //         }
  //         if (columnvalue11.some((item) => item === '')) {
  //           setMatrixradiobtncol('Please Enter Matrix value.');
  //           return false;
  //         }
  //         break;
  //       }

  //       case '10': {
  //         jsonString = JSON.stringify(matrixmcq);
  //         const rowvalue10 = rowinput.map((item) => item.rowvalue);
  //         const columnvalue10 = inputcolumn.map((item) => item.columnvalue);
  //         if (matrixtype === '') {
  //           setMarixtypealert('Please select Matrix type.');
  //           return false;
  //         }
  //         if (columnvalue10.some((item) => item === '')) {
  //           setMatrixinputvaluebox('Please Enter Matrix value.');
  //           return false;
  //         }
  //         if (rowvalue10.some((item) => item === '')) {
  //           setMatrixinputvalue('Please Enter Matrix value.');
  //           return false;
  //         }
  //         break;
  //       }

  //       case '9':
  //         jsonString = JSON.stringify(getinputdata);
  //         if (getinputdata.length < 2 || getinputdata.length > 8) {
  //           setLimitalert(true);
  //           return false;
  //         }
  //         break;

  //       case '12': {
  //         if (getmcqvalue.some((item) => item === '')) {
  //           setAnserInput(true);
  //           return false;
  //         }
  //         jsonString = JSON.stringify(getmcqvalue);
  //         break;
  //       }

  //       case '4': {
  //         jsonString = JSON.stringify(getfiltervalue);
  //         if (getfiltervalue.some((item) => item === '')) {
  //           setAnserInputdropdown(true);
  //           return false;
  //         }
  //         break;
  //       }
  //       case '6': {
  //         jsonString = JSON.stringify(rangeValue);
  //         if (rangeValue[0] === undefined || rangeValue[1] === undefined || rangeValue[2] === undefined) {
  //           setAnserInputrange(true);
  //           return false;
  //         }
  //         if (Number(rangeValue[0]) >= Number(rangeValue[1]) || Number(rangeValue[1]) >= Number(rangeValue[2])) {
  //           setAnserInputrange(true);
  //           return false;
  //         }
  //         break;
  //       }

  //       case '3': {
  //         jsonString = JSON.stringify(ratingArray);
  //         if (ratingArray[0] === '') {
  //           setRattingalert('Please add rating values.');
  //           return false;
  //         }
  //         if (ratingArray[1] === '') {
  //           setRattingalert('Please add label shape.');
  //           return false;
  //         }
  //         if (ratingArray.some((item) => item === '')) {
  //           setRattingalert('Please add Input value.');
  //           return false;
  //         }
  //         break;
  //       }

  //       case '8': {
  //         jsonString = JSON.stringify(fileuploaderarray);
  //         if (fileuploaderarray[0] === '') {
  //           setfileuploaderalert('Please Select file type.');
  //           return false;
  //         }
  //         break;
  //       }

  //       case '7': {
  //         jsonString = JSON.stringify(datearray);
  //         if (datearray[0] === false && datearray[1] === false) {
  //           setDatealert(true);
  //           setDatetyoealert('Please Select One option.');
  //           return false;
  //         }
  //         if (datearray[0] === true && datearray[2] === '') {
  //           setDatealert(true);
  //           setDatetyoealert('Please Select Date type.');
  //           return false;
  //         }
  //         break;
  //       }

  //       case '5': {
  //         jsonString = JSON.stringify(linaerscale);
  //         break;
  //       }

  //       default: {
  //         jsonString = undefined;
  //         break;
  //       }
  //     }

  //     return true;
  //   };

  //   if (createquestion === '') {
  //     setQuestiontextalert(true);
  //     return;
  //   }

  //   if (selectedOption === '') {
  //     openSelectAnswerTypeModal();
  //     return;
  //   }

  //   if (!validateInputs()) return;

  //   resetForm();

  //   try {
  //     const endpoint = API_ENDPOINTS.questionCreatePOST;
  //     const res = await fetch(`${BASE_URL}${endpoint}`, {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({
  //         questionid: setquestionid,
  //         surveyid: id,
  //         question_text: createquestion,
  //         question_type: selectedOption,
  //         created_date: checktime ? `time: ${amPM}` : null,
  //         modified_date: checktime ? `time: ${amPM}` : null,
  //         question_order: middleside,
  //         isImage: liketype,
  //         imagelink: isChecked,
  //         isVideo: isChecked,
  //         video_link: isChecked,
  //         isAudio: isChecked,
  //         audio_link: isChecked,
  //         answer: jsonString,
  //         multiple_answer: multipleSelect || isCheckedmoreans,
  //         compulsory: isChecked,
  //       }),
  //     });

  //     if (res.status === 200) {
  //       dispatch(closeCreateSurveypopup());
  //       dispatch(createQueAlert());
  //       dispatch(updateQuestionList());
  //       setCreatequestion('');
  //       setIsChecked(false);
  //       setSelectedOption('');
  //       setRatingArray(['']);
  //       setCreaterating('');
  //       setInputValues(['']);

  //       resetForm();

  //       console.log('Survey Data post  successfully');
  //       setTimeout(() => {
  //         dispatch(createQueAlertclose());
  //       }, 1000);
  //     }
  //     if (res.status === 400) {
  //       alert('Survey not create some issue');
  //     }
  //   } catch (err) {
  //     alert('Survey not create some issue');
  //     console.log(err);
  //   }

  //   //  post question id into reorder question list
  //   try {
  //     const endpoint = API_ENDPOINTS.surveyEdit;
  //     const currentOrder = localStorage.getItem('quesOrder');
  //     console.log('currentOrder', currentOrder);

  //     const orderArray = currentOrder.split(',').map((item) => item.trim());
  //     orderArray.push(setquestionid);

  //     console.log('orderArray', orderArray);

  //     const response = await fetch(`${BASE_URL}${endpoint}?surveyid=${id}`, {
  //       method: 'PUT',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         question_order: JSON.stringify(orderArray.map(String)),
  //       }),
  //     });

  //     if (response.status === 200) {
  //       console.log(`Survey with ID ${id} question order saved successfully.`);

  //     }
  //   } catch (error) {
  //     console.error('An error occurred while saving survey question order:', error.message);
  //   }
  // };

  // const setRangerSlider = async () => {
  //   try {
  //     const rangeSlider = JSON.parse(answerData);
  //     setLeftside(rangeSlider[0]);
  //     setMiddleside(rangeSlider[1]);
  //     setRightside(rangeSlider[2]);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   setRangerSlider();
  // }, [isEditMode,questionId]);

  const selectValue = (event) => {
    const originalAns = event.target.value;
    setSelectedOption(originalAns);
    if (originalAns === selectedOption && answerData) {
      // setRangerSlider();
      // setDatetime();
      // setLinaerscale();
      // setFileuploader();
      // setImageans();
      // setRatingAns();
      // setMultipleoption();
      // setMatrixcheckbox();
      // setMatrixrow();
      // setShowmatrixcheckbox(true);
    } else {
      // rating
      setCreaterating();
      setInputCount();
      setShape();
      setShowlabe();
      setInputValues();

      // matrix checkbox
      setShowmatrix(false);
      setShowmatrixcheckbox(false);
      const matrixrowbox = [
        { id: 1, checkboxrow: '' },
        { id: 2, checkboxrow: '' },
      ];

      const matrixcolbox = [
        { id: 1, checkboxcolumn: '' },
        { id: 2, checkboxcolumn: '' },
      ];

      // setMatrixrowCheckbox(matrixrowbox);
      // setMatrixcolumnbox(matrixcolbox);

      // matrix mcq
      const matrixvalue = [
        { id: 1, rowvalue: '' },
        { id: 2, rowvalue: '' },
      ];

      const matrixcolvalue = [
        { id: 1, columnvalue: '' },
        { id: 2, columnvalue: '' },
      ];

      // setMartixrow(matrixvalue);
      // setMartixcolumn(matrixcolvalue);

      // image input
      const defaultimage = Array(1).fill({ label: '', showInput: false, additionalInput: '', fileInput: null });
      // setImageAnswers(defaultimage);

      // file uploader
      setWarningmsg();
      setFilecheck();

      // linear scale
      setMinValue(1);
      setMaxValue(10);

      // range slider
      setLeftside();
      setMiddleside();
      setRightside();

      // multiple choice
      const newAnswerData = Array(2).fill({ id: 0, value: '' });
      const getmcqvalue = newAnswerData.map((filtervalue) => filtervalue.value);
      // setDisplayAnswerData(getmcqvalue);
    }
  };

 const handlCreateQuestion = async (event) => {
    event.preventDefault();
    let jsonString;
  
    // Function to validate inputs based on selectedOption
    const validateInputs = () => {
      switch (selectedOption) {
        case 11: {
          jsonString = JSON.stringify(matrixmcqchekbox);
          if (matrixtypebox === '') {
            setMarixboxalert('Please select Matrix type.');
            return false;
          }
          const rowvalue11 = rowinputcheck.map((item) => item.checkboxrow);
          const columnvalue11 = inputcolumncheck.map((item) => item.checkboxcolumn);
          if (rowvalue11.some((item) => item === '')) {
            setMatrixradiobtn('Please Enter Matrix value.');
            return false;
          }
          if (columnvalue11.some((item) => item === '')) {
            setMatrixradiobtncol('Please Enter Matrix value.');
            return false;
          }
          break;
        }
        case 10: {
          jsonString = JSON.stringify(matrixmcq);
          const rowvalue10 = rowinput.map((item) => item.rowvalue);
          const columnvalue10 = inputcolumn.map((item) => item.columnvalue);
          if (matrixtype === '') {
            setMarixtypealert('Please select Matrix type.');
            return false;
          }
          if (columnvalue10.some((item) => item === '')) {
            setMatrixinputvaluebox('Please Enter Matrix value.');
            return false;
          }
          if (rowvalue10.some((item) => item === '')) {
            setMatrixinputvalue('Please Enter Matrix value.');
            return false;
          }
          break;
        }
        case 9:
          jsonString = JSON.stringify(getinputdata);
          if (getinputdata.length < 2 || getinputdata.length > 8) {
            setLimitalert(true);
            return false;
          }
          break;
        case 12: {
          if (getmcqvalue.some((item) => item === '')) {
            setAnserInput(true);
            return false;
          }
          jsonString = JSON.stringify(getmcqvalue);
          break;
        }
        case 4: {
          jsonString = JSON.stringify(getfiltervalue);
          if (getfiltervalue.some((item) => item === '')) {
            setAnserInputdropdown(true);
            return false;
          }
          break;
        }
        case 6: {
          jsonString = JSON.stringify(rangeValue);
          if (rangeValue[0] === undefined || rangeValue[1] === undefined || rangeValue[2] === undefined) {
            setAnserInputrange(true);
            return false;
          }
          if (Number(rangeValue[0]) >= Number(rangeValue[1]) || Number(rangeValue[1]) >= Number(rangeValue[2])) {
            setAnserInputrange(true);
            return false;
          }
          break;
        }
        case 3: {
          jsonString = JSON.stringify(ratingArray);
          if (ratingArray[0] === '') {
            setRattingalert('Please add rating values.');
            return false;
          }
          if (ratingArray[1] === '') {
            setRattingalert('Please add label shape.');
            return false;
          }
          if (ratingArray.some((item) => item === '')) {
            setRattingalert('Please add Input value.');
            return false;
          }
          break;
        }
        case 8: {
          jsonString = JSON.stringify(fileuploaderarray);
          if (fileuploaderarray[0] === '') {
            setfileuploaderalert('Please Select file type.');
            return false;
          }
          break;
        }
        case 7: {
          jsonString = JSON.stringify(datearray);
          if (datearray[0] === false && datearray[1] === false) {
            setDatealert(true);
            setDatetyoealert('Please Select One option.');
            return false;
          }
          if (datearray[0] === true && datearray[2] === '') {
            setDatealert(true);
            setDatetyoealert('Please Select Date type.');
            return false;
          }
          break;
        }
        case 5: {
          jsonString = JSON.stringify(linaerscale);
          break;
        }
        default: {
          jsonString = undefined;
          break;
        }
      }
      return true;
    };
  
    // Validate if createquestion and selectedOption are set
    if (createquestion === '') {
      setQuestiontextalert(true);
      return;
    }
  
    if (selectedOption === '') {
      openSelectAnswerTypeModal();
      return;
    }
  
    // Validate other inputs based on selectedOption
    if (!validateInputs()) return;
  
    // Reset form after validation
    resetForm();
    try {
      let endpoint = API_ENDPOINTS.questionCreatePOST;
      let method = 'POST';
      let questionid = generateQuestionid(); // Generate new question ID by default
  
      if (isEditMode && questionId) {
        endpoint = `${API_ENDPOINTS.questionCreatePOST}?questionid=${questionId}`;
        method = 'PUT';
        questionid = questionId; // Use the existing question ID in edit mode
      }
  
      const requestBody = {
        questionid,
        surveyid: id,
        question_text: createquestion,
        question_type: selectedOption,
        created_date: checktime ? `time: ${amPM}` : null,
        modified_date: checktime ? `time: ${amPM}` : null,
        question_order: middleside,
        isImage: liketype,
        imagelink: isChecked,
        isVideo: isChecked,
        video_link: isChecked,
        isAudio: isChecked,
        audio_link: isChecked,
        answer: jsonString,
        multiple_answer: multipleSelect || isCheckedmoreans,
        compulsory: isChecked,
      };
  
      console.log('isEditMode:', isEditMode);
      console.log('questionId:', questionid);
      console.log('Endpoint:', endpoint);
      console.log('Method:', method);
  
      const res = await fetch(`${BASE_URL}${endpoint}`, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });
  
      if (res.status === 200) {
        dispatch(closeCreateSurveypopup(false));
        if (method === 'POST') {
          dispatch(createQueAlert());
          setTimeout(() => {
            dispatch(createQueAlertclose());
          }, 1000);
        }
        dispatch(updateQuestionList());

        setCreatequestion('');
        setIsChecked(false);
        setSelectedOption('');
        setRatingArray(['']);
        setCreaterating('');
        setInputValues(['']);
   
        resetForm();
  
        console.log('Survey Data posted successfully');
  
        // Conditionally perform survey question order update only on POST
        if (method === 'POST') {
          try {
            const endpoint = API_ENDPOINTS.surveyEdit;
            const currentOrder = localStorage.getItem('quesOrder');
            const orderArray = currentOrder.split(',').map((item) => item.trim());
  
            orderArray.push(questionid);
  
            const response = await fetch(`${BASE_URL}${endpoint}?surveyid=${id}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                question_order: JSON.stringify(orderArray.map(String)),
              }),
            });
  
            if (response.status === 200) {
              console.log(`Survey with ID ${id} question order saved successfully.`);
            }
          } catch (error) {
            console.error('Error while saving survey question order:', error.message);
          }
        }
      } else if (res.status === 400) {
        alert('Survey not created due to some issues');
      }
    } catch (err) {
      alert('Survey not created due to some issues');
      console.error('Error:', err);
    }
  };
  const [scale, setScale] = useState([]);

  CreateSurveyPage.propTypes = {
    createsurvey: PropTypes.string,
  };
  // const options = [
  //   { value: "2", label: "Short Answers", img: ShortAnswerImg },
  //   { value: "1", label: "Long Answers", img: LongAnswerImg },
  //   { value: "3", label: "Rating", img: RatingImg },
  //   { value: "4", label: "Dropdown", img: DropdownImg },
  //   { value: "5", label: "Linear scale", img: LinearscaleImg },
  //   { value: "6", label: "Range Slider", img: RangeSliderImg },
  //   { value: "7", label: "Date & Time", img: DateTimeImg },
  //   { value: "8", label: "File Upload", img: FileUploadImge },
  //   { value: "9", label: "Image Choice", img: ImageChoiceImg },
  //   { value: "10", label: "Matrix MCQ", img: MatrixMCQImg },
  //   { value: "11", label: "Matrix Checkbox", img: MatrixCheckImg },
  //   { value: "12", label: "Multiple Choice", img: MultipleChoiceImg },
  // ];


  const options = [
    { value: 2, label: "Short Answers", img: ShortAnswerImg },
    { value: 1, label: "Long Answers", img: LongAnswerImg },
    { value: 3, label: "Rating", img: RatingImg },
    { value: 4, label: "Dropdown", img: DropdownImg },
    { value: 5, label: "Linear scale", img: LinearscaleImg },
    { value: 6, label: "Range Slider", img: RangeSliderImg },
    { value: 7, label: "Date & Time", img: DateTimeImg },
    { value: 8, label: "File Upload", img: FileUploadImge },
    { value: 9, label: "Image Choice", img: ImageChoiceImg },
    { value: 10, label: "Matrix MCQ", img: MatrixMCQImg },
    { value: 11, label: "Matrix Checkbox", img: MatrixCheckImg },
    { value: 12, label: "Multiple Choice", img: MultipleChoiceImg },
  ];
  // const optionvalue = [
  //   {
  //     opt1: 'Multiple Choice',
  //     opt2: 'Long Answers',
  //     opt4: 'Short Answers',
  //     opt5: 'DropDown',
  //     opt6: 'Linear-scale',
  //     opt7: 'Matrix-MCQ',
  //     opt8: 'Matrix-Checkbox',
  //     opt9: 'Range Slider',
  //     opt10: 'Date & Time ',
  //     opt11: 'File Upload',
  //     opt12: 'Image-choice',
  //     opt13: 'Rating',
  //   },
  // ];

  const selectClose = () => {
    setOpen(false);
  };

  const selectOpen = () => {
    setOpen(true);
  };

  const [matrixtypevalue, setMatrixtypevalue] = useState();
  const matrixMultiple = (event) => {
    const sentence = event.target.value;
    setMarixtype(sentence);
    // setShowmatrix(true);
    selectClose();
    const removedash = sentence.split('-');
    const splitone = removedash[0].trim();
    const splittwo = removedash[1].trim();
    // const word2 = `Very-${words[1]}`;
    // const word3 = `Very-${words[0]}`;
    setInputBoxmsq(splitone);
    setInputBoxmsq1(splittwo);
    setShowmatrix(true);
    setMarixtypealert('');

    const updatedRowInput = [...inputcolumn];
    updatedRowInput[0].columnvalue = splitone;
    updatedRowInput[1].columnvalue = splittwo;
    // updatedRowInput[2].columnvalue = word2;
    // updatedRowInput[3].columnvalue = word3;

    // Update the state with the new rowinput
    setColumn(updatedRowInput);
  };
  // radio btn
  const MatrixMcqDropdown = [
    {
      dropdownclass: 'dropdownclass',
      width: '40%',
      title: 'Select matrix ',
      label: 'Select matrix',
      val1: 'Agree - Disagree',
      val2: 'Satisfied - Dissatisfied',
      val3: 'Yes - No',
      val4: 'Likely - Unlikely',
      val5: 'Familiar Not - familiar',
      val6: 'A great deal - None at all',
      val7: 'Interested - Not interested',
      val8: 'Easy - Difficult',
      val9: 'Always - Never',
      val10: 'Better - Worse',
      val11: 'Approve - Disapprove',
      val12: 'Above average - Below average',
      val13: 'High quality - Low quality',
      val14: 'True False',
      val15: 'Definitely would - Definitely would not',
      val16: 'Useful - Not useful',
      val17: 'Valuable - Not valuable',
      val18: 'Clear - Not clear',
      val19: 'Helpful - Nothelpful',
      value1: 'Agree - Disagree',
      value2: 'Satisfied - Dissatisfied',
      value3: 'Yes - No',
      value4: 'Likely - Unlikely',
      value5: 'Familiar - Not familiar',
      value6: 'A great deal - None at all ',
      value7: 'Interested - Not interested',
      value8: 'Easy - Difficult',
      value9: 'Always - Never',
      value10: 'Better - Worse',
      value11: 'Approve - Disapprove',
      value12: 'Above average - Below average',
      value13: 'High quality - Low quality',
      value14: 'True - False',
      value15: 'Definitely would -  Definitely would not',
      value16: 'Useful - Not useful',
      value17: 'Valuable - Notvaluable',
      value18: 'Clear - Notclear',
      value19: 'Helpful - Nothelpful',
      selectvalue: matrixtype,
      handleClose: selectClose,
      handleOpen: selectOpen,
      handleChange: matrixMultiple,
    },
  ];

  // matrix checkbox code start
  const [opencheckbox, setOpencheckbox] = React.useState(false);
  const [selectvaluecheckbox, setInputcheckbox] = useState('');
  const [selectvaluecheckbox1, setInputcheckbox1] = useState('');
  const [showmatrixcheckbox, setShowmatrixcheckbox] = useState(false);

  const selectClosecheckbox = () => {
    setOpencheckbox(false);
  };

  const selectOpencheckbox = () => {
    setOpencheckbox(true);
  };

  const [matrixtypevaluecheck, setMatrixtypevaluecheck] = useState();
  const matrixMultiplecheckbox = (event) => {
    const sentence = event.target.value;
    setMatrixtypebox(sentence);
    selectClosecheckbox();

    const removedash = sentence.split('-');
    // const words = removedash.split(' ');
    const splitone = removedash[0].trim();
    const splittwo = removedash[1].trim();
    // alert(splittwo)
    // const word2 = `Very-${splitone}`;
    // const word3 = `Very-${splittwo}`;
    setInputcheckbox(splitone);
    setInputcheckbox1(splittwo);
    setShowmatrixcheckbox(true);
    setMarixboxalert('');

    const updatedRowInput = [...inputcolumncheck];
    updatedRowInput[0].checkboxcolumn = splitone;
    updatedRowInput[1].checkboxcolumn = splittwo;
    // updatedRowInput[2].checkboxcolumn = word2;
    // updatedRowInput[3].checkboxcolumn = word3;
    // Update the state with the new rowinput
    setColumncheck(updatedRowInput);
  };

  const [minvaluesetcheck, setMinvaluesetcheck] = useState(false);
  const [maxvaluesetsecondcheck, setMaxvaluesetcheck] = useState(false);
  const [rowinputcheck, setRowinputcheck] = useState([
    { id: 1, checkboxrow: '' },
    { id: 2, checkboxrow: '' },
  ]);

  const [inputcolumncheck, setColumncheck] = useState([
    { id: 1, checkboxcolumn: '' },
    { id: 2, checkboxcolumn: '' },
  ]);

  const [isCheckedNotColcheck, setICheckedNotColcheck] = useState(false);
  const matrixmcqchekbox = [rowinputcheck, inputcolumncheck, isCheckedNotColcheck];
  const handelchangerowvaluecheck = (id, event) => {
    const updatedFields = [...rowinputcheck];
    updatedFields[id].checkboxrow = event.target.value;
    setRowinputcheck(updatedFields);
    setMatrixradiobtn('');
    setShowmatrixcheckbox(updatedFields);
  };

  const handelchangecolvaluecheck = (id, event) => {
    const updatedFields = [...inputcolumncheck];
    updatedFields[id].checkboxcolumn = event.target.value;
    setColumncheck(updatedFields);
    setMatrixradiobtncol('');
    setShowmatrixcheckbox(updatedFields);
  };

  const addRowcheckbox = () => {
    if (rowinputcheck.length >= 8) {
      setLimitalert(true);
      setMinimum(false);
      setTimeout(() => {
        setLimitalert(false);
      }, 2000);
    } else {
      setLimitalert(false);
      const newId = rowinputcheck.length ? rowinputcheck[rowinputcheck.length - 1].id + 1 : 1;
      setRowinputcheck([...rowinputcheck, { id: newId, checkboxrow: '' }]);
    }
  };

  const addColumncheckbox = () => {
    if (inputcolumncheck.length >= 8) {
      setLimitalert(true);
      setMinimum(false);
      setTimeout(() => {
        setLimitalert(false);
      }, 2000);
    } else {
      setLimitalert(false);
      const colid = inputcolumncheck.length ? inputcolumncheck[inputcolumncheck.length - 1].id + 1 : 1;
      setColumncheck([...inputcolumncheck, { id: colid, checkboxcolumn: '' }]);
    }
  };

  const removeRowcheckbox = (id) => {
    if (rowinputcheck.length <= 2) {
      setMinvaluesetcheck(true);
      setTimeout(() => {
        setMinvaluesetcheck(false);
      }, 2000);
    } else {
      const rowinputnew = rowinputcheck.filter((input) => input.id !== id);
      setRowinputcheck(rowinputnew);
    }
  };

  const removeColcheck = (id) => {
    if (inputcolumncheck.length <= 2) {
      setMaxvaluesetcheck(true);
      setTimeout(() => {
        setMaxvaluesetcheck(false);
      }, 2000);
    } else {
      const colinputnew = inputcolumncheck.filter((getcolumn) => getcolumn.id !== id);
      setColumncheck(colinputnew);
    }
  };

  const handleCheckedNotColcheck = (event) => {
    setICheckedNotColcheck(event.target.checked);
    setMatrixtypevaluecheck(isCheckedNotColcheck);
  };

  // checkbox btn
  const MatrixMcqDropdowncheckbox = [
    {
      dropdownclass: 'dropdownclass',
      width: '40%',
      title: 'Select matrix ',
      label: 'Select matrix',
      val1: 'Agree - Disagree',
      val2: 'Satisfied - Dissatisfied',
      val3: 'Yes - No',
      val4: 'Likely - Unlikely',
      val5: 'Familiar - Not familiar',
      val6: 'A great deal - None at all',
      val7: 'Interested - Not interested',
      val8: 'Easy - Difficult',
      val9: 'Always - Never',
      val10: 'Better - Worse',
      val11: 'Approve - Disapprove',
      val12: 'Above average - Below average',
      val13: 'High quality - Low quality',
      val14: 'True - False',
      val15: 'Definitely would - Definitely would not',
      val16: 'Useful - Not useful',
      val17: 'Valuable - Not valuable',
      val18: 'Clear - Not clear',
      val19: 'Helpful - Nothelpful',
      value1: 'Agree - Disagree',
      value2: 'Satisfied - Dissatisfied',
      value3: 'Yes - No',
      value4: 'Likely - Unlikely',
      value5: 'Familiar - Not familiar',
      value6: 'A great deal - None at all',
      value7: 'Interested - Not interested',
      value8: 'Easy - Difficult',
      value9: 'Always - Never',
      value10: 'Better - Worse',
      value11: 'Approve - Disapprove',
      value12: 'Above average - Below average',
      value13: 'High quality - Low quality',
      value14: 'True - False',
      value15: 'Definitely would - Definitely would not',
      value16: 'Useful - Not useful',
      value17: 'Valuable - Notvaluable',
      value18: 'Clear - Notclear',
      value19: 'Helpful - Nothelpful',
      selectvalue: matrixtypebox,
      handleClose: selectClosecheckbox,
      handleOpen: selectOpencheckbox,
      handleChange: matrixMultiplecheckbox,
    },
  ];
  // matrix checkbox code end

  const [createquestion, setCreatequestion] = useState('');
  const handleChangequestion = (event) => {
    setCreatequestion(event.target.value);
    setQuestiontextalert(false);
  };

  const [open, setOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const [checked, setChecked] = useState(false);
  const showcreatsurveypopup = useSelector((state) => state.showcreatsurveypopup);
  const [openalert, setOpenalert] = useState(false);
  const [descriptionbox, showDescriptive] = React.useState(false);
  const [showtwobox, setShowtwobox] = useState();
  const [showmatrix, setShowmatrix] = useState(false);
  const [selectvalue, setInputBoxmsq] = useState('');
  const [selectvalue1, setInputBoxmsq1] = useState('');

  const showDatepicker = () => {
    setChecked(!checked);
  };

  const handleClose = () => {
    inputFields.map((data) => ({ id: 0, value: '' }));
    setCreatequestion('');
    setSelectedOption('');
    setRatingArray(['']);
    setCreaterating('');
    setInputValues(['']);
    dispatch(closeCreateSurveypopup(false));
    setValidated(false);
    setInputFields(initialInputFields);
    setDropdownFields([
      { id: 0, value: '' },
      { id: 1, value: '' },
    ]);

    setRowinput(initialRowInput);
    setColumn(initialColInput);
    setGetinputdata(['']);
    setImagePreviews([]);
    setAnserInputrange(false);
  };

  const handleShow = () => {
    setValidated(true);
  };

  // const [review, setReview] = React.useState('');
  // const handleLabel = () => {
  //   setRating(!rating);
  // };

  // const [fileSizeExceeded, setFileSizeExceeded] = React.useState(false);
  // const maxFileSize = 512000; // 512Kb

  // const handelImageuploader = (event) => {
  //   console.log(event.target.files);
  //   setFile(URL.createObjectURL(event.target.files[0]));
  //   const file = event.target.files[0];
  //   if (file.size > maxFileSize) {
  //     setFileSizeExceeded(true);
  //     return; // do not process the file if it exceeds the size limit
  //   }
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     setFileSizeExceeded(false);
  //   };
  //   reader.readAsArrayBuffer(file);
  // };

  // const newLine = (event) => {

  //   event.preventDefault();

  //   console.log(sortedList)
  //   if (sortedList.length >= 8) {
  //     setOpenalert(true)
  //     setTimeout(() => {
  //       setOpenalert(false)
  //     }, 2000);
  //   } else {
  //     setSortedList(sortedList.concat(''))
  //   }
  // }

  // const newLine1 = (event) => {
  //   event.preventDefault();
  //   console.log(sortedList)
  //   if (sortedList1.length >= 8) {
  //     setOpenalert(true)
  //     setTimeout(() => {
  //       setOpenalert(false)
  //     }, 2000);
  //   } else {
  //     setSortedList1(sortedList1.concat(''))
  //   }
  // }

  // MCQ  DnD code start
  const [anserInput, setAnserInput] = useState(false);
  const [minimum, setMinimum] = useState(false);
  const [limitalert, setLimitalert] = useState(false);
  const initialInputFields = [
    { id: 0, value: '' },
    { id: 1, value: '' },
  ];
  const [inputFields, setInputFields] = useState(initialInputFields);

  const getmcqvalue = inputFields.map((filtervalue) => filtervalue.value);
  const nextId = () => (inputFields.length ? Math.max(...inputFields.map((field) => field.id)) + 1 : 1);

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    values[index].value = event.target.value;
    setInputFields(values);
    setAnserInput(false);
  };

  const handleAddFields = () => {
    if (inputFields.length >= 8) {
      setLimitalert(true);
      setMinimum(false);
      setTimeout(() => {
        setLimitalert(false);
      }, 2000);
    } else {
      setLimitalert(false);
      const values = [...inputFields];
      values.push({ id: nextId(), value: '' });
      setInputFields(values);
    }
  };

  const handleRemoveFields = (id) => {
    const newFields = inputFields.filter((field) => field.id !== id);

    if (newFields.length < 2) {
      setLimitalert(true);
      setMinimum(true);
      setTimeout(() => {
        setLimitalert(false);
      }, 2000);
      return;
    }
    console.log('error');
    setInputFields(newFields);
  };

  // MCQ  DnD code endp

  // dropdown DnD code start
  const [anserInputdropdown, setAnserInputdropdown] = useState(false);
  const initialDropdownFields = [
    { id: 0, value: '' },
    { id: 1, value: '' },
  ];

  const [dropdown, setDropdownFields] = useState(initialDropdownFields);
  const getfiltervalue = dropdown.map((filtervalue) => filtervalue.value);

  const dropdownid = () => (dropdown.length ? Math.max(...dropdown.map((field) => field.id)) + 1 : 1);

  const handleDropdownchange = (index, event) => {
    const values = [...dropdown];
    values[index].value = event.target.value;
    setDropdownFields(values);
    setAnserInputdropdown(false);
  };

  const handleAddropdownFields = () => {
    if (dropdown.length >= 8) {
      setLimitalert(true);
      setMinimum(false);
      setTimeout(() => {
        setLimitalert(false);
      }, 2000);
    } else {
      setOpenalert(false);
      const values = [...dropdown];
      values.push({ id: dropdownid(), value: '' });
      setDropdownFields(values);
    }
  };

  const handleRemovedropdownFields = (id) => {
    const newdropdownFields = dropdown.filter((field) => field.id !== id);

    if (newdropdownFields.length < 2) {
      setLimitalert(true);
      setMinimum(true);
      setTimeout(() => {
        setLimitalert(false);
      }, 2000);
    } else {
      setDropdownFields(newdropdownFields);
    }
  };
  const currentDate = new Date();
  const amPM = currentDate.toLocaleTimeString();
  // dropdown DnD code start

  // linear scale select min and max vlaue from select
  const [minValue, setMinValue] = useState(1);
  const [maxValue, setMaxValue] = useState(10);
  const linaerscale = [minValue, maxValue];

  const handleMinValueChange = (event) => {
    const newMinValue = parseInt(event.target.value, 10);
    setMinValue(newMinValue);
    if (maxValue < newMinValue) {
      setMaxValue(newMinValue);
    }
  };
  const handleMaxValueChange = (event) => {
    const newMaxValue = parseInt(event.target.value, 10);
    setMaxValue(newMaxValue);
  };
  const maxOptions = Array.from({ length: 11 - minValue }, (_, index) => (
    <MenuItem key={index + minValue} value={index + minValue}>
      {index + minValue}
    </MenuItem>
  ));
  const divElements = [];
  // eslint-disable-next-line no-plusplus
  for (let i = minValue; i <= maxValue; i++) {
    divElements.push(
      <Chip style={{ width: '80px' }} className="m-1 linear-chip" label={i} variant="outlined" key={i}>
        {i}
      </Chip>
    );
  }
  // select min and max vlaue from select

  // rating
  const [ratingalert, setRattingalert] = useState('');
  const [isshowlabel, setShowlabe] = useState(false);

  const handleShowLabele = () => {
    setShowlabe(!isshowlabel);
    if (!isshowlabel) {
      setInputValues(Array.from({ length: createrating }, (_, i) => (i + 1).toString()));
    }
  };

  const [addRating, setAddRating] = useState(false);
  const [inputCount, setInputCount] = useState(0);
  const [createrating, setCreaterating] = useState(0);

  const handelRating = (event) => {
    const selectednumber = event.target.value;
    setCreaterating(selectednumber);
    setRattingalert('');
    // Reset input values when rating changes
    setInputValues(Array(selectednumber).fill(''));
    const newInputValues = Array.from({ length: selectednumber }, (_, i) => (i + 1).toString());
    setInputValues(newInputValues);
    console.log('Rating changed:', selectednumber, newInputValues);
  };

  const [shape, setShape] = React.useState('2');
  const [liketype, setShowLike] = React.useState('');
  const [inputValues, setInputValues] = useState(['']);

  const handleLiketype = (event) => {
    setRattingalert('');
    setShape(event.target.value);
    setShowLike(event.target.value);
    if (event.target.value === '1') {
      setShowLike(true);
    } else if (event.target.value === '2') {
      setShowLike(false);
    }
  };

  // const handleInputChangerating = (index, event) => {
  //   setRattingalert("")
  //   const newInputValues = [...inputValues];
  //   newInputValues[index] = [event.target.value];
  //   // const ab = [[1],[2]] this for remove extra brackets [1]
  //   const removebrackets = newInputValues.map((innerArray) => innerArray[0]);
  //   setInputValues(removebrackets);
  //   setShowLike(liketype)
  // };

  // const handleInputChangerating = (index, event) => {
  //   setRattingalert('');
  //   const newInputValues = [...inputValues];
  //   newInputValues[index] = event.target.value;
  //   setInputValues(newInputValues);
  //   setShowLike(liketype);
  // };

  const handleInputChangerating = (index, event) => {
    setRattingalert('');
    const newInputValues = [...inputValues];
    if (isshowlabel) {
      newInputValues[index] = event.target.value;
    } else {
      const ratingValue = parseInt(event.target.value, 10);
      for (let i = 0; i < ratingValue; i += 1) {
        newInputValues[i] = (i + 1).toString();
      }
    }
    setInputValues(newInputValues);
    console.log('Updated input values:', newInputValues);
  };

  useEffect(() => {
    const newRatingArray = [String(createrating), shape, isshowlabel, inputValues];
    setRatingArray(newRatingArray);
  }, [inputValues, shape, liketype, createrating]);
  // rating

  // range slider
  const [leftside, setLeftside] = useState();
  const [middleside, setMiddleside] = useState();
  const [rightside, setRightside] = useState();
  const [anserInputrange, setAnserInputrange] = useState(false);
  const rangeValue = [leftside, middleside, rightside];

  const handelSetrightrange = (e) => {
    setRightside(e.target.value);
    setAnserInputrange(false);
    validateValues();
  };

  const handelSetMiddlerange = (e) => {
    setMiddleside(e.target.value);
    setAnserInputrange(false);
    validateValues();
  };

  const handelSetleftrange = (e) => {
    setLeftside(e.target.value);
    setAnserInputrange(false);
    validateValues();
  };

  const validateValues = () => {
    if (Number(middleside) <= Number(leftside)) {
      return 'Second value must be greater than the first value.';
    }
    if (Number(rightside) <= Number(middleside)) {
      return 'Third value must be greater than the second value.';
    }
    if (Number(leftside) >= Number(rightside)) {
      return 'Third value must be greater than the first value.';
    }
    return '';
  };

  // range slider

  const [datetyoealert, setDatetyoealert] = React.useState('');
  const [checkdate, setCheckdate] = React.useState(true);
  const [checktime, setChecktime] = React.useState(true);
  const [datealert, setDatealert] = React.useState(false);

  const checkDate = (event) => {
    setCheckdate(event.target.checked);
    setDatealert(false);
  };

  const checkTime = (event) => {
    setChecktime(event.target.checked);
    setDatealert(false);
  };

  const [datetype, setDatetype] = useState(''); // Default value
  const handelDatetype = (event) => {
    setDatetype(event.target.value);
    setDatealert(false);
  };

  // for crate array post api
  const datearray = [String(checkdate), String(checktime), datetype];
  // date time checker

  // file uploader code
  const [fileuploaderalert, setfileuploaderalert] = React.useState('');
  const [filecheck, setFilecheck] = useState(''); // Default value
  const [warningmsg, setWarningmsg] = useState(''); // Default value
  const fileuploaderarray = [filecheck, warningmsg];
  const checkFileuoloader = (event) => {
    setFilecheck(event.target.value);
    setfileuploaderalert('');
  };

  const handelWarningmsg = (e) => {
    setWarningmsg(e.target.value);
    setfileuploaderalert('');
  };

  const [imgcheckimg, setCheckimg] = useState('');

  const createImage = () => {
    setCheckimg(imgcheckimg);
  };
  // file uploader code

  // create input and upload image
  // const [checkboxid, setCheckboxid] = useState()
  // const showDescription = (id) => {
  //   showDescriptive(!descriptionbox)
  //   setCheckboxid(id)
  // };
  // const [inputFieldsimg, setInputFieldsimg] = useState([
  //   { id: 1, textValue: '', fileValue: '' },
  // ]);

  // const addInputField = () => {
  //   const nextIdimg = inputFieldsimg.length + 1
  //   setInputFieldsimg([...inputFieldsimg, { id: nextIdimg, textValue: '', fileValue: '' }]);
  // };

  // const handleTextChange = (index, event) => {
  //   const updatedFields = [...inputFieldsimg];
  //   updatedFields[index].textValue = event.target.value;
  //   setInputFieldsimg(updatedFields);
  //   console.log([inputFieldsimg[index].textValue ])
  // };

  // const handleFileChange = async (index, event) => {
  //   const updatedFields = [...getinputdata];
  //   updatedFields[index].fileValue = event.target.files[index];
  //   setGetinputdata(updatedFields);
  //   handelPostImage()
  // };

  // const deleteInputField = (id) => {
  //   const updatedFields = inputFieldsimg.filter((feild) => feild.id !== id);
  //   setInputFieldsimg(updatedFields);
  // }

  // const [imgdescription, setImgdescription] = useState("")

  // const handelAdddescription = (id, event) => {
  //   const updatedFields = [...imgdescription];
  //   updatedFields[id].textValue  = event.target.value;
  //   setImgdescription(updatedFields);
  // }

  // img-uploader, img-answer

  const [imgurl, setimageurl] = useState();
  const [getinputdata, setGetinputdata] = useState([
    { label: '', showInput: false, additionalInput: '', fileInput: null, getImages: JSON.stringify(imgurl) },
  ]);
  const [imgageslectalert, setImgageslectalert] = useState(false);
  const [imagePreviews, setImagePreviews] = useState([]);

  // const handleFileInputChange = (index, event) => {
  //   const selectedFile = event.target.files[0];
  //   const updatedPreviews = [...imagePreviews];
  //   updatedPreviews[index] = URL.createObjectURL(selectedFile);
  //   setImagePreviews(updatedPreviews);

  //   const updatedFields = [...getinputdata];
  //   updatedFields[index].fileInput = event.target.files;
  //   setGetinputdata(updatedFields);
  //   handelPostImage(index);
  // };
  // const [isCheckedmoreans, setCheckedmoreans] = useState(false);

  const handleFileInputChange = async (index, event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const updatedPreviews = [...imagePreviews];
      updatedPreviews[index] = URL.createObjectURL(selectedFile);
      setImagePreviews(updatedPreviews);

      const updatedFields = [...getinputdata];
      updatedFields[index].fileInput = event.target.files;
      setGetinputdata(updatedFields);

      const uid = localStorage.getItem('uid');
      const urlParams = new URLSearchParams(window.location.search);
      const getidfromurl = urlParams.get('id');
      const formData = new FormData();
      formData.append('theFile', selectedFile);
      formData.append('que_id', setquestionid);
      formData.append('surveyid', getidfromurl);
      formData.append('userid', uid);

      try {
        const endpoint = API_ENDPOINTS.answerImagePOST;
        const res = await fetch(`${BASE_URL}${endpoint}`, {
          method: 'POST',
          body: formData,
        });
        if (res.status === 200) {
          const responseJson = await res.json();
          const newImageUrl = responseJson.logo;
          setimageurl(newImageUrl);

          setGetinputdata((prevInputData) => {
            const updatedData = [...prevInputData];
            updatedData[index].getImages = newImageUrl;
            return updatedData;
          });

          console.log('Answer Image Uploaded');
        } else {
          console.error('Error posting Image data:', res.status);
        }
      } catch (error) {
        console.error('Error posting Image data:', error);
      }
    }
  };

  const handleAddFielddata = () => {
    if (getinputdata.length >= 8) {
      setLimitalert(true);
      setMinimum(false);
      setTimeout(() => {
        setLimitalert(false);
      }, 2000);
    } else {
      setLimitalert(false);
      setGetinputdata([
        ...getinputdata,
        { label: '', showInput: false, additionalInput: '', fileInput: null, getImages: '' },
      ]);
      setImagePreviews([...imagePreviews, null]); // Add a corresponding entry in imagePreviews
    }
  };

  const handleInputdataChange = (index, event) => {
    const updatedFields = [...getinputdata];
    updatedFields[index].label = event.target.value;
    setGetinputdata(updatedFields);
  };

  const handleAdditionalInputdataChange = (index, event) => {
    const updatedFields = [...getinputdata];
    updatedFields[index].additionalInput = event.target.value;
    setGetinputdata(updatedFields);
  };

  const handleCheckboxdataChange = (index) => {
    const updatedFields = [...getinputdata];
    updatedFields[index].showInput = !updatedFields[index].showInput;
    setGetinputdata(updatedFields);
  };

  const handleDeleteField = (index) => {
    if (getinputdata.length <= 1) {
      setImgageslectalert(true);
      setTimeout(() => {
        setImgageslectalert(false);
      }, 1000);
    } else {
      const updatedFields = [...getinputdata];
      updatedFields.splice(index, 1);
      setGetinputdata(updatedFields);

      const updatedImagePreviews = [...imagePreviews];
      updatedImagePreviews.splice(index, 1);
      setImagePreviews(updatedImagePreviews);
    }
  };

  // imgagepostapi
  const handelPostImage = async (index) => {
    const uid = localStorage.getItem('uid');
    const urlParams = new URLSearchParams(window.location.search);
    const getidfromurl = urlParams.get('id');
    const formData = new FormData();
    formData.append('theFile', getinputdata[index].fileInput[0]);
    formData.append('que_id', setquestionid);
    formData.append('surveyid', getidfromurl);
    formData.append('userid', uid);
    try {
      const endpoint = API_ENDPOINTS.answerImagePOST;
      const res = await fetch(`${BASE_URL}${endpoint}`, {
        method: 'POST',
        body: formData,
      });
      if (res.status === 200) {
        const responseJson = await res.json(); // Get the JSON response data
        const newImageUrl = responseJson.logo;
        setimageurl(newImageUrl);
        setGetinputdata((getinputdata) => {
          const updatedData = [...getinputdata];
          // const updatedIndex = 0;
          updatedData[index] = {
            ...updatedData[index],
            getImages: newImageUrl, // Use the newImageUrl here
          };
          return updatedData;
        });
        // console.log(responseJson);
        console.log('answer Image Uploaded');
      } else {
        console.error('Error posting Image data:', res.status);
      }
    } catch (error) {
      console.error('Error posting Image data:', error);
    }
  };
  // create input and upload image

  // on check more answer for question
  const [isCheckedmoreans, setIsCheckedmoreans] = useState(false);
  const handleCheckmoreans = (event) => {
    setIsCheckedmoreans(event.target.checked);
    console.log(isChecked);
  };
  // on check more answer for question

  const [minvalueset, setMinvalueset] = useState(false);
  const [maxvaluesetsecond, setMaxvalueset] = useState(false);

  const [rowinput, setRowinput] = useState([
    { id: 1, rowvalue: '' },
    { id: 2, rowvalue: '' },
  ]);

  const [inputcolumn, setColumn] = useState([
    { id: 1, columnvalue: '' },
    { id: 2, columnvalue: '' },
  ]);

  const [isCheckedNotCol, setICheckedNotCol] = useState(false);
  const matrixmcq = [rowinput, inputcolumn, isCheckedNotCol];

  const handelchangerowvalue = (id, event) => {
    const updatedFields = [...rowinput];
    updatedFields[id].rowvalue = event.target.value;
    setRowinput(updatedFields);
    setMatrixinputvalue(' ');
    setShowmatrix(updatedFields);
  };

  const handelchangecolvalue = (id, event) => {
    setMatrixinputvaluebox('');
    const updatedFields = [...inputcolumn];
    updatedFields[id].columnvalue = event.target.value;
    setColumn(updatedFields);
    setShowmatrix(updatedFields);
  };

  const addRow = () => {
    if (rowinput.length >= 8) {
      setLimitalert(true);
      setMinimum(false);
      setTimeout(() => {
        setLimitalert(false);
      }, 2000);
    } else {
      const newId = rowinput.length ? rowinput[rowinput.length - 1].id + 1 : 1;
      setRowinput([...rowinput, { id: newId, rowvalue: '' }]);
    }
  };

  const addColumn = () => {
    if (inputcolumn.length >= 8) {
      setLimitalert(true);
      setMinimum(false);
      setTimeout(() => {
        setLimitalert(false);
      }, 2000);
    } else {
      const colid = inputcolumn.length ? inputcolumn[inputcolumn.length - 1].id + 1 : 1;
      setColumn([...inputcolumn, { id: colid, columnvalue: '' }]);
    }
  };
 
  const removeRow = (id) => {
    if (rowinput.length <= 2) {
      setMinvalueset(true);
      setTimeout(() => {
        setMinvalueset(false);
      }, 2000);
    } else {
      const rowinputnew = rowinput.filter((input) => input.id !== id);
      setRowinput(rowinputnew);
    }
  };
  const removeCol = (id) => {
    if (inputcolumn.length <= 2) {
      setMaxvalueset(true);
      setTimeout(() => {
        setMaxvalueset(false);
      }, 2000);
    } else {
      const colinputnew = inputcolumn.filter((getcolumn) => getcolumn.id !== id);
      setColumn(colinputnew);
    }
  };

  const handleChangeNA = (event) => {
    setICheckedNotCol(event.target.checked);
    setMatrixtypevalue(isCheckedNotCol);
  };
  // matrix radio code

  // multiple select answer checkbox code
  const [multipleSelect, setMultipleSelect] = useState(false);
  const selectMultipleAnswer = (event) => {
    setMultipleSelect(event.target.checked);
  };

  return (
    <>
      <Modal id="createquestionpopup" show={showcreatsurveypopup} onHide={handleClose} onShow={handleShow}>
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', top: '5px', right: '15px' }}
          aria-label="delete"
        >
          <AiOutlineClose />
        </IconButton>

        {createsurvey.map((getsurveymodal) => (
          <>
            <Form className="py-5 px-3 p-lg-5" onSubmit={handlCreateQuestion}>
              <div>
                <h5 className="p-3 ">{getsurveymodal.surveytitle}</h5>
              </div>
              <Row className="mb-3 p-3   p-0 ">
                {/* create question input  */}
                <Form.Group className="mt-2 mb-2 p-0" as={Col} md="12">
                  <Form.Label>{getsurveymodal.inputlabel1}</Form.Label>
                  <Form.Control
                    type=""
                    placeholder={getsurveymodal.input1placeholder}
                    value={createquestion}
                    onChange={handleChangequestion}
                  />
                </Form.Group>
                {questiontextalert && (
                  <span style={{ color: 'red', fontSize: '14px' }}>You must enter question text.</span>
                )}
                {/* Answer type input  */}
                <Form.Group className="mt-1  p-0" as={Col} md="12">
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Label className="m-0">{getsurveymodal.inputlabel2}</Form.Label>

                    {/* Answer type Required  */}
                    <FormControlLabel
                      control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
                      label="Required"
                    />
                  </div>

                  {/* <div>
      <Form.Select
        className="mb-4 rounded-0"
        style={{ width: `${getsurveymodal.widthclass},${getsurveymodal.display}` }}
        value={selectedOption}
        onChange={selectValue}
        aria-label="Select Answer Type"
      >
        <option value="" disabled hidden>
          Select Answer Type
        </option>
        <option value="2" style={{ backgroundImage: 'url("/src/img/question-and-answer.png")', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center', paddingLeft: '20px' }}>
          Short Answers
        </option>
        <option value="1">Long Answers</option>
        <option value="12">Multiple Choice</option>
        <option value="3">Rating</option>
        <option value="4">Dropdown</option>
        <option value="5">Linear-scale</option>
        <option value="6">RangeSlider</option>
        <option value="7">Date Time</option>
        <option value="8">File Upload</option>
        <option value="9">Imagechoice</option>
        <option value="10">Matrix MCQ</option>
        <option value="11">Matrix Checkbox</option>
      </Form.Select>
    </div> */}
 <div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 ,width:'100%'}}>
      <InputLabel id="demo-simple-select-standard-label">Select Answer Type</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          value={selectedOption}
          onChange={selectValue}
          className="mb-4 rounded-0"
          sx={{ width: '100%' }}
          aria-label="Select Answer Type"
          renderValue={(selected) => {
            const option = options.find(opt => opt.value === selected);
            return (
              <div className="d-flex align-items-center">
                <img src={option.img} alt="" style={{ marginRight: '10px', width: '20px', height: '20px' }} />
                {option.label}
              </div>
            );
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value} className="d-flex align-items-center">
              <img src={option.img} alt="" style={{ marginRight: '10px', width: '20px', height: '20px' }} />
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
    {/* <div>
  <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>
    <InputLabel id="demo-simple-select-standard-label">Select Answer Type</InputLabel>
    <Select
      labelId="demo-simple-select-standard-label"
      value={String(selectedOption)}
      onChange={selectValue}
      className="mb-4 rounded-0"
      sx={{ width: '100%' }}
      aria-label="Select Answer Type"
      renderValue={(selected) => {
        const option = options.find((opt) => opt.value === String(selected));
        if (!option) {
          return (<div>No option selected</div>);
        }
        return (
          <div className="d-flex align-items-center">
            <img src={option.img} alt="" style={{ marginRight: '10px', width: '20px', height: '20px' }} />
            {option.label}
          </div>
        );
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={String(option.value)} className="d-flex align-items-center">
          <img src={option.img} alt="" style={{ marginRight: '10px', width: '20px', height: '20px' }} />
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</div> */}
                  {/*  multiple choice question */}
                  <div className="mt-2 col-md-12 position-relative">
                    {selectedOption === 12 && (
                      <>
                        <div className="mcq-question">
                          <div className="page">
                            <div
                              className="container"
                              style={{ maxWidth: 'none', paddingLeft: 'inherit', paddingRight: 'inherit' }}
                            >
                              <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className="">Choices</Form.Label>
                              </div>
                              <ReactSortable list={inputFields} setList={setInputFields}>
                                {inputFields.map((inputField, index) => (
                                  <div className="my-2 position-relative d-flex align-items-center" key={inputField.id}>
                                    <GrDrag style={{ cursor: 'all-scroll', fontSize: '25px' }} />
                                    <input
                                      type="text"
                                      style={{ width: '100%' }}
                                      value={inputField.value}
                                      onChange={(event) => handleInputChange(index, event)}
                                      className="form-control input-item ms-2"
                                      placeholder="Enter text here"
                                    />
                                    {inputFields.length > 2 && (
                                      <IconButton aria-label="delete" onClick={() => handleRemoveFields(inputField.id)}>
                                        <DeleteOutlineTwoToneIcon />
                                      </IconButton>
                                    )}
                                  </div>
                                ))}
                              </ReactSortable>
                              {anserInput && (
                                <span className="mt-1" style={{ color: 'red', fontSize: '14px', marginLeft: '35px' }}>
                                  Please Enter Answers In input fields.
                                </span>
                              )}
                            </div>
                            {limitalert && (
                              <Alert className="p-1" size="small" severity="error">
                                {minimum ? 'Minimum 2 options are required' : 'Maximum 8 answer options are allowed'}
                              </Alert>
                            )}
                          </div>
                        </div>
                        <Button className="mt-3 bgButton" variant='success' onClick={handleAddFields} size="sm">
                          <AiOutlinePlus />
                        </Button>
                        <FormGroup className="flex-row justify-content-between mt-3">
                          <FormControlLabel
                            className="select-multiple"
                            color="info"
                            checked={multipleSelect}
                            onChange={selectMultipleAnswer}
                            control={<Checkbox defaultChecked />}
                            label="Select Multiple Answers "
                          />
                        </FormGroup>
                      </>
                    )}
                  </div>

                  {/* DropDown */}
                  <div className="mt-2 col-md-12 position-relative">
                    {selectedOption === 4 && (
                      <>
                        <Form>
                          <div className="mcq-question">
                            <div className="page">
                              <div
                                className="container"
                                style={{ maxWidth: 'none', paddingLeft: 'inherit', paddingRight: 'inherit' }}
                              >
                                <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                  <Form.Label className="">Choices</Form.Label>
                                </div>
                                <ReactSortable list={dropdown} setList={setDropdownFields}>
                                  {dropdown.map((inputField, index) => (
                                    <div
                                      className="my-2 position-relative d-flex align-items-center"
                                      key={inputField.id}
                                    >
                                      <GrDrag style={{ cursor: 'all-scroll', fontSize: '25px' }} />
                                      <input
                                        type="text"
                                        value={inputField.value}
                                        onChange={(event) => handleDropdownchange(index, event)}
                                        style={{ width: '100%' }}
                                        className="form-control input-item ms-2"
                                        placeholder="Enter text here"
                                      />
                                      {dropdown.length > 2 && (
                                        <IconButton aria-label="delete">
                                          <DeleteOutlineTwoToneIcon
                                            onClick={() => handleRemovedropdownFields(inputField.id)}
                                          />
                                        </IconButton>
                                      )}
                                    </div>
                                  ))}
                                </ReactSortable>
                                {anserInputdropdown && (
                                  <span className="mt-1" style={{ color: 'red', fontSize: '14px', marginLeft: '35px' }}>
                                    Please Enter Answers In input fields.
                                  </span>
                                )}
                              </div>
                              {limitalert && (
                                <Alert className="p-1" size="small" severity="error">
                                  {minimum ? 'Minimum 2 options are required' : 'Maximum 8 answer options are allowed'}
                                </Alert>
                              )}
                            </div>
                          </div>
                        </Form>
                        <Button className="mt-3" onClick={handleAddropdownFields} size="sm">
                          <AiOutlinePlus />
                        </Button>
                        <FormGroup className="flex-row justify-content-between mt-3">
                          {/* Additional form elements if needed */}
                        </FormGroup>
                      </>
                    )}
                  </div>

                  {/* Linear-scale */}
                  {selectedOption === 5 && (
                    <div className=" mt-3 m-auto">
                      <div className="d-flex ">
                        <Box sx={{ minWidth: 120 }} className="mb-2">
                          {' '}
                          {/* Add mb-2 class for margin bottom */}
                          <FormControl fullWidth>
                            <InputLabel id="min-value-label">Minimum Value</InputLabel>
                            <Select
                              sx={{ maxWidth: 120 }}
                              labelId="min-value-label"
                              value={minValue}
                              label="Minimum Value"
                              onChange={handleMinValueChange}
                            >
                              {Array.from({ length: 10 }, (_, index) => (
                                <MenuItem key={index} value={index + 1}>
                                  {index + 1}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box sx={{ minWidth: 120 }} className="mb-2 mx-2">
                          {' '}
                          {/* Add mb-2 and mx-auto classes for margin bottom and auto horizontal margin */}
                          <FormControl fullWidth>
                            <InputLabel id="max-value-label">Maximum Value</InputLabel>
                            <Select
                              sx={{ maxWidth: 120 }}
                              labelId="max-value-label"
                              value={maxValue}
                              label="Maximum Value"
                              onChange={handleMaxValueChange}
                            >
                              {maxOptions}
                            </Select>
                          </FormControl>
                        </Box>
                      </div>

                      <div className="mt-3 flex-wrap">{divElements}</div>
                    </div>
                  )}
                  <SelectAnswerTypeModal isOpen={showSelectAnswerTypeModal} toggleModal={closeSelectAnswerTypeModal} />

                  {/* Matrix-Checkbox */}
                  {selectedOption === 11 && (
                    <>
                      <DropDown dropdown={MatrixMcqDropdowncheckbox} />
                      <span style={{ color: 'red', fontSize: '14px' }}>{matrixboxalert}</span>
                      {showmatrixcheckbox && (
                        <>
                          <div>
                            <div className="mb-2">
                              <div className="mb-3">
                                <Form.Label className="">Rows</Form.Label>
                              </div>
                              {rowinputcheck.map((input, index) => (
                                <>
                                  <div key={input.id} className="position-relative">
                                    <TextField
                                      size="small"
                                      value={input.checkboxrow}
                                      onChange={(event) => handelchangerowvaluecheck(index, event)}
                                      // placeholder={input.id}
                                      className=" w-100 position-relative mb-3"
                                      label="Enter a label"
                                      variant="outlined"
                                    />
                                    {rowinputcheck.length > 2 && (
                                      <IconButton
                                        onClick={() => removeRowcheckbox(input.id)}
                                        style={{ position: 'absolute', top: '0px', right: '15px' }}
                                        aria-label="delete"
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    )}
                                  </div>
                                </>
                              ))}

                              <span style={{ color: 'red', fontSize: '14px' }}>{matrixradiobtn}</span>
                              {minvaluesetcheck && (
                                <Alert className="p-1" size="small" severity="error">
                                  {'Manimum 3  input are required'}
                                </Alert>
                              )}
                              {/* <Button  onClick={addRow} style={{ float: 'right',backgroundColor:"gray",color:"white" }} variant="contained"> + Add</Button> */}
                            </div>
                            <Button className='bgButton' variant='success' onClick={addRowcheckbox} size="sm">
                              <AiOutlinePlus />
                            </Button>

                            <div>
                              <div className="mb-3">
                                <Form.Label className="mt-3">Columns</Form.Label>
                              </div>
                              {inputcolumncheck.map((getcolumn, index) => (
                                <>
                                  <div key={getcolumn.id} className="position-relative">
                                    <TextField
                                      size="small"
                                      value={getcolumn.checkboxcolumn}
                                      // placeholder={getcolumn.id}
                                      onChange={(event) => handelchangecolvaluecheck(index, event)}
                                      className=" w-100 position-relative mb-3"
                                      label="Enter a label"
                                      variant="outlined"
                                    />

                                    {inputcolumncheck.length > 2 && (
                                      <IconButton
                                        onClick={() => removeColcheck(getcolumn.id)}
                                        style={{ position: 'absolute', top: '0px', right: '15px' }}
                                        aria-label="delete"
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    )}
                                  </div>
                                </>
                              ))}
                              <Button onClick={addColumncheckbox} size="sm" className="mb-3 bgButton" variant='success'>
                                <AiOutlinePlus />
                              </Button>
                              {limitalert && (
                                <Alert className="p-1" size="small" severity="error">
                                  {minimum ? 'Minimum 2 options are required' : 'Maximum 8 answer options are allowed'}
                                </Alert>
                              )}
                              <span style={{ color: 'red', fontSize: '14px' }}>{matrixradiobtncol}</span>
                              <br />
                              {maxvaluesetsecondcheck && (
                                <Alert className="p-1" size="small" severity="error">
                                  {'Manimum 3  input are required'}
                                </Alert>
                              )}

                              <FormControlLabel
                                className="d-none"
                                control={
                                  <Checkbox checked={isCheckedNotColcheck} onChange={handleCheckedNotColcheck} />
                                }
                                label="N/A"
                              />
                            </div>
                          </div>
                          <br />
                          <div className="mb-4" />
                          <div className="border-0">
                            <Accordion>
                              <Accordion.Item className="border-0" eventKey="0">
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  <Accordion.Header style={{ width: 'auto !important', color: 'black' }}>
                                    Preview
                                  </Accordion.Header>
                                </div>
                                <Accordion.Body
                                  className="ques-prev-div"
                                  style={{ margin: 'auto', width: '60%', maxWidth: '60%' }}
                                >
                                  <Table responsive>
                                    <thead>
                                      <tr>
                                        <th style={{ border: 'none' }}>&nbsp;</th>
                                        {inputcolumncheck.map((getcolumn) => (
                                          <>
                                            <th
                                              key={getcolumn.id}
                                              style={{
                                                textAlign: 'center',
                                                border: 'none',
                                                maxWidthwidth: '300px',
                                                width: '200px',
                                                textTransform: 'capitalize',
                                              }}
                                            >
                                              {getcolumn.checkboxcolumn}
                                            </th>
                                          </>
                                        ))}
                                        {isCheckedNotColcheck && (
                                          <th
                                            key="1"
                                            style={{
                                              textAlign: 'center',
                                              border: 'none',
                                              maxWidthwidth: '300px',
                                              width: '200px',
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            N/A
                                          </th>
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {rowinputcheck.map((input) => (
                                        <>
                                          <tr>
                                            <div key={input.id} className="position-relative">
                                              <th
                                                key={input.id}
                                                style={{
                                                  textAlign: 'center',
                                                  textTransform: 'capitalize',
                                                  maxWidthwidth: '300px',
                                                  width: '200px',
                                                }}
                                              >
                                                {input.checkboxrow}
                                              </th>
                                            </div>
                                            {inputcolumncheck.map((getcolumn) => (
                                              <>
                                                <td
                                                  style={{
                                                    textAlign: 'center',
                                                    border: 'none',
                                                    maxWidthwidth: '300px',
                                                    width: '200px',
                                                  }}
                                                  key={getcolumn.id}
                                                >
                                                  {' '}
                                                  <Form.Check type="checkbox" />
                                                </td>
                                              </>
                                            ))}
                                            {isCheckedNotColcheck && (
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  border: 'none',
                                                  maxWidthwidth: '300px',
                                                  width: '200px',
                                                }}
                                                key="1"
                                              >
                                                {' '}
                                                <Form.Check type="checkbox" />
                                              </td>
                                            )}
                                          </tr>
                                        </>
                                      ))}
                                    </tbody>
                                  </Table>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {/* Matrix-MCQ radio */}
                  {selectedOption === 10 && (
                    <>
                      <DropDown dropdown={MatrixMcqDropdown} />
                      <span style={{ color: 'red', fontSize: '14px' }}>{matrixtypealert}</span>
                      {showmatrix && (
                        <>
                          <div>
                            <div className="mb-2">
                              <div className="mb-3">
                                <Form.Label className="">Rows</Form.Label>
                              </div>
                              {rowinput.map((input, index) => (
                                <>
                                  <div key={input.id} className="position-relative">
                                    <TextField
                                      size="small"
                                      value={input.rowvalue}
                                      onChange={(event) => handelchangerowvalue(index, event)}
                                      // placeholder={input.id}
                                      className=" w-100 position-relative mb-3"
                                      label="Enter a label"
                                      variant="outlined"
                                    />
                                    {rowinput.length > 2 && (
                                      <IconButton
                                        onClick={() => removeRow(input.id)}
                                        style={{ position: 'absolute', top: '0px', right: '15px' }}
                                        aria-label="delete"
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    )}
                                  </div>
                                </>
                              ))}
                              <span className="mt-1" style={{ color: 'red', fontSize: '14px' }}>
                                {matrixinputvalue}
                              </span>
                              {minvalueset && (
                                <Alert className="p-1" size="small" severity="error">
                                  {'Manimum 3  input are required'}
                                </Alert>
                              )}
                            </div>
                            <Button className='bgButton' variant='success' onClick={addRow} size="sm">
                              <AiOutlinePlus />
                            </Button>

                            <div>
                              <div className="mb-3">
                                <Form.Label className="mt-3">Columns</Form.Label>
                              </div>
                              {inputcolumn.map((getcolumn, index) => (
                                <>
                                  <div key={getcolumn.id} className="position-relative">
                                    <TextField
                                      size="small"
                                      value={getcolumn.columnvalue}
                                      // placeholder={getcolumn.id}
                                      onChange={(event) => handelchangecolvalue(index, event)}
                                      className=" w-100 position-relative mb-3"
                                      label="Enter a label"
                                      variant="outlined"
                                    />
                                    {inputcolumn.length > 2 && (
                                      <IconButton
                                        onClick={() => removeCol(getcolumn.id)}
                                        style={{ position: 'absolute', top: '0px', right: '15px' }}
                                        aria-label="delete"
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    )}
                                  </div>
                                </>
                              ))}
                              <Button onClick={addColumn} variant='success' size="sm" className="mb-3 bgButton">
                                <AiOutlinePlus />
                              </Button>
                              {limitalert && (
                                <Alert className="p-1" size="small" severity="error">
                                  {minimum ? 'Minimum 2 options are required' : 'Maximum 8 answer options are allowed'}
                                </Alert>
                              )}
                              <span className="mt-1" style={{ color: 'red', fontSize: '14px' }}>
                                {matrixinputvaluebox}
                              </span>
                              <br />
                              {maxvaluesetsecond && (
                                <Alert className="p-1" size="small" severity="error">
                                  {'Manimum 3  input are required'}
                                </Alert>
                              )}
                              <FormControlLabel
                                className="d-none"
                                control={<Checkbox checked={isCheckedNotCol} onChange={handleChangeNA} />}
                                label="N/A"
                              />
                            </div>
                          </div>
                          <br />
                          <div className="mb-4" />
                          <div className="border-0">
                            <Accordion>
                              <Accordion.Item className="border-0" eventKey="0">
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  <Accordion.Header
                                    style={{ width: 'auto !important', color: 'black' }}
                                    className="preview "
                                  >
                                    Preview
                                  </Accordion.Header>
                                </div>
                                <Accordion.Body
                                  className="ques-prev-div"
                                  style={{ margin: 'auto', width: '60%', maxWidth: '60%' }}
                                >
                                  <Table responsive>
                                    <thead>
                                      <tr>
                                        <th style={{ border: 'none' }}>&nbsp;</th>
                                        {inputcolumn.map((getcolumn) => (
                                          <>
                                            <th
                                              key={getcolumn.id}
                                              style={{
                                                textAlign: 'center',
                                                border: 'none',
                                                maxWidthwidth: '300px',
                                                width: '200px',
                                                textTransform: 'capitalize',
                                              }}
                                            >
                                              {getcolumn.columnvalue}
                                            </th>
                                          </>
                                        ))}
                                        {isCheckedNotCol && (
                                          <th
                                            key="1"
                                            style={{
                                              textAlign: 'center',
                                              border: 'none',
                                              maxWidthwidth: '300px',
                                              width: '200px',
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            N/A
                                          </th>
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {rowinput.map((input) => (
                                        <>
                                          <tr>
                                            <div key={input.id} className="position-relative">
                                              <th
                                                key={input.id}
                                                style={{
                                                  textAlign: 'center',
                                                  textTransform: 'capitalize',
                                                  maxWidthwidth: '300px',
                                                  width: '200px',
                                                }}
                                              >
                                                {input.rowvalue}
                                              </th>
                                            </div>
                                            {inputcolumn.map((getcolumn) => (
                                              <>
                                                <td
                                                  style={{
                                                    textAlign: 'center',
                                                    border: 'none',
                                                    maxWidthwidth: '300px',
                                                    width: '200px',
                                                  }}
                                                  key={getcolumn.id}
                                                >
                                                  {' '}
                                                  <Form.Check type="radio" />
                                                </td>
                                              </>
                                            ))}

                                            {isCheckedNotCol && (
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  border: 'none',
                                                  maxWidthwidth: '300px',
                                                  width: '200px',
                                                }}
                                                key="1"
                                              >
                                                {' '}
                                                <Form.Check type="radio" />
                                              </td>
                                            )}
                                          </tr>
                                        </>
                                      ))}
                                    </tbody>
                                  </Table>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {/* rangeSlide  */}
                  {selectedOption === 6 && (
                    <>
                      <Form.Label>Range</Form.Label>
                      <InputGroup className="mt-3">
                        <InputGroup.Text
                          className="justify-content-center rangeslider_style rounded-0"
                          style={{ width: '10%' }}
                        >
                          Left Side
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          onChange={handelSetleftrange}
                          value={leftside}
                          aria-label="Small"
                          aria-describedby="basic-addon3"
                        />
                      </InputGroup>
                      <InputGroup className="mt-3">
                        <InputGroup.Text
                          className="justify-content-center rangeslider_style rounded-0"
                          style={{ width: '10%' }}
                        >
                          Center
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          onChange={handelSetMiddlerange}
                          value={middleside}
                          aria-label="Small"
                          aria-describedby="basic-addon3"
                        />
                      </InputGroup>
                      <InputGroup className="mt-3">
                        <InputGroup.Text
                          className="justify-content-center rangeslider_style rounded-0"
                          style={{ width: '10%' }}
                        >
                          Right Side
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          onChange={handelSetrightrange}
                          value={rightside}
                          aria-label="Small"
                          aria-describedby="basic-addon3"
                        />
                      </InputGroup>
                      {validateValues() && (
                        <div style={{ color: 'red', fontSize: '14px' }} className="error mt-2">
                          {validateValues()}
                        </div>
                      )}
                      {anserInputrange && (
                        <span className="mt-1" style={{ color: 'red', fontSize: '14px' }}>
                          Please enter valid range values.
                        </span>
                      )}
                    </>
                  )}

                  {/* Date & Time */}
                  {selectedOption === 7 && (
                    <>
                      <div className="form-control ">
                        <div>
                          <Checkbox
                            size="small"
                            checked={checkdate}
                            onChange={checkDate}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          Date
                          <Checkbox
                            size="small"
                            checked={checktime}
                            onChange={checkTime}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          Time
                        </div>
                        {/* check date show date  */}
                        {checkdate && (
                          <FormControl>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={datetype}
                              onChange={handelDatetype}
                            >
                              <div className="d-flex">
                                <FormControlLabel value="MM / DD / YYYY" control={<Radio />} label="MM / DD / YYYY" />
                                <FormControlLabel value="DD / MM / YYYY" control={<Radio />} label="DD / MM / YYYY" />
                              </div>
                            </RadioGroup>
                          </FormControl>
                        )}
                      </div>
                      {datealert && (
                        <span className="mt-1" style={{ color: 'red', fontSize: '14px' }}>
                          {' '}
                          {datetyoealert}
                        </span>
                      )}
                    </>
                  )}

                  {/* FileUpload */}
                  {selectedOption === 8 && (
                    <>
                      <div>
                        {/* file uploader type check  */}
                        <FormControl className="border rounded-2 p-3">
                          <RadioGroup
                            className="d-flex flex-row"
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={filecheck}
                            onChange={checkFileuoloader}
                          >
                            <FormControlLabel value="Image" control={<Radio />} label="Image" />
                            <FormControlLabel value="Document" control={<Radio />} label="Document" />
                            {/* <FormControlLabel value="PNG" control={<Radio />} label="PNG" />
                            <FormControlLabel value="JPG, JPEG" control={<Radio />} label="JPG, JPEG" />
                            <FormControlLabel value="GIF" control={<Radio />} label="GIF" /> */}
                          </RadioGroup>
                        </FormControl>
                        <div style={{ display: 'none' }}>
                          <FormLabel className="mt-3" component="legend">
                            Validation Message
                          </FormLabel>
                          <TextField
                            onChange={handelWarningmsg}
                            value={warningmsg}
                            fullWidth
                            id="outlined-basic"
                            label="When an invalid file type is uploaded, display this error message."
                            variant="outlined"
                          />
                          {fileuploaderalert && (
                            <span className="mt-1" style={{ color: 'red', fontSize: '14px' }}>
                              {fileuploaderalert}
                            </span>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {/* image */}
                  {selectedOption === 9 && (
                    <>
                      <div>
                        {getinputdata.map((field, index) => (
                          <div key={index} className="form-control my-3 position-relative">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>{index + 1}</span>
                              {index !== 0 && (
                                <IconButton onClick={() => handleDeleteField(index)} aria-label="delete">
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </div>
                            <div>
                              <Form.Control
                                value={field.label || ''}
                                onChange={(event) => handleInputdataChange(index, event)}
                                aria-label="Small"
                                placeholder="Label"
                                aria-describedby="inputGroup-sizing-sm"
                              />
                            </div>
                            <div className="row mt-3">
                              <div className="col-12 col-md-4 col-lg-4">
                                <Button
                                  className='bgButton' variant='success'
                                  onClick={() => document.getElementById(`fileInput${index}`).click()}
                                >
                                  Add Image
                                </Button>
                                <input
                                  id={`fileInput${index}`}
                                  type="file"
                                  accept="image/*"
                                  onChange={(event) => handleFileInputChange(index, event)}
                                  style={{ display: 'none' }}
                                />
                              </div>
                              {imagePreviews[index] && (
                                <div style={{ width: '250px', display: 'inline-block', height: 'auto' }}>
                                  <div
                                    style={{
                                      height: '168px',
                                      textAlign: 'center',
                                      backgroundColor: 'white',
                                      position: 'relative',
                                    }}
                                  >
                                    <img
                                      style={{
                                        maxHeight: '100%',
                                        maxWidth: '100%',
                                        height: 'auto',
                                        width: 'auto',
                                        position: 'absolute',
                                        top: '0',
                                        bottom: '0',
                                        left: '0',
                                        right: '0',
                                        margin: 'auto',
                                      }}
                                      src={imagePreviews[index]}
                                      alt={`Preview ${index}`}
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="me-2 col-12 col-md-6 col-lg-6">
                                <FormControlLabel
                                  onChange={() => handleCheckboxdataChange(index)}
                                  checked={field.showInput || false}
                                  control={<Checkbox />}
                                  label="Include descriptive text for image"
                                />
                                {field.showInput && (
                                  <TextField
                                    value={field.additionalInput || ''}
                                    onChange={(event) => handleAdditionalInputdataChange(index, event)}
                                    size="small"
                                    placeholder="Enter descriptive text"
                                    fullWidth
                                    label="Enter descriptive text"
                                    variant="outlined"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                        <Button className='bgButton' variant='success' onClick={handleAddFielddata} size="sm">
                          <AiOutlinePlus />
                        </Button>
                        <br />
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={isCheckedmoreans}
                              onChange={() => setIsCheckedmoreans(!isCheckedmoreans)}
                            />
                          }
                          label="Allow more than one answer image to this question (use checkboxes)"
                        />
                        <br />
                        {limitalert && (
                          <Alert className="p-1" size="small" severity="error">
                            {minimum
                              ? 'Minimum 2 options are required'
                              : 'Maximum 8 and Minimum 2 answer options are allowed'}
                          </Alert>
                        )}
                        {imgageslectalert && (
                          <Alert className="p-1" size="small" severity="error">
                            Minimum 1 option is required.
                          </Alert>
                        )}
                      </div>
                    </>
                  )}

                  {/* rating */}
               
 {selectedOption === 3 && (
        <div className="mt-3 m-auto">
          <Box className="d-flex flex-wrap">
            <FormControl className="rating-select" sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Rating</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Rating"
                value={createrating}
                onChange={handelRating}
              >
                {Array.from({ length: 10 }, (_, i) => (
                  <MenuItem key={i + 1} value={i + 1}>
                    {i + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box className="ms-3 rating-select" sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Shape</InputLabel>
                <Select
                  sx={{ maxWidth: 120 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={shape}
                  label="Shape"
                  onChange={handleLiketype}
                >
                  <MenuItem value="1">
                    <AiFillLike /> &nbsp; Like
                  </MenuItem>
                  <MenuItem value="2">
                    <AiFillStar /> &nbsp; Star
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <FormControlLabel
              className="ms-lg-2 ms-md-2 ms-0 rating-select"
              control={<Checkbox className="ps-0" checked={isshowlabel} onChange={handleShowLabele} />}
              label="Add label"
            />
          </Box>

          {Array.from({ length: createrating }).map((_, index) => (
            <div key={index} className="d-flex align-items-center mt-3">
              {isshowlabel && (shape === '1' ? <AiFillLike /> : <AiFillStar />)}
              {isshowlabel && (
                <TextField
                  value={inputValues[index] || ''}
                  placeholder="Enter a rating label"
                  className="ms-2"
                  size="small"
                  variant="outlined"
                  fullWidth
                  onChange={(event) => handleInputChangerating(index, event)}
                />
              )}
            </div>
          ))}

          <span className="mt-1" style={{ color: 'red', fontSize: '14px' }}>
            {ratingalert}
          </span>
        </div>
      )}

                </Form.Group>
                <Form.Group className={getsurveymodal.textareadisplay} as={Col} md="12" controlId="validationCustom01">
                  <Form.Label>{getsurveymodal.inputlabel3}</Form.Label>
                  <textarea type="text" rows="7" className="form-control" />
                </Form.Group>
              </Row>
              <div className="d-flex justify-content-end ">
                {' '}
                <Button color="success" variant="contained" className="px-5 bgButton" type="submit">
                  Save
                </Button>
              </div>
            </Form>
          </>
        ))}
      </Modal>
    </>
  );
}
export default memo(CreateSurveyPage);
