import React, { useState, useEffect, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import Button from '@mui/material/Button';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

const ShareLinks = () => {
  const [surveyId, setSurveyId] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [dimension, setDimension] = useState({ width: '1920', height: '1080' });
  const location = useLocation();
  const [editableValue, setEditableValue] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const segments = location.pathname.split('/');
    const getidfromurl = segments[segments.length - 1];
    setSurveyId(getidfromurl);
  
    // Determine the base URL based on the environment
    const baseUrl = window.location.hostname === 'localhost'
      ? `http://localhost:${window.location.port}/surveyform`
      : `https://app.surveyslack.com/surveyform`;
  
    setEditableValue(`${baseUrl}/${getidfromurl}`);
  }, [location.pathname]);
  
  const handleCopy = () => {
    const input = document.createElement('input');
    input.value =
      window.location.hostname === 'localhost'
        ? `http://localhost:${window.location.port}/surveyform/${surveyId}`
        : `https://app.surveyslack.com/surveyform/${surveyId}`;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(input);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  const generateIframeCode = () => {
    return `<!-- Begin SurveySlack survey embed -->
    <iframe width="${dimension.width}" height="${dimension.height}" src="${
      window.location.hostname === 'localhost'
        ? `http://localhost:${window.location.port}/surveyform/${surveyId}`
        : `https://app.surveyslack.com/surveyform/${surveyId}`
    }"></iframe>
    <!-- End SurveySlack survey embed -->`;
  };
  const updateIframeCode = () => {
    const newIframeCode = generateIframeCode();
    const textarea = document.getElementById('iframe-code-textarea');
    textarea.value = newIframeCode;
  };

  const handleInputChange = (type, event) => {
    const inputValue = event.target.value.trim() === '0' ? '' : event.target.value;
    setDimension((prevDimension) => ({
      ...prevDimension,
      [type]: inputValue,
    }));
  };

  const handleButtonClick = (type, action) => {
    setDimension((prevDimension) => {
      let newValue = parseInt(prevDimension[type], 10);

      if (action === 'increase') {
        newValue = Number.isNaN(newValue) ? 1 : newValue + 1;
      } else if (action === 'decrease') {
        newValue = newValue < 1 ? 1 : newValue - 1;
      }

      return {
        ...prevDimension,
        [type]: newValue,
      };
    });
  };

  const handleEditClick = () => {
    setIsCopied(false);
    setIsEditing(true);
    inputRef.current.focus();
  };

  const handleInputBlur = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    updateIframeCode();
  }, [dimension, surveyId]);

  return (
    <div>
      {/* Direct Link Accordion */}
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="custom-accordion-summary"
        >
          Direct Link
        </AccordionSummary>
        <AccordionDetails>
          <span className="mt-4 text-muted">Copy this link to your clipboard or share it on a social network</span>
          <div className="input-container mt-4">
            <input
              type="text"
              id="input-direct-link"
              name="url"
              className={`big editable-input ${isCopied ? 'read-only' : ''}`}
              tabIndex="-1"
              value={
                isEditing // Use the editable value only when editing
                  ? editableValue
                  : window.location.hostname === 'localhost'
                  ? `http://localhost:${window.location.port}/surveyform/${surveyId}`
                  : `https://app.surveyslack.com/surveyform/${surveyId}`
              }
              onChange={(e) => {
                if (isEditing) {
                  setEditableValue(e.target.value); // Update the editable value when editing
                }
              }}
              ref={inputRef}
              onBlur={handleInputBlur}
            />

            <Link to="#" id="share-url-edit-button" className="edit-icon d-none" onClick={handleEditClick}>
              <EditIcon />
            </Link>
            <Button className="bgButton" style={{height:'40px',width:'90px'}} id="survey-url-button" onClick={handleCopy}>
              {isCopied ? 'Copied!' : 'Copy'}
            </Button>
          </div>
          <div className="mt-3">
            <Link
              to={
                window.location.hostname === 'localhost'
                  ? `http://localhost:${window.location.port}/surveyform/${surveyId}`
                  : `https://app.surveyslack.com/surveyform/${surveyId}`
              }
              id="share-url-open-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open in new window
            </Link>
          </div>
        </AccordionDetails>
      </Accordion>

      {/* Embed Link Accordion */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          className="custom-accordion-summary"
        >
          Embed
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <span className="text-muted mt-4">Embed your survey on your own website</span>
            <div className="row mt-4">
              <div className="embedcontent col-12 col-md-6 col-lg-6">
                <div className="input-with-button">
                  Width
                  <form>
                    <div
                      role="button"
                      tabIndex="0"
                      className="value-button"
                      onClick={() => handleButtonClick('width', 'decrease')}
                      onKeyDown={(e) => e.key === 'Enter' && handleButtonClick('width', 'decrease')}
                      aria-label="Decrease Width"
                    >
                      -
                    </div>
                    <input
                      type="number"
                      id="width"
                      value={dimension.width}
                      onChange={(e) => handleInputChange('width', e)}
                    />
                    <div
                      role="button"
                      tabIndex="0"
                      className="value-button"
                      onClick={() => handleButtonClick('width', 'increase')}
                      onKeyDown={(e) => e.key === 'Enter' && handleButtonClick('width', 'increase')}
                      aria-label="Increase Width"
                    >
                      +
                    </div>
                  </form>
                </div>
              </div>
              <div className="embedcontent col-12 col-md-6 col-lg-6">
                <div className="input-with-button">
                  Height
                  <form>
                    <div
                      role="button"
                      tabIndex="0"
                      className="value-button"
                      onClick={() => handleButtonClick('height', 'decrease')}
                      onKeyDown={(e) => e.key === 'Enter' && handleButtonClick('height', 'decrease')}
                      aria-label="Decrease Height"
                    >
                      -
                    </div>
                    <input
                      type="number"
                      id="height"
                      value={dimension.height}
                      onChange={(e) => handleInputChange('height', e)}
                    />
                    <div
                      role="button"
                      tabIndex="0"
                      className="value-button"
                      onClick={() => handleButtonClick('height', 'increase')}
                      onKeyDown={(e) => e.key === 'Enter' && handleButtonClick('height', 'increase')}
                      aria-label="Increase Height"
                    >
                      +
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="embediFrame">
              iFrame Embed Code
              <textarea id="iframe-code-textarea" className="embedTextarea" name="iframe-code" readOnly="">
                {generateIframeCode()}
              </textarea>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ShareLinks;
