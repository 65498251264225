import {useState, useEffect } from 'react';
import { BrowserRouter  } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';

import { createBrowserHistory } from 'history'
import Box from '@mui/material/Box';
import * as Sentry from "@sentry/react";
import { getAuth, signOut } from "firebase/auth";
import Router from './routes';
import ThemeProvider from './theme';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import store from './Redux/store';
import './Style/Global.css';


export default function App() {
  Sentry.init({
    dsn: "https://e1453c7c945c0e3e5c0e955e25aaef3d@o4507089027989504.ingest.us.sentry.io/4507089039261696",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  






  // useEffect(()=>{
  //   const uid = localStorage.getItem("uid");
  //   if (!uid) {
  //   console.log("not found")
  //   }
  // })




  
  function SurveySuccessAlert({ alerttype }) {
    const [state, setState] = useState({
      open: true,
      vertical: 'top',
      horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;
    const { type } = alerttype[0];
    return (
      <Box sx={{ width: 500 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          message={type}
          key={vertical + horizontal}
        />
      </Box>
    );
  }
  
  const successdeleteAlert = [
    {
      type: 'Device is offline Need to connect  Internet.',
    },
  ];

  const [isOnline, setIsOnline] = useState(false);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(true);
      setTimeout(() => {
        setIsOnline(false);
      }, 3000);
    };
    window.addEventListener('offline', handleOnlineStatusChange);
  }, []);


  const history = createBrowserHistory();
  return (
    <Provider store={store}>
    <HelmetProvider>
   {isOnline && <SurveySuccessAlert alerttype={successdeleteAlert} />}
      <BrowserRouter  >
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router history={history  } />
        </ThemeProvider>
      </BrowserRouter >
    </HelmetProvider>
    </Provider>
  );
}
