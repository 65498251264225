import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { getAuth, updateEmail } from 'firebase/auth';
import validator from 'validator'
import { iscloseEmailModal } from '../../Redux/actions';
import Alerts from '../Alerts';

const EmailUpdatePopup = () => {
    const [checkerror, setCheckType] = useState('')

    const alert = [
        {
            bgcolor: 'warning',
            alerttype: "Do You want to Update Your email id .",
            errortype: checkerror,
            onClick: handelProcced,
            onClosealert: handelCloseWarring,
        }
    ];

    const [emailid, setEmailid] = useState('')
    const navigate = useNavigate();
    function handelProcced() {
        const auth = getAuth();
        const newEmail = document.getElementById('new-email').value;
        updateEmail(auth.currentUser, newEmail)
            .then(() => {
                console.log('Email updated successfully');
                setWarningAlert(false)
                dispatch(iscloseEmailModal());
                setModalVisible(true)
                setEmailid('')
                // navigate("/");
                // window.location.reload(false);
                // localStorage.clear();
                // sessionStorage.clear();
                sessionStorage.removeItem('email');
                localStorage.removeItem('email');
                // Store the new email in both sessionStorage and localStorage
                sessionStorage.setItem('email', newEmail);
                localStorage.setItem('email', newEmail);
                // Redirect the user to "/dashboard/profile"
                navigate("/dashboard/profile");
            })
            .catch((error) => {
                console.error(error);
                if (error.code === 'auth/email-already-in-use') {
                    setCheckType('Email is already in use. Please choose another email address.');
                  } else if(error.code === 'auth/requires-recent-login'){
                    setCheckType('requires-recent-login');
                  }else{
                    setCheckType('try after some time'); 
                  }
            });
    }

    function handelCloseWarring() {
        setWarningAlert(false)
        setModalVisible(true)
        setCheckType('');
    }

    const [modalVisible, setModalVisible] = useState(true);
    const getemail = sessionStorage.getItem('email')
    // const [email, setEmail] = useState('');
    const [warningAlert, setWarningAlert] = useState(false)
    const [emailalert, setEmailalert] = useState(false)
    const dispatch = useDispatch();
    const isEditEmailModal = useSelector((state) => state.isEditEmailModal);

    const handleClose = () => {
        dispatch(iscloseEmailModal());
        setWarningAlert(false)
        setModalVisible(true)
        setEmailid('')
        setEmailalert(false)

    };

    const handelEnterEmail = (e) => {
        const getmailid = e.target.value
        setEmailid(getmailid)
        const checkemail = validator.isEmail(getmailid)
        if (!checkemail) {
            setEmailalert(true)
        } else {
            setEmailalert(false)
        }
    }

    const handelProceedChange = () => {
        if (emailalert || emailid === "") {
            setEmailalert(true)
            return
        }
        // setModalVisible(false)

        const newEmail = document.getElementById('new-email').value;
        if (newEmail === '') {
            setEmailalert(true)
        } else {
            setWarningAlert(true)
        }
    };


    return (
        <>
            {warningAlert && <Alerts alert={alert} />}
            {modalVisible && <Modal style={{ visibility: warningAlert ? "hidden" : "visible" }} show={isEditEmailModal} onHide={handleClose}>
                <div>
                    <Modal.Header style={{ backgroundColor: '#D0F2FF' }} closeButton>
                        <Modal.Title>Update Email</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label className='d-none'>Enter Old Email</Form.Label>
                                <Grid className='mb-3 d-none' container spacing={2}>
                                    <Grid item xs={12}>
                                        <Form.Control type="email" placeholder="Enter email" value={getemail} />
                                    </Grid>
                                    {/* <Grid item xs={4}>
                                        <Button className="checkbtn" type="button" onClick={handelProceedChange}>
                                            // Update Email
                                        </Button>
                                    </Grid> */}
                                </Grid>
                                <Form.Label>Enter New Email</Form.Label>
                                <Grid className='mb-3' container spacing={2}>
                                    <Grid item xs={12}>
                                        <Form.Control value={emailid} onChange={handelEnterEmail} type="email" placeholder="Enter new email" id="new-email" />
                                    </Grid>
                                    {emailalert && <span style={{ color: '#c71a1ad1' }} className='px-3 pt-1'>Please enter valid email id.</span>}
                                    {/* <Grid item xs={4}>
                                        <Button className="checkbtn" type="button" onClick={handelProceedChange}>
                                            Update Email
                                        </Button>
                                    </Grid> */}
                                </Grid>

                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="checkbtn " type="button" onClick={handelProceedChange} >
                            Proceed
                        </button>
                    </Modal.Footer>
                </div>
            </Modal>}
        </>
    );
}

export default EmailUpdatePopup
