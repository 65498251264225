import { React, useState,useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithRedirect,
  signInWithPopup,
  FacebookAuthProvider,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
// import { createUserWithEmailAndPassword } from "firebase/auth";
import validator from 'validator';
import LoginForm from './AuthenticationComponent';
import { auth, provider } from '../../sections/auth/login/firebase';
import { showDiv, iscloseError } from '../../Redux/actions';
import '../../Style/LoginForm.css';

function Signup() {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const [isdisable, setDisable] = useState(false);
  const navigate = useNavigate();
  const [isanimate, setAnimation] = useState('');
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [Confermpassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [ispasswordError ,setPasswordError] = useState('')
  const [confirmPasswordstate, setConfirmPasswordstate] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  function checkformdata() {
    if (confirmPasswordstate === false) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }

  useEffect(() => {
    setDisable(true);
    

  }, []);

  useEffect(() => {
    checkFormValidity();
  }, [email, password, Confermpassword]);

  const checkFormValidity = () => {
    // const isEmailValid = validator.isEmail(email); 
    const isEmailValid = validator.isEmail(email) || email.trim() === "";

    const isPasswordValid = validator.isStrongPassword(password, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    }) || password.trim() === "";
    const isConfirmPasswordValid = password === Confermpassword || Confermpassword.trim() === "";

    setEmailError(!isEmailValid);
    setPasswordError(
      isPasswordValid
        ? ''
        : 'Must contain Minimum 8 characters, one -  number, symbol , uppercase and lowercase letter.'
    );
    setConfirmPasswordError(!isConfirmPasswordValid);

    setDisable(!(isEmailValid && isPasswordValid && isConfirmPasswordValid));
  };

  
  useEffect(()=>{
    const storedEmail =  localStorage.getItem('saveEmailid')
    const storedPswd = localStorage.getItem('savePsw')
    setEmail(storedEmail);
    setPassword(storedPswd);
  },[])
  
  const onSignUp = async (e) => {
    
    e.preventDefault();
    const useremail = email;
    // ValidationFunction(email)
    ValidationFunction(email, password, Confermpassword);

    // const createusername = localStorage.getItem('username');
    localStorage.setItem("signupWithEmail", "email");
    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        localStorage.setItem('provider', user.providerId);
        localStorage.setItem("getuserid",user.uid);
        localStorage.setItem("uid",user.uid);
        localStorage.setItem('email',user.email);
        navigate('/dashboard/profile');
        try {
          const res = fetch('https://app.surveyslack.com/api/surveytask/api/v1/user.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              userid: user.uid,
              username: '',
              created_date: userCredential.user.metadata.creationTime,
              modified_date: userCredential.user.metadata.lastSignInTime,
              platform: 'Web',
              created_for: 'Personal',
              isFree: 'free',
              subscription_enddate: '',
              teams: '',
              last_login: userCredential.user.metadata.lastSignInTime,
              email: useremail,
              active: 'true',
              loginwith: 'email',
            }),
          });
          if (res.status === 200) {
            console.log('user Data post  successfully');
          }
        } catch (err) {
          console.log(err);
        }

        // user profile
        try {
          // const createusername = localStorage.getItem('username');
          const res = fetch('https://app.surveyslack.com/api/surveytask/api/v1/userprofile.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              userid: user.uid,
              username: '',
              first_name: '',
              last_name: '',
              profile_image: '',
              created_date: userCredential.user.metadata.creationTime,
              modified_date: userCredential.user.metadata.lastSignInTime,
              phone_number: '',
              bio: '',
            }),
          });
          // const resJson = await res.json();
          if (res.status === 200) {
            console.log(' user profile data post successfully');
          }
        } catch (err) {
          console.log(err);
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        if (errorCode === 'auth/email-already-in-use') {
          alert('An account with this email address already exists. Please use a different email.');
        } else {
          dispatch(showDiv());
        }
      });
  };
  const GoogleSignInComponent = async () => {
    // Check if email and password are filled
    if (email && password) {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        localStorage.setItem('uid', user.uid);
        localStorage.setItem('getuserid', user.uid);
        localStorage.setItem('email', user.email);
        localStorage.setItem('username', user.displayName);
        navigate('/dashboard/survey', { replace: true });
  
        // Handle profile and user data posting
        try {
          const res = await fetch('https://app.surveyslack.com/api/surveytask/api/v1/user.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              userid: user.uid,
              username: user.displayName || '',
              created_date: user.metadata.creationTime,
              modified_date: user.metadata.lastSignInTime,
              platform: 'Web',
              created_for: 'Personal',
              isFree: 'free',
              subscription_enddate: '',
              teams: '',
              last_login: user.metadata.lastSignInTime,
              email: user.email,
              active: 'true',
              loginwith: 'email',
            }),
          });
          if (res.status === 200) {
            console.log('User data posted successfully');
          }
        } catch (err) {
          console.log(err);
        }
  
        try {
          const res = await fetch('https://app.surveyslack.com/api/surveytask/api/v1/userprofile.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              userid: user.uid,
              username: user.displayName || '',
              first_name: '',
              last_name: '',
              profile_image: '',
              created_date: user.metadata.creationTime,
              modified_date: user.metadata.lastSignInTime,
              phone_number: '',
              bio: '',
            }),
          });
          if (res.status === 200) {
            console.log('User profile data posted successfully');
          }
        } catch (err) {
          console.log(err);
        }
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        dispatch(showDiv()); // Show error if sign-in fails
      }
    } else {
      // Proceed with Google sign-in if email and password are not filled
      signInWithPopup(auth, provider)
        .then((data) => {
          navigate('/dashboard/survey', { replace: true });
          localStorage.setItem('uid', data.user.uid);
          localStorage.setItem('getuserid', data.user.uid);
          localStorage.setItem('email', data.user.email);
          localStorage.setItem('username', data.user.displayName);
  
          // Handle profile and user data posting
          try {
            const res = fetch('https://app.surveyslack.com/api/surveytask/api/v1/user.php', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                userid: data.user.uid,
                username: data.user.displayName,
                created_date: data.user.metadata.creationTime,
                modified_date: data.user.metadata.lastSignInTime,
                platform: 'Web',
                created_for: 'Personal',
                isFree: 'free',
                subscription_enddate: '',
                teams: '',
                last_login: data.user.metadata.lastSignInTime,
                email: data.user.email,
                active: 'true',
                loginwith: data.user.providerData[0].providerId,
              }),
            });
            if (res.status === 200) {
              console.log('User data posted successfully');
            }
          } catch (err) {
            console.log(err);
          }
  
          try {
            const res = fetch('https://app.surveyslack.com/api/surveytask/api/v1/userprofile.php', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                userid: data.user.uid,
                username: data.user.displayName,
                first_name: '',
                last_name: '',
                profile_image: '',
                created_date: data.user.metadata.creationTime,
                modified_date: data.user.metadata.lastSignInTime,
                phone_number: '',
                bio: '',
              }),
            });
            if (res.status === 200) {
              console.log('User profile data posted successfully');
            }
          } catch (err) {
            console.log(err);
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          console.log(errorCode);
          const errorMessage = error.message;
          console.log(errorMessage);
          const { customData: { email } } = error;
          const credential = FacebookAuthProvider.credentialFromError(error);
          console.log(credential);
        });
    }
  };
  

  // const GoogleSignInComponent = () => {
  // signInWithPopup(auth, provider, email).then((data) => {
  //   navigate('/dashboard/survey', { replace: true });
  //   // localStorage.setItem('Auth Token', data._tokenResponse.refreshToken);
  //   // sessionStorage.setItem('Auth Token', data._tokenResponse.refreshToken);
  //   localStorage.setItem('uid', data.user.uid);
  //   localStorage.setItem('getuserid', data.user.uid);
  //   localStorage.setItem('email', data.user.email);
  //   localStorage.setItem('username', data.user.displayName);
  //   // const sessionId = 'unique-session-id';
  //   // Cookies.set('sessionId', sessionId, { expires: 0.5 });
  //   // localStorage.setItem('sessionInfo', JSON.stringify(data._tokenResponse.refreshToken));
  //   try {
  //     // const getuserid = localStorage.getItem('uid');
  //     const getuserid = localStorage.getItem('getuserid');
  //     const getemail = localStorage.getItem('email');
  //     const createusername = localStorage.getItem('username');

  //     const res = fetch('https://app.surveyslack.com/api/surveytask/api/v1/user.php', {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({
  //         userid: getuserid,
  //         username: createusername,
  //         created_date: data.user.metadata.creationTime,
  //         modified_date: data.user.metadata.lastSignInTime,
  //         platform: 'Web',
  //         created_for: 'Personal',
  //         isFree: 'free',
  //         subscription_enddate: '',
  //         teams: '',
  //         last_login: data.user.metadata.lastSignInTime,
  //         email: getemail,
  //         active: 'true',
  //         loginwith: data.user.providerData[0].providerId,
  //       }),
  //     });
  //     if (res.status === 200) {
  //       console.log('user Data post  successfully');
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }

  //   try {
  //     const createusername = localStorage.getItem('username');
  //     const res = fetch('https://app.surveyslack.com/api/surveytask/api/v1/userprofile.php', {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({
  //         userid: data.user.uid,
  //         username: createusername,
  //         first_name: data._tokenResponse.firstName,
  //         last_name: data._tokenResponse.lastName,
  //         profile_image: data.user.photoURL,
  //         created_date: data.user.metadata.creationTime,
  //         modified_date: data.user.metadata.lastSignInTime,
  //         phone_number: data.user.phoneNumber,
  //         bio: '',
  //       }),
  //     });
  //     // const resJson = await res.json();
  //     if (res.status === 200) {
  //       console.log(' user profile data post successfully');
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // });
 
  
  // };

  const handelfacebooklogin = () => {
    const provider = new FacebookAuthProvider();
    const auth = getAuth();
    signInWithRedirect(auth, provider)
      .then((result) => {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = provider.credentialFromResult(result);
        const { accessToken } = credential;
        const user = result.user;
        localStorage.setItem('Auth Token', user._tokenResponse.refreshToken);
        localStorage.setItem("user id:", user.uid);
        localStorage.setItem('email', user.email);
        sessionStorage.setItem('Access token', accessToken);
        navigate('/dashboard/app', { replace: true });
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
        const errorMessage = error.message;
        console.log(errorMessage);
        const { customData: { email } } = error;
        const credential = FacebookAuthProvider.credentialFromError(error);
        console.log(credential)
      });

  
  }

  const handleAppleLogin = () => {
    console.log("does not authenticate.")
   
  };

 

  function onChangeEmail(event) {
    setEmail(event.target.value);
    // const email = event.target.value;
    // checkformdata();
    // const setpassword = password===null?"":password;
    // const ischeckpsw = Confermpassword;
    
  }

  function onChangePassword(e) {
    const setpassword = e.target.value;
    setPassword(setpassword);
    // ValidationFunction(email,setpassword,Confermpassword) 
    checkformdata()
  }

  function onCheckPassword(e) {
    const Confermpassword = e.target.value;
    setConfirmPassword(Confermpassword);
    const setpassword = password;
    ValidationFunction(email,Confermpassword,setpassword) 
    checkformdata()
  }

  

  function ValidationFunction (email,setpassword,Confermpassword) {
    if (validator.isEmail(email) ) {
      setEmailError(false);
    } else {
      setEmailError(true);
    } 
    

    if (validator.isStrongPassword(setpassword , { 
      minLength: 8, minLowercase: 1, 
      minUppercase: 1, minNumbers: 1, minSymbols: 1 
  })) { 
    setPasswordError("") 
  } else { 
    setPasswordError('Must contain Minimum 8 characters, one -  number, symbol , uppercase and lowercase letter.') 
  }  


  if (setpassword !== Confermpassword) {
    dispatch(showDiv());
    setConfirmPasswordstate(true) 
  } else if (setpassword === Confermpassword) {
    setConfirmPasswordstate(false) ;

    // if (!checked) {
    //   setCheckPsw(true);
    // }
    if ( Confermpassword ===""){
      setConfirmPasswordstate(false) 
    }
  }
  }
 
    const checkTerms = (event) => {
      // checkformdata()
      setChecked(event.target.checked);
    };
  
  const loginformdata = [
    {
      commonbtntext: 'Join us for FREE and create unlimited surveys, questions & answers! Already have an account?',
      commonbtn: 'Back to Login',
      titletext: 'Create Account',
      toastAlert: ' Please Check your Email And Password !',
      loginbtn: 'Signup',
      maintitle: '',
      forgotbtn: 'Forgot password?',
      navLinkcommonbtn: { to: '/' },
      onClick: onSignUp,
      handleChangeEmail: onChangeEmail,
      handleChangePassword: onChangePassword,
      handleCheckPassword: onCheckPassword,
      faceBook: handelfacebooklogin,
      apple: handleAppleLogin,
      googleLoginin: GoogleSignInComponent,
      // confermerror: 'Please check your password',

      ConfermPasswordError: confirmPasswordstate,
      ConfermEmail: 'Please enter valid Email.',
      passwordError: ispasswordError,
      ConfermPasswordValue: Confermpassword,
      emailvalidator: emailError,
      disable: isdisable,
      checkdata:checked,
      shake: isanimate,
      emailid: email,
      Password: password,
      handleChange:checkTerms,
    },
  ];

  return (
    <div className="auth-container">
      <div className="auth">
      <LoginForm loginformdata={loginformdata}  />
    </div>
    </div>
  );
}
export default Signup;
