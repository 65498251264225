import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AiOutlineRollback, AiFillSetting, AiFillQuestionCircle,faEnvelope, } from 'react-icons/ai';

import { MdOutlineIncompleteCircle, MdPreview } from 'react-icons/md';
import { FaAffiliatetheme, FaShareAltSquare } from 'react-icons/fa';
import { styled, alpha } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset,faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
import account from '../../../_mock/account';
import useResponsive from '../../../hooks/useResponsive';
import Logo from '../../../components/logo';
import logoWithText from '../../../img/SurveySlack-Full-Logo-With-Text.png'
import Scrollbar from '../../../components/scrollbar';
import EmailModal from '../../../components/Popup-folder/EmailModal';
import NavSection from '../../../components/nav-section/NavSection';
import { BASE_URL, API_ENDPOINTS } from '../../../api_folder/config';

//
// import navConfig from './config';
// import navConfigTwo from './configCreatesurvey';
// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
  surveydata: PropTypes.object.isRequired,
};

const provider = localStorage.getItem('provider');


export default function Nav({ surveydata, openNav, onCloseNav }) {
  const [username, setUserName] = useState('');
  const [surveyTitle, setSurveyTitle] = useState('');
  const [surveyLogo, setSurveyLogo] = useState('');
  const [isEmailModalOpen, setEmailModalOpen] = useState(false);

  console.log('username', username);
  console.log('surveydata', surveydata); // New log stat
  const getemailid = localStorage.getItem('email');
  useEffect(() => {
    if (getemailid == null) {
      console.log('user not login');
    } else {
      const createusername = getemailid.split('@');
      setUserName(createusername[0]);
      //   console.log("user is  login ")
    }
  }, []);
  const setid = useSelector((state) => state.usersurveylogoData)
  console.log("logo" ,setid);
  const getsurveyid = localStorage.getItem('SURVEY_ID');

  const fetchOrder = async () => {
    try {
      const response = await fetch(
        `https://app.surveyslack.com/api/surveytask/api/v1/surveylist.php?surveyid=${getsurveyid}`
      );
      if (response.status === 200) {
        const data = await response.json();
        setSurveyTitle(data.surveyid.survey_title);
        setSurveyLogo(data.surveyid.logo);
        console.log('Survey data fetched successfully:', data);
      } else {
        console.error('Error fetching survey list. Status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching survey list:', error);
    }
  };

  const handleEmailButtonClick = () => {
    setEmailModalOpen(true);
  };

  const handleCloseEmailModal = () => {
    setEmailModalOpen(false);
  };

  useEffect(() => {
    // Call the fetchOrder function
    fetchOrder();
  }, [getsurveyid]);

  const navConfigTwo = [
    {
      title: 'Settings',
      path: `/surveycreatelayout/setting/${getsurveyid}`,
      // path: `/surveycreatelayout/setting?id=${getsurveyid}`,
      // path: '/surveycreatelayout/setting',
      icon: <AiFillSetting style={{ fontSize: '20px' }} />,
    },
    {
      title: 'Questions',
      path: `/surveycreatelayout/questions/${getsurveyid}`,
      // path:`/surveycreatelayout/questions?id=${getsurveyid}`,
      // path: '/Surveys/questions',
      icon: <AiFillQuestionCircle style={{ fontSize: '20px' }} />,
    },
    {
      title: 'Customizations',
      // path:`/surveycreatelayout/thems?id=${getsurveyid}`,
      path: `/surveycreatelayout/thems/${getsurveyid}`,
      // path: '/Surveys/thems',
      icon: <FaAffiliatetheme style={{ fontSize: '20px' }} />,
    },
    {
      title: 'Preview',
      // path: '/surveycreatelayout/preview',
      path: `/surveycreatelayout/preview/${getsurveyid}`,
      // path:`/surveycreatelayout/preview?id=${getsurveyid}`,
      icon: <MdPreview style={{ fontSize: '20px' }} />,
    },
    {
      title: 'Share',
      // path: '/surveycreatelayout/share',
      path: `/surveycreatelayout/share/${getsurveyid}`,
      // path:`/surveycreatelayout/share?id=${getsurveyid}`,
      icon: <FaShareAltSquare style={{ fontSize: '20px' }} />,
    },
    {
      title: 'Result',
      // path: '/surveycreatelayout/result',
      path: `/surveycreatelayout/result/${getsurveyid}`,
      // path:`/surveycreatelayout/result?id=${getsurveyid}`,
      icon: <MdOutlineIncompleteCircle style={{ fontSize: '20px' }} />,
    },
    {
      title: 'Question Branching',
      path: `/surveycreatelayout/QuestionBranching/${getsurveyid}`,
      icon: <FontAwesomeIcon icon={faCodeBranch} style={{ fontSize: '20px' }} />,
    },
    {
      title: 'Exit',
      path: '/dashboard/survey',
      icon: <AiOutlineRollback style={{ fontSize: '20px' }} />,
    },
  ];

  const { pathname } = useLocation();
  const isSurveyDashboard = pathname.startsWith('/dashboard/survey');
  const isAppDashboard = pathname.startsWith('/dashboard/app');
  const isCreateLayout = pathname.startsWith('/surveycreatelayout/');
  const shouldDisplaySurveyTitle = isCreateLayout ;
  const shouldDisplayStyledAccount = isSurveyDashboard || isAppDashboard;

  const isDesktop = useResponsive('up', 'lg');
  // const showNavSection = window.location.hash.includes('surveycreatelayout');

  const [userName, setUsername] = useState('');
  const [userImg, setImg] = useState('');
const isblank = useSelector(state => state.usersurveyData);
const isblanklogo=  useSelector(state => state.usersurveylogoData);
  useEffect(() => {
    const userid = localStorage.getItem('uid');
    const endpoint = API_ENDPOINTS.userDataprofileGET;

    fetch(`${BASE_URL}${endpoint}`)
      .then((response) => response.json())
      .then((data) => {
        const user = data.data.find((profiledata) => profiledata.userid === userid);
        if (user) {
          setUsername(user.username);
          setImg(user.profile_image);
          localStorage.setItem('setprofileimg', user.profile_image);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
        <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' ,margin:'5px'}}>
        {/* <Logo /> */}
        <img src={logoWithText} alt="altlogo" />
      </Box>
      {shouldDisplaySurveyTitle && (
  <Box sx={{ mx: 2.5 }}>
    <Link underline="none">
      <StyledAccount>
        {/* sidebar general */}
        {surveyLogo ? (
          <Avatar
            src={setid===""?surveyLogo:setid}
            alt="photoURL"
          />
        ) : null}
           {/* <img
          src={setid}
          alt="Img"
          style={{ maxWidth: '100%', maxHeight: '100%', display: 'block', margin: 'auto' }}
        /> */}
        <Box sx={{ ml: surveyLogo ? 2 : 0 }}>
          <Typography style={{ textTransform: 'capitalize' }} variant="subtitle2" sx={{ color: 'text.primary' }}>
            {isblank}
            {isblank ===""&&<Typography variant="subtitle2" sx={{ color: 'text.primary', textTransform: 'capitalize' }}>
                {(isblank === "") ? surveyTitle : surveyTitle}
              </Typography>}
          </Typography>
        </Box>
      </StyledAccount>
    </Link>
  </Box>
)}



      {shouldDisplayStyledAccount && (
        <Box sx={{ mx: 2.5 }}>
          <Link underline="none">
            <StyledAccount>
              {/* sidebar general */}
              <Avatar
                src={
                  userImg === ''
                    ? 'https://static.vecteezy.com/system/resources/previews/002/275/847/original/male-avatar-profile-icon-of-smiling-caucasian-man-vector.jpg'
                    : userImg
                }
                alt="photoURL"
              />
              <Box sx={{ ml: 2 }}>
                <Typography style={{ textTransform: 'capitalize' }} variant="subtitle2" sx={{ color: 'text.primary' }}>
                  {userName === 'username' ? username : userName}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {account.role}
                </Typography>
              </Box>
            </StyledAccount>
          </Link>
        </Box>
      )}

      {/* <NavSection data={navConfig} /> */}
      {<NavSection  data={navConfigTwo} />}

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
      <Button
          style={{ width: '100%' }}
          component="label"
          className='bgButton'
          startIcon={<FontAwesomeIcon icon={faHeadset} />}
          onClick={handleEmailButtonClick}  // Open modal on click
        >
          Need Support?
        </Button>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
       <EmailModal open={isEmailModalOpen} handleClose={handleCloseEmailModal} />

    </Box>
  );
}
