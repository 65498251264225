import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
      apiKey: "AIzaSyAHbh4U1d13Tz2gzqUs_kz3FhQzdDpk2T8",
      authDomain: "survey-slack-57b32.firebaseapp.com",
      projectId: "survey-slack-57b32",
      storageBucket: "survey-slack-57b32.appspot.com",
      messagingSenderId: "799722049686",
      appId: "1:799722049686:web:285bc1236471386460f2b7",
      measurementId: "G-KSNEHN86TF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage();
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
export {storage,auth,provider};