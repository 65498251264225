import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect, useRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Container, Stack, Slide, Typography, Input, Button, InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams,useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import '../Style/Surveylistingpage.css';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { IoMdCloseCircle } from 'react-icons/io';
import MenuItem from '@mui/material/MenuItem';
import {showBackdropLoader, closeBackdroploader, opencreateSurvey, showAISurvey,setBackground, closefilter,showAISurveyTitle } from '../Redux/actions';
import CreateSurvey from '../components/Popup-folder/createSurvey';
import Iconify from '../components/iconify';
import DeleteModal from './DeleteModal';
import { BASE_URL, API_ENDPOINTS } from '../api_folder/config'; 
// import { ProductSort, ProductFilterSidebar, } from '../sections/@dashboard/products';
import SurveyCard from '../sections/@dashboard/products/SurvayCard';
import AISurveyModal from '../components/Popup-folder/AISurveyModal';
import AISurveyinfoModal from './AISurveyinfoModal';

function SurveySuccessAlert({ alerttype }) {

  const [state, setState] = useState({
    open: true,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;
  const { type } = alerttype[0];
  return (
    <Box sx={{ width: 500 }}>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} message={type} key={vertical + horizontal} />
    </Box>
  );
}

const successdeleteAlert = [
  {
    type: 'Survey Deleted Successfully',
  },
];
const surveyCreateAlert = [
  {
    type: 'Survey Created Successfully',
  },
];
const dataFetch = [
  {
    type: 'Loading...',
  },
];

export default function ProductsPage() {
  const showfilter = useSelector((state) => state.togglesurveyfilter);
  const { id } = useParams();
  const navigate = useNavigate(); 
  const [openFilter, setOpenFilter] = useState(false);
  const [survey, setSurveyList] = useState([]);
  const [surveycount, setSurveycount] = useState(0);
  const [surveydelete, setSurveydelete] = useState(0);
  const [loader, setLoader] = useState(true);
  const [alert, setAlert] = useState(false);
  const [deletealert, setDeletealert] = useState(false);
  const [fetchDataalert, setfetchDataalert] = useState(true);
  const [filtertext, setFilterText] = useState('');
  const [isnotsurvey, setNotsurvey] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [survayIdToDelete, setSurvayIdToDelete] = useState(null);
  const [show, setShow] = useState(false);
  const isMounted = useRef(false);
  const [showAISurveyinfoModal, setShowAISurveyinfoModal] = useState(false);

  const openAISurveyinfoModal = () => {
    setShowAISurveyinfoModal(true);
  };

  const closeAISurveyinfoModal = () => {
    setShowAISurveyinfoModal(false);
  };



  const handelClosefilter = () => {
    dispatch(closefilter());
    clearfilter();

  };

  const openDeleteModal = (survayid) => {
    setSurvayIdToDelete(survayid);
    setShowDeleteModal(true);
  };
  console.log('showDeleteModal:', showDeleteModal);


  const handleDelete = async () => {
    setShowDeleteModal(false); // Close the modal immediately

    try {
        // Delete survey from surveylist table
        const responseSurveyList = await fetch(
            `https://app.surveyslack.com/api/surveytask/api/v1/surveylist.php?surveyid=${survayIdToDelete}`,
            {
                method: 'DELETE',
            }
        );

        // Delete survey from surveyquestionary table
        const responseSurveyQuestionary = await fetch(
            `https://app.surveyslack.com/api/surveytask/api/v1/surveyquestionary.php?surveyid=${survayIdToDelete}`,
            {
                method: 'DELETE',
            }
        );

         // Delete survey from surveyresponse table
         const responseSurveyResponse = await fetch(
          `https://app.surveyslack.com/api/surveytask/api/v1/surveyresponse.php?surveyid=${survayIdToDelete}`,
          {
              method: 'DELETE',
          }
      );
         // Delete survey from surveyresponder table
         const responseSurveyResponder = await fetch(
          `https://app.surveyslack.com/api/surveytask/api/v1/surveyresponder.php?surveyid=${survayIdToDelete}`,
          {
              method: 'DELETE',
          }
      );


        // Check if both requests were successful
        if (responseSurveyList.ok && responseSurveyQuestionary.ok && responseSurveyResponse.ok && responseSurveyResponder.ok) {
            // Add logic for successful deletion
            console.log(`Survey with ID ${survayIdToDelete} was deleted successfully.`);

            // Update the state to remove the deleted survey
            setSurveyList((prevSurveyList) =>
                prevSurveyList.filter((surveyItem) => surveyItem.surveyid !== survayIdToDelete)
            );

            setDeletealert(true);
            setTimeout(() => {
                setDeletealert(false);
            }, 1500);
        } else {
            console.error(`Failed to delete survey with ID ${survayIdToDelete}.`);
        }
    } catch (error) {
        console.error('An error occurred while deleting the survey:', error);
    }
};


  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  // const deleteSurvay = async (survayid) => {
  //   const confirmed = window.confirm(
  //     'Are you sure you want to delete the survey.'
  //   );

  //   if (!confirmed) {
  //     return;
  //   }

  //   try {
  //     const response = await fetch(
  //       `https://app.surveyslack.com/api/surveytask/api/v1/surveylist.php?surveyid=${survayid}`,
  //       {
  //         method: 'DELETE',
  //       }
  //     );
  //     if (response.ok) {
  //       deleteUsersurvey();
  //       console.log(`Survey with ID ${survayid} was deleted successfully.`);
  //     } else {
  //       console.error(
  //         `Failed to delete survey with ID ${survayid}.`
  //       );
  //     }
  //   } catch (error) {
  //     console.error(
  //       'An error occurred while deleting the survey:',
  //       error
  //     );
  //   }
  // };

  const deleteUsersurvey = () => {
    setSurveydelete(surveydelete + 1);
    setDeletealert(true);
    setTimeout(() => {
      setDeletealert(false);
    }, 1500);
  };


  
  const handelCount = () => {
    setTimeout(() => {
      setAlert(true);
    }, 1000);

    setTimeout(() => {
      setSurveycount(surveycount + 1);
      setAlert(false);
    }, 3000);
  };

  
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    Phone: '',
    Bio: '',
    img:'',
  });

  const dispatch = useDispatch();
 
  useEffect(() => {
    const userid = localStorage.getItem('uid');
    const endpoint = API_ENDPOINTS.userDataprofileGET;

    fetch(`${BASE_URL}${endpoint}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        return response.json();
      })
      .then((data) => {
        const user = data.data.find((profiledata) => profiledata.userid === userid);
        if (user) {
          setUserData({
            firstName: user.first_name,
            lastName: user.last_name,
            Phone: user.phone_number,
            Bio: user.bio,
            img: user.profile_image,
          });
          console.log("lsatName",user.last_name);
          // Check if lastname exists, if not redirect to profile page
          if (!user.last_name) {
            navigate('/dashboard/profile');
          }

        } else {
          console.error('User not found');
        }
      })
      .catch(error => console.error('Error fetching user data:', error));
  }, []);


  useEffect(() => {
    setfetchDataalert(true);
    fetch('https://app.surveyslack.com/api/surveytask/api/v1/getsurveylist.php')
      .then((response) => response.json())
      .then((data) => {
        setSurveyList(data.data);
        setLoader(false);
        setShow(true);
        setTimeout(() => {
          setShow(false);
          setfetchDataalert(false);
          dispatch(closeBackdroploader());
        }, 2000);
      })
      .catch((error) => console.error(error));
  }, [surveycount, surveydelete]);
  
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
    // clearfilter();

  };

  const getuserid = localStorage.getItem('uid');
  const filteredData = survey.filter((item) => item.userid === getuserid);

  dispatch(setBackground(filteredData));

  const surveydata = filteredData.map((surveyItem) => ({
    surveyid: surveyItem.surveyid,
    background: surveyItem.background,
    survey_title: surveyItem.survey_title,
    survey_description: surveyItem.survey_description,
    survey_label: surveyItem.survey_label,
    email: surveyItem.email,
    public_survey: surveyItem.public_survey,
    survey_pin: surveyItem.survey_pin,
    // disabled: surveyItem.disabled,
    fonts: surveyItem.fonts,
    created_date: new Date(surveyItem.created_date).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    }),
  }));

  const counter = useSelector((state) => state.showsurveyloaderbackdrop);

  const [sortOption, setSortOption] = useState('');

  // filter survey
  const filteredSurveyData = surveydata
    .filter((surveyItem) => surveyItem.survey_title.toLowerCase().includes(filtertext.toLowerCase()))
    .sort((a, b) => {
      if (sortOption === 'Alphabetically A-Z') {
        return a.survey_title.localeCompare(b.survey_title);
      }
      if (sortOption === 'Alphabetically Z-A') {
        return b.survey_title.localeCompare(a.survey_title);
      }
      if (sortOption === 'Newest') {
        return new Date(b.created_date) - new Date(a.created_date);
      }
      if (sortOption === 'Oldest') {
        return new Date(a.created_date) - new Date(b.created_date);
      }
      return 0; // Default return if no condition is met
    });

  const handelSort = (event) => {
    setSortOption(event.target.value);
  };

  // const clearfilter = () => {
  //   setFilterText('');
  // };
  const HEADER_MOBILE = 64;
  const HEADER_DESKTOP = 92;
  const StyledSearchbar = styled('div')(({ theme }) => ({
    top: 0,
    left: 0,
    zIndex: 99,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    height: HEADER_MOBILE,
    padding: theme.spacing(0, 3),
    boxShadow: theme.customShadows.z8,
    [theme.breakpoints.up('md')]: {
      height: HEADER_DESKTOP,
      padding: theme.spacing(0, 5),
    },
  }));

  const surveyClick = () => {
    dispatch(opencreateSurvey());
  };

  // const AISurveyClick = () => {
  //   dispatch(showAISurvey());
  // };




  const AISurveyClick = () => {
    const storedPreference = localStorage.getItem('doNotShowAISurveyInfoModalAgain');
    if (storedPreference === 'true') {
      dispatch(showAISurveyTitle());
    }else{
      openAISurveyinfoModal();
    }

  };
  

  const clearfilter = () => {
    const userid = localStorage.getItem('uid');
    setSurveyList([]);
    fetch('https://app.surveyslack.com/api/surveytask/api/v1/getsurveylist.php')
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.data.filter((item) => item.userid === userid);
        setSurveyList(filteredData);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (isMounted.current) {
      setNotsurvey(filteredData.length === 0);
    } else {
      isMounted.current = true;
    }
  }, [filteredData]);

  return (
    <>
      <CreateSurvey onButtonclick={handelCount} />
      <Helmet>
        <title> Survey : surveyslack </title>
      </Helmet>
      <Container className="myclass" style={{ maxWidth: 'unset' }}>
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack
            className="w-100 justify-content-between surveysort"
            direction="row"
            spacing={1}
            flexShrink={0}
            sx={{ my: 1 }}
          >
            <Typography variant="h4" sx={{ mb: 10, width: '20%' }}>
              My Surveys
            </Typography>
            <div>
              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={counter}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={fetchDataalert}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>

            {showfilter && (
              <Slide
                className="m-0"
                style={{ backdropFilter: 'blur(6px)', backgroundColor: 'rgba(249, 250, 251, 0.8)' }}
                direction="down"
                in={showfilter}
                mountOnEnter
                unmountOnExit
              >
                <StyledSearchbar>
                  <Input
                    onChange={(e) => setFilterText(e.target.value)}
                    value={filtertext}
                    autoFocus
                    fullWidth
                    disableUnderline
                    placeholder="Search…"
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    }
                    sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
                  />
                  <IoMdCloseCircle onClick={handelClosefilter} style={{ fontSize: '24px', color: 'gray' }} className="me-3" />
                  {/* <Button onClick={handelClosefilter} variant="contained">
                    Close
                  </Button> */}
                </StyledSearchbar>
              </Slide>
            )}
         <div className="d-flex align-items-center flex-column flex-lg-row justify-content-between">
      <Box style={{ width: '100%', maxWidth: '300px', marginRight: '10px' }}>
        <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
          <InputLabel id="demo-select-small-label">Recently Updated</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={sortOption}
            label="Recently Updated"
            onChange={handelSort}
          >
            <MenuItem value="Alphabetically A-Z">Alphabetically A-Z</MenuItem>
            <MenuItem value="Alphabetically Z-A">Alphabetically Z-A</MenuItem>
            <MenuItem value="Newest">Newest</MenuItem>
            <MenuItem value="Oldest">Oldest</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Button
        className='bgButton'
        sx={{ width: '90%', height: '40px', marginBottom: '5px',marginRight:'5px' }}
        style={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}
        variant="contained"
        onClick={surveyClick}
      >
        Create a New Survey
      </Button>
      <Button
        className='bgButton'
        sx={{ width: '90%', height: '40px', marginBottom: '5px',marginRight:'5px'}}
        style={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}
        variant="contained"
        onClick={AISurveyClick}
      >
        Generate Survey with AI
      </Button>
    </div>
          </Stack>
        </Stack>
        {isnotsurvey && (
          <div>
            <img
              src="/assets/images/avatars/onlinesurvey.png"
              alt="Survey not created"
              style={{ width: '120px', height: '120px', margin: 'auto', display: 'block' }}
            />
            <h2 style={{ color: 'black', textAlign: 'center', marginTop: '20px' }}>Welcome to Survey Slack</h2>

            <h4 style={{ color: 'gray', textAlign: 'center', marginTop: '10px' }}>
              You haven't created any surveys yet. To get started, click on the New Survey button.
            </h4>

            <div
              className="survey-box"
              style={{
                border: '2px dashed #007bff',
                padding: '20px',
                borderRadius: '8px',
                width: '100%',
                margin: 'auto',
                marginTop: '60px',
                transition: 'background-color 0.3s ease',
              }}
            >
              <a
                href="#"
                id="new-survey-link"
                className="survey-link"
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#007bff',
                  fontSize: '16px',
                }}
                onClick={surveyClick}
              >
                <span className="fa fa-plus" style={{ marginRight: '8px' }}>
                  New Survey
                </span>
              </a>
            </div>
          </div>
        )}
        <DeleteModal isOpen={showDeleteModal} toggleModal={closeDeleteModal} handleDelete={handleDelete} />

        {loader ? (
          <div
            style={{
              position: 'absolute',
              width: '70%',
              height: '70%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/* <Box width={'100%'} className="align-items-center justify-content-center" sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box> */}
          </div>
        ) : (
          // <SurveyCard surveydata={filteredSurveyData} handleDelete={openDeleteModal} />
          // ProductsPage.js
          <SurveyCard surveydata={filteredSurveyData} handleDelete={(surveyId) => openDeleteModal(surveyId)} />
        )}

        {deletealert && <SurveySuccessAlert alerttype={successdeleteAlert} />}
        {/* {fetchDataalert && <SurveySuccessAlert alerttype={dataFetch}/>} */}
        {fetchDataalert && <SurveySuccessAlert alerttype={dataFetch}/>}
        {counter && <SurveySuccessAlert alerttype={surveyCreateAlert} />}
        <AISurveyinfoModal isOpen={showAISurveyinfoModal} toggleModal={closeAISurveyinfoModal} />

        <AISurveyModal/>
      </Container>
    </>
  );
}
